$msv-modal-width: 500px;
$msv-modal-padding: 40px 60px;
$msv-modal-margin: 20px;
$msv-modal-button-padding: 5px;
$msv-modal-vertical-alignment-top: flex-start;
$msv-modal-vertical-alignment-center: center;
$msv-modal-vertical-alignment-bottom: flex-end;
$msv-modal-z-index: 1050;
$msv-modal-backdrop-opacity: 0.4;
$msv-modal-animation-duration: 0.2s;

$msv-size-m: 360px;

@keyframes modal-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes modal-backdrop-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: $msv-modal-backdrop-opacity;
  }
}

@keyframes modal-backdrop-disappear {
  0% {
    opacity: $msv-modal-backdrop-opacity;
  }
  100% {
    opacity: 0;
  }
}

.modal-open .msc-modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.msc-modal {
  ::-webkit-scrollbar {
    background: none;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    width: 8px;
    border-radius: 8px;
  }

  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $msv-modal-z-index;

  &.show {
    animation: modal-appear $msv-modal-animation-duration;
  }

  &.fade:not(.show) {
    animation: modal-disappear $msv-modal-animation-duration;
    opacity: 0;
  }

  &__dialog {
    align-items: $msv-modal-vertical-alignment-top;
    max-width: $msv-modal-width;
    // margin-left: 12%;
    // margin-left: 310px;
    pointer-events: none;
    position: relative;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    transform: translate(-50%, -50%);

    &:focus {
      outline: 0;
    }
    /* stylelint-disable declaration-no-important -- Auto-suppressed. Please fix if possible. */
    input,
    textarea,
    select {
      margin-right: 1px;
      background-color: var(--msv-modal-bg-color) !important;
      border: 1px solid $msv-gray-300 !important;

      &:focus {
        outline: none !important;
      }
    }

    input:disabled,
    textarea:disabled,
    select:disabled {
      background-color: $msv-gray-100 !important;
      border: none !important;
    }
    /* stylelint-enable declaration-no-important -- Auto-suppressed. Please fix if possible. */

    @media (min-width: map-get($grid-breakpoints, "md")) {
      max-width: 1100px;
    }
    &.ms-header {
      &__mobile-hamburger {
        top: 0;
        left: 0;
        transform: rotateY(0);
        max-width: 100%;
        .msc-modal__content {
          max-width: 600px;
          overflow: hidden;
          border: 0;
        }
      }
    }
    &.order-template-confirmation {
      .msc-modal__content {
        text-align: center;
        padding: 15px;
        min-height: 180px;
        .ms-order-template__remove-list {
          position: relative;
          margin-right: 10px;
          background: var(--britax-success-bg-color);
          color: var(--britax-success-font-color);
          line-height: 33px;
          width: 36px;
          height: 36px;
          display: inline-block;
          &:before {
            font-family: "britax";
            content: $britax-icon-checked;
            font-size: var(--britax-font-size-sm);
          }
        }
        .cancel {
          font-size: 0;
          border: 0;
          background: var(--britax-danger-bg-color);
          line-height: initial;
          width: 36px;
          height: 36px;
          display: inline-block;
          @include add-icon($msv-Cancel);
          &:before {
            color: var(--britax-danger-font-color);
            font-size: var(--britax-font-size-sm);
          }
        }
      }
    }
    &.order-detail-modal {
      .msc-modal__content {
        text-align: center;
        padding: 15px;
        min-height: 145px;
        .ms-order-detail {
          position: relative;
          margin-right: 10px;
          background: var(--britax-success-bg-color);
          color: var(--britax-success-font-color);
          line-height: 33px;
          width: 36px;
          height: 36px;
          display: inline-block;
          &:before {
            font-family: "britax";
            content: $britax-icon-checked;
            font-size: var(--britax-font-size-sm);
          }
        }
        //   .action-button {
        //     @include grey-button();
        //     margin: 12px;
        //     font-size: var(--britax-body-font-size-s);
        //     line-height: var(--britax-line-height-m);
        // }
        .cancel {
          font-size: 0;
          border: 0;
          background: var(--britax-danger-bg-color);
          line-height: 49px;
          width: 36px;
          height: 36px;
          display: inline-block;
          @include add-icon($msv-Cancel);
          &:before {
            color: var(--britax-danger-font-color);
            font-size: var(--britax-font-size-sm);
          }
        }
      }
    }
  }

  &__content {
    // background-clip: padding-box;
    overflow: auto;
    background-color: var(--britax-gray-100);
    color: var(--britax-primary);
    border: 10px solid var(--britax-white);
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    padding: 0;
    .ms-nav {
      display: block;
      &__list__item__button,
      &__list__item__link {
        background-color: var(--britax-white);
        height: 44px;
        line-height: 44px;
        padding: 0 25px;
        border-bottom: solid 1px var(--britax-silver-grey);
        &:hover {
          color: var(--britax-primary);
        }
      }
    }
    .ms-header {
      &__mobile-hamburger-menu-body {
        .ms-nav__list__item__button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: capitalize;
          &::after {
            margin-right: 12px;
          }
        }
        .ms-header__mobile-hamburger-menu-links {
          background-color: var(--britax-white);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-transform: capitalize;
          a {
            color: var(--britax-grey);
            font-size: var(--britax-font-size-sm);
            height: 44px;
            line-height: var(--britax-line-height-xml);
            padding: 0 25px;
            width: 100%;
            border-bottom: solid 1px var(--britax-silver-grey);
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover {
              text-decoration: none;
            }
            &::after {
              font-family: "Font Awesome 5 Free";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              font-style: normal;
              font-variant: normal;
              font-weight: 900;
              text-rendering: auto;
              line-height: 1;
              content: "";
              margin-right: 12px;
              line-height: 30px;
            }
            &.ms-header__wishlist-mobile {
              display: none;
            }
          }
          .ms-signin-info__account-link-button[title="My account"] {
            &::after {
              font-family: "britax";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              font-weight: var(--britax-font-weight-normal);
              text-rendering: auto;
              line-height: 1;
              content: "";
              font-size: var(--britax-body-font-size-xs);
              margin-right: 10px;
            }
          }
          .ms-signin-info__account-link-button[title="Order history"] {
            &::after {
              font-family: "britax";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              font-weight: var(--britax-font-weight-normal);
              text-rendering: auto;
              line-height: 1;
              content: "";
              font-size: var(--britax-body-font-size-xs);
              margin-right: 10px;
            }
          }
          .ms-signin-info__account-link-button[title="My profile"] {
            &::after {
              font-family: "britax";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              font-weight: var(--britax-font-weight-normal);
              text-rendering: auto;
              line-height: 1;
              content: "";
              font-size: var(--britax-body-font-size-xs);
              margin-right: 10px;
            }
          }
          .ms-header__signout-button {
            text-transform: capitalize;
            &::after {
              font-family: "britax";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              font-weight: var(--britax-font-weight-normal);
              text-rendering: auto;
              line-height: 1;
              content: "";
              font-size: var(--britax-body-font-size-xs);
              margin-right: 10px;
            }
          }
          .language-picker {
            &__form {
              width: 100%;
              padding: 0 36px 0 23px;
              color: var(--britax-grey);
              font-size: var(--britax-font-size-sm);
              height: 44px;
              position: relative;
              line-height: var(--britax-line-height-xml);
              border-bottom: solid 1px var(--britax-silver-grey);
              select {
                width: 100%;
                border: 0 !important;
                padding: 9px 20px 9px 0;
                color: var(--britax-grey);
                -webkit-appearance: none;
                -moz-appearance: none;
                height: 34px;
                padding-top: 0;
                padding-bottom: 0;
              }
              &:after {
                @include msv-icon();
                content: $msv-ChevronRight;
                position: absolute;
                top: 12px;
                right: 40px;
                transform: rotate(90deg);
              }
            }
          }
        }
      }
      &__mobile-hamburger-menu-body {
        &.msc-modal {
          &__body {
            margin-bottom: 0;
            max-height: 100vh;
            height: 100vh;
            padding: 0;
          }
        }
      }
      &__mobile-hamburger-menu-header {
        &.msc-modal {
          &__header {
            padding: 0 30px;
            min-height: 36px;
          }
        }
      }
    }
    .modal-footer {
      .btn-primary {
        @include primary-button();
      }
      .btn-secondary {
        @include grey-button();
      }
    }
    .modal-body {
      .product-variant-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        .blk {
          width: 23.33%;
          h2 {
            color: #c4c4c4;
            text-transform: uppercase;
            font-size: 0.85rem;
          }
          &:first-of-type {
            width: 30%;
          }
        }
      }
      .ms-buybox__quantity {
        .quantity-box {
          background-color: transparent;
        }
      }
    }
    .cancel-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background: var(--britax-grey);
      color: var(--britax-white);
      padding: 5px 10px;
      font-weight: var(--britax-font-weight-bold);
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    right: 0;
    z-index: 1;
    padding: 18px 30px;
    // border-bottom: solid 1px var(--britax-silver-grey);
    .msc-modal__title {
      @include font-content-xl();
      margin: 0;
      text-transform: none;
    }

    .msc-modal__close-button {
      @include add-icon($msv-x-shape);
      @include vfi();
      background-color: var(--britax-primary);
      border: 0;
      color: var(--britax-white);
      font-size: var(--britax-font-size-xm);
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 36px;
      height: 36px;
      &:hover {
        background-color: var(--britax-grey);
      }
    }
  }

  .msc-fullview-modal {
    @media (min-width: map-get($grid-breakpoints, "md")) {
      max-width: 100%;
      max-height: 772px;
      margin: 40px;
    }

    .msc-modal {
      &__content {
        padding: 0;
        background-color: $msv-white;
        background-clip: border-box;
      }

      &__close-button {
        right: 10px;
        top: 15px;
      }
    }

    .msc-fullview-modal-body.msc-modal__body {
      margin-top: 20px;
    }

    .msc-carousel__item {
      img {
        display: block;
        max-height: 600px;

        @media (max-width: $msv-breakpoint-m) {
          min-height: 0;
        }
      }
    }
  }

  &__body {
    overflow: overlay;
    max-height: 600px;
    padding: 15px 30px;
    @media screen and (min-height: 580px) and (max-height: 680px) {
      max-height: 500px;
    }
    @media screen and (min-height: 480px) and (max-height: 579px) {
      max-height: 400px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
    padding: 15px 30px;
    .ms-business-organization-list__form-cancel {
      border-radius: 25px;
    }

    // border-top: solid 1px var(--britax-silver-grey);

    @media (max-width: map-get($grid-breakpoints, "md")) {
      flex-wrap: wrap;

      .btn-primary {
        margin-bottom: 12px;
      }

      .btn-primary,
      .btn-secondary {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__footer-claims {
    display: flex;
    justify-content: flex-end;
    padding: 15px 30px;
    .ms-business-organization-list__form-cancel {
      border-radius: 25px;
    }

    // border-top: solid 1px var(--britax-silver-grey);

    @media (max-width: map-get($grid-breakpoints, "md")) {
      flex-wrap: wrap;

      .btn-primary {
        margin-bottom: 12px;
      }

      .btn-primary,
      .btn-secondary {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__backdrop {
    background-color: $msv-black;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $msv-modal-z-index - 10;

    &.show {
      animation: modal-backdrop-appear $msv-modal-animation-duration;
      opacity: $msv-modal-backdrop-opacity;
    }

    &.fade:not(.show) {
      animation: modal-backdrop-appear $msv-modal-animation-duration;
      opacity: 0;
    }
  }
  &.ms-header {
    &__mobile-hamburger {
      top: 0;
      transform: translateY(0);
      &-menu-body {
        height: 100vh;
      }
    }
  }
}

@media screen and (max-width: $msv-breakpoint-m) {
  .msc-modal__dialog:not(.msc-modal-input-required) {
    margin: 0;

    .msc-modal__content {
      border: 0;
    }
  }

  .msc-modal__dialog.msc-modal-input-required {
    height: 100%;
    max-width: none;

    .msc-modal__content {
      height: 100%;
      padding: 0;

      .msc-modal__header {
        height: 48px;
        border-bottom: 1px solid $msv-gray-300;
        padding: 9px 20px;
        margin-bottom: 0;
        flex: 0 0 auto;

        .msc-modal__title {
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          width: 100%;
          text-align: center;
        }

        .msc-modal__close-button {
          top: 12px;
        }
      }
    }

    .msc-modal__body {
      padding: 20px 20px;
      flex: 1 1 auto;
      margin-bottom: 0;
      max-height: none;
    }

    .msc-modal__footer {
      border-top: 1px solid $msv-gray-300;
      padding: 9px 20px;
      width: 100%;
      flex: 0 0 auto;
      margin-top: 0;

      button {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
}
