//style presets
:root {
	--msv-breadcrumb-item-font-size: var(--msv-body-font-size-s);
}

.ms-breadcrumb {
	margin: 15px 0;
	overflow: hidden;

	.ms-breadcrumb_list {
		color: var(--britax-white);
		float: left;
		list-style: none;
		padding: 0;

		.ms-breadcrumb-back-button {
			text-decoration: underline;
			cursor: pointer;
			float: left;
			margin-right: 10px;
			display: none;

			@include add-icon($msv-ChevronLeft, before);

			&::before {
				padding-right: 5px;
			}
		}
	}

	.ms-breadcrumb_item {
		color: var(--britax-white);
		float: left;
		font-size: var(--msv-breadcrumb-item-font-size);
		position: relative;
		margin: 0;
		padding-right: 5px;

		@include font-content-s();

		a[data-no-click="true"] {
			cursor: default;
			pointer-events: none;
		}

		.ms-breadcrumb_link {
			color: var(--britax-white);
			font-size: var(--msv-breadcrumb-item-font-size);
			text-decoration: underline;
		}

		&.breadcrumb_item-current {
			.ms-breadcrumb_link {
				text-decoration: none;
			}
		}
	}
}
.breadcrumb-container {
	background-color: var(--britax-primary);
	.ms-breadcrumb {
		margin: 15px auto;
	}
}
