// bootstrap color variables
$msv-white: #ffffff;
$msv-white-100: rgba(255, 255, 255, 0.8);
$msv-white-200: #f3f3f3;
$msv-black: #000000;
$msv-blue: #0062af;
$msv-green: #107c10;
$msv-red: #a80000;
$msv-pink: #fcdfdf;
$msv-cyan: #0776d1;
$msv-yellow: #ffff00;
$msv-snow: #f9f9f9;

$msv-gray-100: #f8f7f6;
$msv-gray-200: #e8e8e8;
$msv-gray-300: #d1d1d1;
$msv-gray-400: #6b727a;
$msv-gray-500: #616365;
$msv-gray-800: #8f8f8f;
$msv-gray-900: #1d1d1d;
$msv-gray-1000: #2d2d2d;
$msv-gray-1100: #2b2b2b;
$msv-gray-1200: #6e6e6e;
$msv-gray-tint: #495057;

$msv-white-smoke: #f1f1f1;
$msv-whisper: #ededed;
$msv-nobel: #949494;

$msv-primary: #4c833a;
$msv-primary-hover: #39632c;
$msv-secondary: #6c757d;
$msv-secondary-hover: #e1efdc;

$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-box-shadow-color-0: rgba(0, 0, 0, 0);
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-box-shadow-color-18: rgba(0, 0, 0, 0.18);
$msv-box-shadow-color-22: rgba(0, 0, 0, 0.22);

$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #f3f2f1;

// Social button color variables
$facebook-bg-color: #3c3c41;
$facebook-hover-color: #1d1d1d;
$microsoft-bg-color: #3c3c41;
$microsoft-hover-color: #1d1d1d;
$social-bg-color: #3c3c41;
$social-hover-color: #1d1d1d;

$msv-unfilled-rating-color: $msv-gray-800;

// Britax Color variable
$britax-white: #ffffff;
$britax-black: #000000;
//$britax-primary-red: #d50c2d;
$britax-primary: #222323; // Saqlain Customization: Primary Color Change
$britax-gray-100: #f8f7f6;
//$britax-grey: #626c76; //Saqlain customization: Color Change
$britax-grey: #626b76;
//$britax-silver-grey: #e5e8eb; //Saqlain Customization: Color Change
$britax-silver-grey: #e4e8eb;
$britax-cold-grey: #b6bcc1;
$britax-light-grey: #fcfcfc;
$britax-grey-2: #f6f6f6;
$britax-grey-550: rgba(77, 86, 98, 0.5);
$britax-grey-600: #434a51;
$britax-grey-1400: #626161;
$britax-dark-grey: #393d46;
$britax-light-grey-100: rgba(98, 108, 118, 0.7);
$britax-purple: #451854;
$britax-border: rgba(0, 0, 0, 0.07);
$britax-border-tile: rgba(98, 108, 118, 0.2);
$britax-check-color: #6aa0b7;
$britax-shade-grey: #f0f4f6;
$britax-bg-grey: #f7f8f9;
$britax-label: #c4c4c4;
$britax-success-bg-color: #e3f2e8;
$britax-success-font-color: #39ba40;
$britax-danger-bg-color: #f8d7da;
$britax-danger-font-color: #721c24;
$britax-success-color: #00c52b;
$britax-pending-color: #ff7f0a;
$britax-error-color: #d50c2d;

// Style presets
:root {
	// Background
	--msv-bg-color: #{$msv-white};

	// Font
	--msv-font-primary-color: #{$msv-black};
	--msv-font-secondary-color: #{$msv-white};

	// Borders
	--msv-border-color: #{$msv-gray-100};

	// Brand accents
	--msv-accent-brand-color: #{$msv-primary};
	--msv-accent-brand-alt-color: #{$msv-primary-hover};
	--msv-accent-secondary-btn: #{$msv-secondary-hover};

	// Status
	--msv-error-color: #{$msv-red};
	--msv-success-color: #{$msv-green};

	// Modal
	--msv-modal-bg-color: var(--msv-bg-color);
	--msv-modal-font-color: var(--msv-font-primary-color);
	--msv-flyout-bg-color: #{$msv-white};

	// Britax Theme Color
	--britax-white: #{$britax-white};
	--britax-black: #{$britax-black};
	--britax-primary: #{$britax-primary};
	--britax-grey: #{$britax-grey};
	--britax-gray-100: #{$britax-gray-100};
	--britax-silver-grey: #{$britax-silver-grey};
	--britax-cold-grey: #{$britax-cold-grey};
	--britax-light-grey: #{$britax-light-grey};
	--britax-grey-2: #{$britax-grey-2};
	--britax-grey-550: #{$britax-grey-550};
	--britax-grey-600: #{$britax-grey-600};
	--britax-light-grey-100: #{$britax-light-grey-100};
	--britax-purple: #{$britax-purple};
	--britax-border: #{$britax-border};
	--britax-border-tile: #{$britax-border-tile};
	--britax-grey-1400: #{$britax-grey-1400};
	--britax-check-color: #{$britax-check-color};
	--britax-shade-grey: #{$britax-shade-grey};
	--britax-bg-grey: #{$britax-bg-grey};
	--britax-label: #{$britax-label};
	--britax-dark-grey: #{$britax-dark-grey};
	--britax-success-bg-color: #{$britax-success-bg-color};
	--britax-success-font-color: #{$britax-success-font-color};
	--britax-danger-bg-color: #{$britax-danger-bg-color};
	--britax-danger-font-color: #{$britax-danger-font-color};
	--britax-success-color: #{$britax-success-color};
	--britax-pending-color: #{$britax-pending-color};
	--britax-error-color: #{$britax-error-color};
}
