.msc-invoice-details {
  margin-top: 40px;

  &__header {
    padding-bottom: 40px;
    border-bottom: 1px solid $msv-divider-color;

    &__invoice-id {
      font-size: var(--britax-font-size-xm);
      margin-top: 7px;
    }

    &__invoice-info-container {
      @media only screen and (min-width: $msv-breakpoint-l) {
        display: flex;
        justify-content: space-between;
      }

      &__order-info-container {
        &__order-info {
          &__main-info {
            font-size: var(--britax-font-size-xm);
            margin: 7px 0;
          }

          &__details {
            font-size: var(--britax-body-font-size-m);
            display: flex;

            &__order-date {
              padding-right: 10px;
              border-right: solid 1px;
            }

            &__order-items-count {
              padding: 0 10px;
            }
          }
        }
      }

      &__amount-summary {
        @media only screen and (min-width: $msv-breakpoint-l) {
          text-align: right;
        }

        @media only screen and (max-width: $msv-breakpoint-l) {
          margin-top: 30px;
        }

        &__amount-due-text {
          font-size: var(--britax-font-size-xm);
        }

        &__amount-due-amount {
          font-size: var(--britax-font-size-sml-32);
          line-height: 42px;
        }

        &__amount-due-date {
          font-size: var(--britax-body-font-size-m);
          font-weight: $msv-font-weight-light;
        }
      }
    }

    &__actions {
      &__pay-invoice {
        @include primary-button();

        @media only screen and (max-width: $msv-breakpoint-l) {
          width: 100%;
          margin-top: 30px;
        }
        display: none;
      }
    }
  }

  .invoiced-line {
    position: relative;
    .sku-number {
      margin-left: 170px;
      position: absolute;
      bottom: 30px;
      font-size: var(--britax-body-font-size-xs);
      @media screen and (max-width: $britax-container-max-width-s) {
        margin-left: 120px;
        bottom: 10px;
      }
    }
  }

  &__products {
    padding-top: 40px;
    border-bottom: 1px solid $msv-divider-color;

    &__product-line {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;

      &__product-info-container {
        display: flex;

        &__product-details {
          padding: 0 20px;
          @media screen and (max-width: $britax-container-max-width-s) {
            flex-wrap: wrap;
            max-width: calc(100% - 150px);
          }

          &__name {
            font-weight: $msv-font-weight-bold;
            font-size: $msv-font-size-l;
            line-height: $msv-line-height-l;
          }

          a {
            color: var(--britax-primary);
            &:hover {
              color: var(--britax-grey);
            }
          }

          &__small-details {
            font-size: $msv-font-size-m;
            line-height: $msv-line-height-m;
            &__claim {
              margin-top: 10px;
              a {
                display: inline-block;
                text-decoration: underline;
                color: var(--britax-primary);
                &:hover {
                  color: var(--britax-grey);
                }
              }
            }
            h6 {
              s,
              span {
                font-weight: var(--britax-font-weight-normal);
              }
            }
            &__dimensions {
              margin-bottom: 3px;
            }
          }
        }

        &__name {
          font-weight: $msv-font-weight-bold;
          font-size: $msv-font-size-l;
          line-height: $msv-line-height-l;
        }
        .msc-empty_image {
          background-size: cover;
          width: 120px;
          height: 120px;
        }
        picture,
        img {
          @media screen and (max-width: $britax-container-max-width-s) {
            width: 100px;
          }
        }
      }

      &__total-price {
        font-weight: $msv-font-weight-bold;
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-l;
      }
    }
  }

  &__payment-details {
    padding-top: 20px;

    &__line {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      &__label {
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-l;
      }

      &__value {
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-l;
        font-weight: $msv-font-weight-bold;
      }

      &-total-amount &__value {
        font-size: $msv-font-size-xl;
        line-height: $msv-line-height-xl;
      }
    }
  }

  .order-detail-header {
    position: relative;
    &__order-number {
      font-size: var(--britax-font-size-xm);
    }
    &__status {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 15px;
      border-radius: 25px;
      &.PartiallyPaid {
        background-color: rgba(255, 127, 10, 0.11);
        color: #ff7f0a;
      }
      &.Unpaid {
        background-color: #ffacac;
        color: #ff0019;
      }
      &.Paid {
        background-color: #e3f2e8;
        color: #39ba40;
      }
    }
  }

  &__create-price-claim-button {
    @include primary-button;
  }
}
