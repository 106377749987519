
$promo-banner-line-height: 20px;
$promo-banner-bottom-padding: 5px;
$promo-banner-top-padding: 5px;
$promo-banner-link-margin-left: 4px;

//style presets
:root {
    --msv-promo-banner-bg: #{$msv-gray-100};
    --msv-promo-banner-border: #{$msv-gray-1000};
    --msv-promo-banner-font-color: #{$msv-gray-1100};
    --msv-promo-banner-font-size: var(--msv-body-font-size-m);
 }

.ms-promo-banner {
    display: flex;
    flex-wrap: wrap;
    background: var(--msv-promo-banner-bg);
    border: none;
    padding-bottom: $promo-banner-bottom-padding;
    padding-top: $promo-banner-top-padding;
    line-height: $promo-banner-line-height;
    width: 100%;

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    .msc-carousel__indicators {
        display: none;
    }

    .ms-promo-banner__text,
    .ms-promo-banner__link {
        color: var(--msv-promo-banner-font-color);
        font-size: var(--msv-promo-banner-font-size);
        font-style: normal;
        font-weight: var(--msv-font-weight-normal);
    }

    .ms-promo-banner__link {
        margin-left: $promo-banner-link-margin-left;
        text-decoration: underline;
    }

    .ms-promo-banner__carousel {
        width: 98%;
    }

    .msc-carousel__inner {
        .msc-carousel__item {
            &.active {
                text-decoration: none;
            }
        }
        color: var(--msv-font-primary-color);
    }

    .ms-promo-banner__close-button {
        color: var(--msv-font-secondary-color);
        background-color: transparent;
        border: none;
        cursor: pointer;

        &::after {
            @include msv-icon();
            content: $msv-x-shape;
        }

        .ms-promo-banner__close-button__label {
            display: none;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-promo-banner .ms-promo-banner__carousel {
        width: 95%;
    }
}
