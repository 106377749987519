$navigation-parent-menu-margin-left: 24px;
$navigation-parent-menu-margin-right: 24px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-sub-menu-padding-left: 20px;
$navigation-sub-menu-padding-right: 20px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-item-line-height: 30px;
$navigation-menu-icon-line-height: 30px;
$navigation-menu-item-underline: 0;
$navigation-menu-wrapper-height: auto;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-height: 400px;
$navigation-menu-max-image-width: 370px;
$navigation-menu-max-image-height: 330px;
$navigation-menu-min-image-height: 300px;
$navigation-menu-image-width: 50%;
$navigation-menu-image-padding: 40px;
$navigation-menu-image-bottom: 14px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 15px;
$navigation-ms-nav-area-width: 63%;
$navigation-ms-category-img-padding: 20px;
$navigation-menu-list-min-width: 180px;
//style presets
:root {
  --msv-nav-bg: #{$msv-gray-1100};
  --msv-nav-border: transparent;
  --msv-nav-dropdown-border: #{$msv-gray-300};
  --msv-nav-carot-color: var(--msv-font-secondary-color);
  --msv-nav-font-color: var(--msv-font-secondary-color);
  --msv-nav-font-size: var(--msv-body-font-size-m);
  --msv-nav-font-family: #{$msv-primary-font-family};
  --msv-nav-title-font-size: var(--msv-body-font-size-l);
  --britax-nav-font-family: #{$britax-primary-font-family};
}
.ms-nav__list {
  border-bottom: 20px;
}
.ms-nav {
  background: transparent;
  color: var(--britax-primary);
  width: 100%;
  @media screen and (max-width: $britax-container-max-width-m) {
    display: none;
  }

  &__list {
    z-index: 1000;

    &.level-2 {
      width: 100%;
    }

    .level-2 {
      > li {
        font-weight: $navigation-menu-active-item-font-weight;
      }

      &.navmenu-multi-level {
        li.ms-nav__list__item {
          .havesubmenu {
            font-weight: normal;
          }
        }
      }
    }

    &__item {
      font-size: var(--msv-nav-font-size);
      font-style: normal;
      line-height: $navigation-menu-item-line-height;
      white-space: nowrap;
      display: inline-block;

      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      .havesubmenu {
        font-weight: $navigation-menu-active-item-font-weight;
      }

      .level-3 {
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .havesubmenu {
          .ms-nav__list {
            margin-left: $navigation-ms-nav-submenu-margin-left;
          }
        }
      }

      &__image {
        position: absolute;
        right: 0;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  > .ms-nav__list {
    > .ms-nav__list__item {
      background: var(--britax-white);

      > .ms-nav__list {
        background: var(--britax-white);
        min-width: $navigation-menu-list-min-width;
      }

      > .ms-nav__feature {
        background: var(--britax-white);
        border: none;
        height: $navigation-menu-wrapper-height;
        left: $navigation-menu-wrapper-left;
        min-width: $navigation-menu-list-min-width;
        position: absolute;
        outline: none;
        overflow: hidden;
        width: $navigation-menu-wrapper-width;

        > .category-image {
          float: right;
          padding: $navigation-ms-category-img-padding;

          .category-image-title {
            text-align: center;
            display: none;
          }

          picture {
            + div.category-image-title {
              display: block;
            }
          }

          img {
            min-height: $navigation-menu-min-image-height;
            max-height: $navigation-menu-max-image-height;
            max-width: $navigation-menu-max-image-width;

            > .ms-nav-image__item {
              display: block;
              padding: $navigation-menu-image-padding;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $msv-breakpoint-xs) {
  .ms-nav {
    &__list {
      display: block;
      flex-wrap: nowrap;

      &__mobile__container {
        background-color: var(--britax-gray-100);
        padding: 8px 25px;
        display: flex;

        &__button {
          @include vfi();
          @include add-icon($msv-ChevronLeft);
          background-color: transparent;
          border: none;
          color: var(--britax-primary);
          font-family: var(--msv-nav-font-family);
          padding-left: 0;
          padding-right: 0;

          &:focus {
            outline-offset: -2px;
          }
        }

        &__span {
          margin-left: 21px;
          font-weight: 600;
          color: var(--britax-primary);
          font-family: var(--msv-nav-font-family);
          font-size: var(--britax-body-font-size-xs);
        }
      }

      &__item {
        &__image {
          display: none;
        }

        &__button {
          color: var(--britax-primary);
          padding-left: 0;
          padding-right: 0;
          text-align: left;
          width: 100%;

          &::after {
            @include msv-icon();
            content: $msv-ChevronRight;
            display: inline-flex;
            float: right;
            line-height: 40px;
            text-align: right;
          }
        }

        &__button,
        &__link {
          background-color: var(--britax-grey-2);
          border: none;
          color: var(--britax-primary);
          display: block;
          font-family: var(--msv-nav-font-family);

          @include vfi();

          &:focus {
            outline-offset: -2px;
          }
        }
      }
    }

    > .ms-nav__list {
      outline: none;
      width: 100%;

      .ms-nav__list {
        height: 100vh;
      }

      .havecateImage {
        float: left;
        position: relative;
        width: $navigation-ms-nav-area-width;
      }

      > .ms-nav__list__item {
        > .ms-nav__list__item__button {
          color: var(--britax-primary);
        }
      }
    }
  }

  .ms-nav.child {
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

@media screen and (min-width: $msv-breakpoint-l) {
  .ms-nav {
    display: flex;

    &__list {
      position: absolute;

      &.level-2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        left: 0;
        background: var(--britax-grey-2);

        .ms-nav__list {
          position: relative;

          .ms-nav__list__item {
            font-weight: normal;
            margin-left: 0;
          }
        }
      }

      &__mobile__container {
        display: none;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    > .ms-nav__list {
      display: flex;
      flex-wrap: wrap;
      outline: none;
      position: relative;
      justify-content: center;
      width: $navigation-ms-nav-list-width;

      .ms-nav__list {
        height: auto;
        min-width: $navigation-menu-list-min-width;
        //padding: 10px calc((100% - 960px)/ 2);
        background-color: var(--britax-grey-2);
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        .ms-nav__list {
          margin: 0;
        }
      }

      > .ms-nav__list__item:first-child {
        margin-left: 0;
      }

      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      > .ms-nav__list__item {
        margin: {
          left: $navigation-parent-menu-margin-left;
          right: $navigation-parent-menu-margin-right;
        }

        > .ms-nav__list__item__button,
        > .ms-nav__list__item__link {
          @include vfi();
          @include britax-font-content(
            var(--britax-font-weight-bold),
            var(--britax-body-font-size-s),
            $britax-line-height-s
          );
          align-items: flex-end;
          background-color: var(--britax-grey-2);
          border: none;
          color: var(--britax-primary);
          display: flex;
          justify-content: center;
          position: relative;
          line-height: $navigation-menu-item-line-height;
          padding: 5px 0;

          &::before {
            right: 0;
            left: 0;
            content: "";
            outline-offset: 0;
            width: 0%;
          }

          &:hover,
          &:focus {
            background: var(--britax-grey-2);
            outline-offset: -2px;
            outline: none;
            border: 0;
            &::before {
              -webkit-transition: width 0.1s ease-in-out;
              -moz-transition: width 0.1s ease-in-out;
              -ms-transition: width 0.1s ease-in-out;
              -o-transition: width 0.1s ease-in-out;
              transition: width 0.1s ease-in-out;
              background-color: var(--britax-primary);
              bottom: $navigation-menu-item-underline;
              height: 4px;
              outline: none;
              position: absolute;
              top: inherit;
              width: calc(100% - 20px);
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list__item__button {
          &::after {
            @include msv-icon();
            color: var(--britax-primary);
            content: $msv-ChevronDown;
            font-size: var(--britax-body-font-size-xs);
            margin-left: $navigation-menu-button-icon-margin-left;
            line-height: $navigation-menu-icon-line-height;
          }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-nav__list__item {
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 15px;
          &:first-child {
            display: none;
          }
        }
        .ms-nav__list__item__link {
          color: var(--britax-primary);
          background-color: var(--britax-white);
          justify-content: flex-start;
          padding-bottom: 10px;
          text-decoration: none;
          width: 100%;
          position: relative;
          &:focus {
            border: 0;
            outline: none;
          }
          &:before {
            content: "";
            display: block;
            width: 200px;
            margin-bottom: 8px;
            background-size: cover;
          }

          &:after {
            right: 0;
            left: 0;
            content: "";
            outline-offset: 0;
            width: 0%;
          }

          &:hover,
          &:focus {
            outline: none;
            border: 0;
            &:after {
              -webkit-transition: width 0.1s ease-in-out;
              -moz-transition: width 0.1s ease-in-out;
              -ms-transition: width 0.1s ease-in-out;
              -o-transition: width 0.1s ease-in-out;
              transition: width 0.1s ease-in-out;
              background-color: var(--britax-primary);
              bottom: $navigation-menu-item-underline;
              height: 4px;
              outline: none;
              position: absolute;
              top: inherit;
              width: 100%;
            }
          }
        }

        .ms-nav_menu_item_image {
          display: block;
          width: 200px;
          height: 200px;
          margin-bottom: 8px;
          background-size: cover;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list__item__link {
          display: flex;
          padding-left: 0;
          padding-right: 0;
          justify-content: center;
          line-height: $navigation-menu-item-line-height;

          &:hover,
          &:focus {
            background: transparent;
            text-decoration: none;
            outline-offset: -2px;
            &::before {
              width: 100%;
            }
          }
        }

        > .ms-nav__list__item__button:first-child {
          margin-left: 0;
        }

        > .ms-nav__list {
          border: 1px solid var(--msv-nav-dropdown-border);
          box-shadow: 0 8px 15px $msv-box-shadow-color;
        }
      }
    }
  }
}
