$content-block-min-height: 150px;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 16px;
$hero-content-button-margin-top: 18px;
$hero-content-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108),
	0 1.6px 3.6px rgba(0, 0, 0, 0.132);
$hero-heading-color: $black;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 60px;
$hero-link-to-link-spacing: 16px;
$hero-links-top-margin: 32px;
$hero-links-secondary-padding: 12px 20px;

:root {
	// heading
	--msv-content-block-heading-font-size: #{$hero-heading-font-size};
	--msv-content-block-heading-font-color: var(--msv-font-primary-color);

	// Text theme
	--msv-content-block-textheme-dark: var(--msv-font-primary-color);
	--msv-content-block-textheme-light: #{$msv-gray-100};
	--msv-content-block-text-font-size: var(--msv-body-font-size-m);

	// primary button
	--msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
	--msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
	--msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
}

@mixin overlay-text-on-image {
	.ms-content-block__details {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		padding: 0;
		// max-width: 64em;
		@media (max-width: $britax-container-max-width-s) {
			max-width: 100%;
			padding: 0;
			margin: 0;
			top: 0;
			left: 0;
			right: inherit;
			position: relative;
			transform: translate(0, 0);
			background: var(--britax-grey-2);
			padding: 15px;
			text-align: center;
		}
	}
}

.homepage-product-collection {
	margin-top: 50px;
	.ms-text-block {
		div {
			h5 {
				font-size: var(--britax-font-size-xm);
				font-weight: var(--britax-font-weight-above-normal);
				color: vaR(--britax-primary);
			}
			h2 {
				font-size: var(--britax-body-font-size-ml);
				line-height: var(--britax-line-height-xxxl);
				font-weight: var(--britax-font-weight-700);
				color: vaR(--britax-grey);
			}
			p {
				font-size: var(--britax-font-size-sm);
				line-height: var(--britax-line-height-sml);
				font-weight: var(--britax-font-weight-normal);
				color: vaR(--britax-grey);
			}
		}
	}
	.ms-product-collection {
		&__item {
			width: calc(25% - 20px);
			@media screen and (max-width: $britax-container-max-width-l) {
				width: calc(33.33% - 20px);
			}
			@media screen and (max-width: $britax-container-max-width-m) {
				width: calc(50% - 20px);
			}
			@media screen and (max-width: $britax-breakpoint-mbl-sm) {
				width: 100%;
				margin: 0;
			}
		}
		&__heading {
			font-size: var(--britax-font-size-xsml);
			color: var(--britax-grey);
			border-bottom: solid 1px var(--britax-cold-grey);
			padding-bottom: 20px;
			margin: 0 0 15px;
		}
	}
	.msc-ss-carousel-strip {
		width: calc(100% + 20px);
		margin-left: -10px;
		margin-right: -10px;
	}
}
.featured-products {
	&.ms-product-collection {
		&__item {
			width: calc(25% - 20px);
			@media screen and (max-width: $britax-container-max-width-l) {
				width: calc(33.33% - 20px);
			}
			@media screen and (max-width: $britax-container-max-width-m) {
				width: calc(50% - 20px);
			}
			@media screen and (max-width: $britax-breakpoint-mbl-sm) {
				width: 100%;
				margin: 0;
			}
		}
	}
}
.top-category-section {
	.col-12 {
		flex: 0 0 calc(33.33% - 2px);
		max-width: calc(33.33% - 2px);
		overflow: hidden;
		margin: 30px 2px 0 0;
		@media screen and (max-width: $britax-container-max-width-m) {
			flex: 0 0 100%;
			max-width: 100%;
			margin: 15px 0 0;
		}
		.ms-content-block {
			background: var(
				--britax-silver-grey
			); //Saqlain Customization, Backgroud Color Change
			padding: 25px 25px 10px;
			//color: var(--britax-white); Saqlain Customization
			font-size: var(--britax-body-font-size-xs);
			&__title {
				font-size: var(--britax-font-size-sml);
				margin-bottom: 10px;
			}
			&__image {
				position: absolute;
				max-width: 35%;
				right: 0;
				bottom: 0;
				img {
					width: auto;
					height: auto;
					max-height: 240px;
				}
			}
			&__text {
				> p {
					@media screen and (min-width: $britax-container-max-width-m) {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						white-space: normal;
						height: 56px;
					}
				}
			}
			&__cta {
				max-width: 50%;
				height: 221px;
				display: flex;
				flex-flow: column-reverse;
				> a {
					display: block;
					color: $britax-primary; //Saqlain Customization: Text Color Change
					padding: 12px 0;
					position: relative;
					border-bottom: solid 1px var(--britax-grey);
					&:first-child {
						border-bottom: 0;
					}
					&:hover {
						color: $britax-grey; //Saqlain Customization: Text Color hover change
						text-decoration: none;
					}
				}
			}
		}
	}
	@media screen and (max-width: $britax-container-max-width-m) {
		margin-top: 120px;
	}
}
.category-container {
	.ms-tab-header-section {
		margin-bottom: -68px;
		display: block;
		@media screen and (max-width: $britax-container-max-width-s) {
			margin-bottom: 0;
		}
		&__heading {
			font-size: var(--britax-font-size-xsml);
			color: var(--britax-primary);
			border-bottom: solid 1px var(--britax-grey);
			font-weight: var(--britax-font-weight-bold-full);
			padding-bottom: 20px;
			margin: 0 0 15px;
		}
	}
	.ms-tab-tabs-section {
		justify-content: flex-end;
		margin-right: 8px;
		@media screen and (max-width: $britax-container-max-width-s) {
			justify-content: center;
			margin-right: 0;
		}
		&-li {
			padding: 10px 10px 25px;
			.ms-tab-item-header {
				color: var(--britax-primary);
				font-size: var(--britax-body-font-size-xs);
				font-weight: var(--britax-font-weight-bold);
			}
			&[aria-selected="false"] {
				.ms-tab-item-header {
					color: var(--britax-silver-grey);
					font-weight: var(--britax-font-weight-normal);
					&:hover {
						color: var(--britax-grey);
					}
				}
			}
		}
	}
	.ms-product-collection {
		&__item {
			width: calc(25% - 20px);
			@media screen and (max-width: $britax-container-max-width-l) {
				width: calc(33.33% - 20px);
			}
			@media screen and (max-width: $britax-container-max-width-m) {
				width: calc(50% - 20px);
			}
			@media screen and (max-width: $britax-breakpoint-mbl-sm) {
				width: 100%;
				margin: 0;
			}
		}
		&__heading {
			font-size: var(--britax-font-size-xsml);
			color: var(--britax-grey);
			border-bottom: solid 1px var(--britax-grey);
			padding-bottom: 20px;
			margin: 0 11px 15px;
		}
	}
	.msc-ss-carousel-strip {
		width: calc(100% + 20px);
		margin-left: -10px;
		margin-right: -10px;
	}
}

.ms-content-block {
	min-height: $content-block-min-height;

	&[data-m-layout="full-width"] {
		position: relative;

		.ms-content-block__image,
		.ms-content-block__image img {
			width: 100%;
			height: auto; //Saqlain Customization: Bug Fix: 2597
		}

		.ms-content-block__link {
			width: 100%;
		}

		.ms-content-block__details,
		&.textplacement__left .ms-content-block__details {
			text-align: left;
			align-items: flex-start;
		}

		&.textplacement__center .ms-content-block__details {
			align-items: center;
			text-align: center;
		}

		&.textplacement__right .ms-content-block__details {
			text-align: right;
			align-items: flex-end;
		}

		.ms-content-block__title {
			color: var(--britax-primary);
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
			width: 100%;
			line-height: var(--britax-line-height-ml);
			@media (max-width: $britax-container-max-width-s) {
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-l);
				color: var(--britax-grey);
				text-align: center;
				line-height: initial;
				width: 90%;
				margin: 0 auto 5px;
			}
		}

		.ms-content-block__text {
			font-size: var(--britax-font-size-xm);
			font-weight: var(--britax-font-weight-normal);
			line-height: var(--britax-line-height-l);
			color: var(--britax-white);
		}

		&.texttheme__light .ms-content-block__title,
		&.texttheme__light .ms-content-block__text {
			color: var(--britax-grey);
			text-shadow: $hero-content-shadow;
		}

		&.texttheme__dark .ms-content-block__title,
		&.texttheme__dark .ms-content-block__text {
			color: var(--msv-content-block-textheme-dark);
		}

		/* body should have top margin only when there's something above it */
		/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
		* + .ms-content-block__text {
			margin-top: $hero-content-text-margin-top;
			@media (max-width: $britax-container-max-width-s) {
				margin-top: 0;
			}
		}

		.ms-content-block__cta {
			align-items: flex-end;
			justify-content: center;
		}

		/* links should have top margin only when there's something above it */
		* + .ms-content-block__cta {
			margin-top: $hero-content-button-margin-top;
			@media (max-width: $britax-container-max-width-s) {
				margin-top: 0;
				text-align: center;
			}
		}

		/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
		.ms-content-block__details {
			// @include msv-layout-page-side-padding;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0px;

			.msc-cta__primary {
				@include button-default();
				@include primary-button(
					var(--britax-primary),
					var(--britax-white),
					var(--britax-primary)
				);
				margin: $hero-link-to-link-spacing / 2;
				display: inline-block;
				line-height: 18px;
				transition: all 0.3s;
				border-radius: 25px;
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-white);
					background: var(--britax-grey);
					text-decoration: none;
				}
				@media (max-width: $britax-container-max-width-s) {
					font-size: var(--britax-body-font-size-xs);
					padding: 7px 15px;
					height: 36px;
				}
			}

			.msc-cta__secondary {
				@include button-default();
				@include secondary-button(
					var(--britax-silver-grey),
					var(--britax-primary),
					var(--britax-silver-grey)
				);
				margin: $hero-link-to-link-spacing / 2;
				border-radius: 25px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				line-height: 18px;
				transition: all 0.3s;
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-white);
					background: var(--britax-grey);
					border-color: var(--britax-grey);
					text-decoration: none;
				}
				@media (max-width: $britax-container-max-width-s) {
					font-size: var(--britax-body-font-size-xs);
					padding: 7px 15px;
					height: 36px;
				}
				&[aria-label="login"] {
					font-size: var(--britax-body-font-size-l);
					display: block;
					background: transparent;
					border: 0;
					padding: 0 15px;
					height: auto;
					color: var(--britax-primary);
					@media (max-width: $britax-container-max-width-s) {
						font-size: var(--britax-body-font-size-s);
						color: var(--britax-primary);
						margin: 0;
					}
					&:after {
						display: none;
					}
					&:hover {
						text-decoration: underline;
						background-color: transparent;
						color: var(--britax-grey);
					}
				}
			}
		}

		/**
    * When the image is not added in content block.
    */
		div.ms-content-block__image:empty {
			+ div.ms-content-block__details {
				position: relative;
				height: unset;
			}
		}

		/**
    * Text over image by default only in modern browsers.
    * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
    */

		@supports (object-fit: cover) {
			.ms-content-block__image {
				object-fit: cover;
			}

			@include overlay-text-on-image;
		}

		/**
    * When the image is tall enough for IE 11 to display text on top of it.
    */

		@media screen and (min-width: $msv-breakpoint-l) {
			@include overlay-text-on-image;
		}

		@media screen and (min-width: $msv-breakpoint-m) {
			.ms-content-block__details,
			&.textplacement__left .ms-content-block__details,
			&.textplacement__center .ms-content-block__details,
			&.textplacement__right .ms-content-block__details {
				width: max-content;
				height: auto;
				overflow: inherit;
			}

			&.textplacement__center .ms-content-block__details {
				margin: 0 auto;
			}

			&.textplacement__right .ms-content-block__details {
				margin-left: auto;
			}
		}
	}
}
