$msv-breakpoint-xs: 0;
$msv-breakpoint-s: 0;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1440px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1440px;

$msv-container-min-width-m: 769px;

//Britax breakpoints
$britax-container-min-width-s: 768px;
$britax-container-min-width-m: 992px;
$britax-container-min-width-l: 1220px;
$britax-container-min-width-xl: 1440px;

$britax-container-max-width-s: 767px;
$britax-container-max-width-m: 991px;
$britax-container-max-width-ipad: 1112px;
$britax-container-max-width-l: 1219px;
$britax-container-max-width-xl: 1439px;

$britax-breakpoint-mbl: 436px;
$britax-breakpoint-mbl-sm: 576px;