$footer-ms-footer-padding-top: 48px;
$footer-ms-footer-padding-left: 0px;
$footer-ms-footer-padding-xl: 48px 0;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-max-width: 20%;
$footer-category-full-width: 100%;
$footer-category-padding-bottom: 15px;
$footer-item-padding-desktop: 5px 0;
$footer-item-padding-right-mobile: 28px;
$footer-heading-title-line-height: 24px;
$footer-heading-title-margin-bottom: 0.5rem;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 160px;
$footer-back-to-top-border-radius: 2px;
$footer-categoty-back-to-top-max-width: 0;
$social-footer-padding-left: 60px;

:root {
	--msv-footer-bg: #{$msv-gray-1100};
	// Headings
	--msv-footer-heading-font-color: #{$msv-white};
	--msv-footer-heading-font-size: var(--msv-body-font-size-l);

	//Links
	--msv-footer-link-font-color: #{$msv-white-100};
	--msv-footer-link-font-size: var(--msv-body-font-size-m);
}

footer {
	background-color: var(--britax-white);
	padding-top: 30px;
	.ms-footer {
		&__list {
			flex: initial;
			width: auto;
		}

		.ms-back-to-top {
			@include vfi();
			background: var(--britax-white);
			border: none;
			border-radius: $footer-back-to-top-border-radius;
			bottom: $footer-back-to-top-bottom;
			cursor: pointer;
			display: none;
			height: $footer-back-to-top-height;
			min-width: auto;
			opacity: 0;
			padding: 0;
			position: absolute;
			right: $footer-back-to-top-right;
			text-align: center;
			vertical-align: middle;
			width: $footer-back-to-top-width;
			border-radius: 50%;
			border: solid 1px var(--britax-grey);

			.fa-arrow-up {
				color: var(--britax-primary);
				font-size: var(--msv-body-font-size-xl);
				left: 50%;
				line-height: $footer-back-to-top-line-height;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);

				&::before {
					@include msv-icon();
					content: $msv-ChevronUp;
				}
			}
			&:hover {
				background: var(--britax-grey);
				border: solid 1px var(--britax-grey);
				.fa-arrow-up {
					color: var(--britax-white);
				}
			}
		}

		&__item {
			padding: $footer-item-padding-desktop;
		}

		&__heading__title {
			color: var(--britax-grey);
			font-size: var(--britax-font-size-xsml);
			font-weight: var(--britax-font-weight-bold);
			line-height: $msv-line-height-l;
			margin-bottom: $footer-heading-title-margin-bottom;
		}

		&__link {
			@include vfi();
			display: inline-flex;
			cursor: pointer;

			img {
				@include vfi();
			}

			div,
			&__text {
				color: var(--britax-primary);
				font-size: var(--britax-font-size-sm);
				font-weight: var(--britax-font-weight-normal);
				line-height: $msv-line-height-m;
				@media (min-width: $britax-container-min-width-s) and (max-width: $britax-container-min-width-m) {
					font-size: var(--britax-body-font-size-xs);
				}
				&:hover {
					color: var(--britax-grey);
				}
			}
		}

		& > * {
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
			padding-left: $footer-ms-footer-padding-left;
			> * {
				max-width: 100%;
				padding-bottom: $footer-category-padding-bottom;

				&:first-child {
					max-width: $footer-category-full-width;
				}

				&:last-child {
					max-width: $footer-categoty-back-to-top-max-width;
				}
			}
		}
		.ms-content-block {
			&.britax-footer-logo {
				min-height: auto;
				border-bottom: solid 1px var(--britax-grey);
				padding-bottom: 15px;
				img {
					max-width: 100px;
					height: auto;
				}
			}
		}
		.ms-accordion {
			&-header-section {
				display: none;
			}
			&-item-section {
				.ms-accordion-item {
					.drawer__button {
						height: auto;
						margin-top: 0;
						padding-top: 0;
						color: var(--britax-primary);
						font-weight: var(--britax-font-weight-bold);
					}
				}
			}
			@media screen and (min-width: $britax-container-min-width-s) {
				&-item-section {
					.ms-accordion-item {
						width: 33.33%;
						float: left;
						.drawer__button {
							pointer-events: none;
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.social-footer {
		padding: 10px 20px;
		border-top: solid 1px var(--britax-grey-2);
		.ms-footer__list {
			display: flex;
			width: max-content;
			margin: auto;
			@media (max-width: $msv-breakpoint-m) {
				flex-direction: column;
				margin: 0;
			}
			.ms-footer__item {
				margin-right: 20px;
				@media (min-width: $britax-container-min-width-s) and (max-width: $britax-container-min-width-m) {
					margin-right: 10px;
					font-size: var(--britax-body-font-size-xs);
				}
				&.cookie-selector {
					margin-top: 3px;
					cursor: pointer;
					color: var(--britax-primary);
					&:hover {
						text-decoration: underline;
						color: var(--britax-grey);
					}
				}
			}
			&-item {
				&:last-child {
					.ms-footer__item {
						color: var(--britax-primary);
						margin-top: 3px;
						&:hover {
							color: var(--britax-grey);
						}
					}
				}
			}
		}
		.col-md-4 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	@media (max-width: $msv-breakpoint-m) {
		.ms-footer {
			margin-left: $footer-ms-footer-margin-left-mobile;

			&__item {
				padding-right: $footer-item-padding-right-mobile;
			}

			> * {
				padding-top: $footer-row-padding-top-mobile;

				> * {
					max-width: 100%;

					&:nth-child(5) {
						// .ms-footer__list {
						//   display: inline-flex;
						// }

						@for $i from 3 to ($footer-list-item-icon-number + 2) {
							.ms-footer__list-item:nth-child(#{$i}) {
								left: 0;
							}
						}
					}
				}
			}
		}
	}

	@media (min-width: $msv-breakpoint-m) {
		.ms-footer {
			.ms-back-to-top[aria-hidden="false"] {
				display: block;
				opacity: 1;
				transform: translateX(0%);
			}
		}
	}
}
