//spacing
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 10px;
$heading-padding-top: 2px;
$heading-padding-bottom: 0;
$signin-info-padding: 12px 14px;
$header-height: 40px;
$header-mobile-height: 48px;
$header-icon-width: 40px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$account-links-min-width: 200px;
$signin-link-padding: 8px 0;
$signin-link-height: 48px;
$header_container-max-width: $britax-container-min-width-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin-bottom: -25px;
$header-logo-margin-top: 15px;
$header-logo-margin: 5px auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 25px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;

//style presets
:root {
	--msv-header-bg: #{$msv-gray-1100};
	--msv-header-border: var(--msv-border-color);
	--msv-header-font-color: #{$msv-white};
	--msv-header-font-size: var(--msv-body-font-size-m);
}

header {
	background-color: var(--britax-white);
}

.ms-header {
	background-color: var(--britax-white);
	color: var(--britax-grey);
	border: none;
	margin-left: auto;
	margin-right: auto;
	padding-left: $header-container-spacing-mobile;
	padding-right: $header-container-spacing-mobile;
	box-shadow: 0 0 5px var(--britax-silver-grey);
	@media screen and (max-width: $britax-container-max-width-m) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 17;
	}

	&__container {
		.ms-header__logo {
			a {
				@include vfi();

				&:focus {
					border: solid 1px transparent;
					outline: 0;
				}
				img {
					max-width: 125px;
					height: auto;
					margin-top: -12px;
					@media screen and (max-width: $britax-container-max-width-m) {
						max-width: 82px;
						margin: 4px 0;
					}
				}
			}
			@media screen and (max-width: $britax-container-max-width-m) {
				float: right;
			}
		}

		.ms-header__collapsible-hamburger {
			.ms-header__divider {
				background: var(--britax-white);
				border-bottom: 1px solid $msv-gray-300;
				padding: $divider-padding-m;
			}

			.ms-nav.mobile-vp {
				padding-top: $nav-padding-top-m;
			}
		}
	}

	.ms-header__preferred-store-text {
		color: var(--britax-grey);
		font-size: var(--msv-header-font-size);
		margin-left: $header-myaccount-margin-left;
	}

	.ms-header__preferred-store-btn {
		@include add-icon($msv-Shop-Address);
	}

	.ms-header__preferred-store-btn,
	.ms-search__icon,
	.ms-header__signin-button,
	.msc-wishlist-icon,
	.msc-cart-icon,
	.ms-search__form-cancelSearch {
		background-color: transparent;
		color: var(--britax-primary);
		padding: 8px 12px;
		@include britax-font-content(
			var(--britax-font-weight-normal),
			var(--britax-body-font-size-s),
			$britax-line-height-s
		);

		&:hover,
		&:focus ::before ms-profile-button-text ::after {
			color: var(--britax-grey);
		}
	}

	.ms-header__profile-button {
		background-color: transparent;
		color: var(--britax-primary);
		padding: 8px 12px;
		@include britax-font-content(
			var(--britax-font-weight-normal),
			var(--britax-body-font-size-s),
			$britax-line-height-s
		);

		&:hover,
		&:hover > div,
		&:hover::after,
		&:hover::before {
			color: var(--britax-grey);
		}
	}

	.ms-search__form-cancelSearch {
		color: var(--britax-primary);
		background-color: var(--britax-white);
		@include font-content(
			var(--msv-font-weight-normal),
			var(--msv-header-font-size),
			$msv-line-height-m
		);
		&:focus,
		&:hover {
			color: var(--britax-grey);
		}
	}

	.ms-search__form-control,
	.ms-search__form-cancelSearch {
		&:focus {
			outline-offset: -1px;
		}
	}

	// hiding the header icons from login page
	&.hide-header-options {
		.ms-header__nav-icon,
		.ms-header__account-info.account-desktop,
		.ms-header__account-info.account-mobile,
		.msc-cart-icon,
		.ms-header__divider,
		.msc-wishlist-icon {
			display: none;
		}
	}

	.ms-header__topbar {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		height: $header-mobile-height;
		.ms-site-picker {
			display: none;
		}
	}

	.ms-search {
		display: block;
		border-radius: 3px;
		position: relative;
		border: solid 1px var(--britax-cold-grey);
		&:hover,
		&:focus * search-by-barcode-btn {
			color: var(--britax-grey);
			border: solid 1px var(--britax-grey);
		}
	}

	.ms-header__desktop-view {
		display: none;
	}

	.ms-header__account-info {
		@include font-content(
			var(--msv-font-weight-normal),
			var(--msv-header-font-size),
			$msv-line-height-m
		);
		float: right;
		@media screen and (max-width: $britax-container-max-width-m) {
			display: none;
		}
		&__account-link-container {
			height: $account-link-height;
		}

		/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
		a {
			align-items: center;
			display: flex;
			height: 100%;
			font-size: var(--britax-body-font-size-xs);
			line-height: $msv-text-line-height;
			padding: $signin-link-padding;
			color: var(--britax-primary);
			border-bottom: solid 1px var(--britax-grey-2);

			&:hover {
				text-decoration: none;
				color: var(--britax-grey);
			}
			&:last-child {
				border-bottom: none;
			}
			&::before {
				@include britax-icon();
				content: $britax-icon-signout;
				font-size: var(--britax-body-font-size-xs);
				margin-right: 10px;
			}
			&:nth-child(1) {
				&::before {
					content: $britax-icon-account1;
				}
			}
			&:nth-child(2) {
				&::before {
					content: $britax-icon-order-history;
				}
			}
			&:nth-child(3) {
				&::before {
					content: $britax-icon-user;
				}
			}
		}

		.ms-header__signin-button {
			background-color: var(--britax-white);
			color: var(--britax-grey);
			font-weight: var(--msv-font-weight-normal);
			font-size: var(--britax-body-font-size-s);

			&:focus {
				outline-offset: -1px;
			}
		}

		.msc-popover {
			background-color: var(--britax-white);
			background-clip: padding-box;
			border: 0;
			box-shadow: 0px 0px 3px var(--britax-cold-grey);
			border-radius: 3px;
			padding: 0 10px;
			margin-top: 5px;
			display: block;
			left: 0;
			max-width: $account-links-max-width;
			min-width: $account-links-min-width;
			position: absolute;
			top: 0;
			word-wrap: break-word;
			z-index: 1060;
			text-transform: capitalize;

			.msc-btn {
				color: var(--britax-grey);
				font-weight: var(--msv-font-weight-normal);
			}

			.msc-arrow {
				display: none;
				height: 0.5rem;
				margin: 0 0.3rem;
				position: absolute;
				top: calc((0.5rem + 1px) * -1);
				width: 1rem;

				&::before,
				&::after {
					border-color: transparent;
					border-style: solid;
					border-width: 0 0.5rem 0.5rem 0.5rem;
					content: "";
					display: block;
					position: absolute;
				}

				&::before {
					border-bottom-color: var(--britax-cold-grey);
					top: 0;
				}

				&::after {
					border-bottom-color: var(--britax-silver-grey);
					top: 1px;
				}
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
	.msc-wishlist-icon {
		&:hover {
			text-decoration: none;
		}
	}

	.ms-header__wishlist-desktop {
		align-items: center;
		display: none;
		height: $header-height;
		width: $header-icon-width;

		@include add-icon($msv-Heart, before, 400);

		&::before {
			font-size: var(--msv-header-font-size);
			text-align: center;
			vertical-align: text-bottom;
			width: $header-icon-width;
			color: var(--britax-grey);
		}

		.msc-wishlist-icon__text {
			display: none;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
	.ms-header__nav-icon {
		align-items: center;
		background: var(--britax-white);
		display: flex;
		height: $header-height;
		justify-content: center;
		width: $header-icon-width;
		padding: 0;
		margin-top: 5px;
		float: left;

		&:focus {
			outline-offset: -1px;
		}

		&::before {
			@include msv-icon();
			content: $msv-GlobalNavButton;
			font-size: var(--britax-font-size-xsml);
			vertical-align: text-bottom;
			text-align: center;
			color: var(--britax-grey);
		}

		&:hover {
			text-decoration: none;
		}
		&[aria-expanded="true"] {
			&::before {
				@include msv-icon();
				content: $msv-Cancel;
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
	.msc-cart-icon {
		// width: $header-icon-width;
		// height: $header-height;
		display: flex;
		align-items: center;
		position: relative;

		&::before {
			@include britax-icon();
			content: $britax-icon-cart;
			font-size: var(--britax-font-size-xxml);
			vertical-align: text-bottom;
			text-align: center;
		}

		&:hover {
			text-decoration: none;
			color: var(--britax-grey);
		}

		// &__count {
		// 	@include font-content-s();
		// 	position: absolute;
		// 	top: -1px;
		// 	right: 0;
		// 	font-size: var(--britax-breadcrumb-icon);
		// 	color: var(--britax-white);
		// 	background: var(--britax-primary-red);
		// 	font-weight: var(--britax-font-weight-bold);
		// 	border-radius: 25px;
		// 	padding: 1px 4px 2px;
		// 	line-height: initial;
		// }

		&__count-with-products {
			@include font-content-s();
			position: absolute;
			top: -1px;
			right: 0;
			font-size: var(--britax-breadcrumb-icon);
			color: var(--britax-white);
			background: #d50c2d;
			font-weight: var(--britax-font-weight-bold);
			border-radius: 25px;
			padding: 1px 4px 2px;
			line-height: initial;
		}
		&__count-empty-cart {
			@include font-content-s();
			position: absolute;
			top: -1px;
			right: 0;
			font-size: var(--britax-breadcrumb-icon);
			color: var(--britax-white);
			background: var(--britax-silver-grey);
			font-weight: var(--britax-font-weight-bold);
			border-radius: 25px;
			padding: 1px 4px 2px;
			line-height: initial;
		}
	}

	@media (min-width: $msv-breakpoint-xs) {
		.ms-nav.desktop-vp {
			display: none;
		}
	}

	@media (max-width: $britax-container-min-width-m) {
		.msc-wishlist-icon {
			@include add-icon($msv-Heart, before, 400);

			&::before {
				padding-right: $header-icon-padding-right;
			}
		}

		&__container {
			.ms-header__topbar {
				justify-content: space-between;

				.ms-header__logo {
					margin: $header-logo-margin;
				}

				.ms-site-picker {
					display: none;
				}
			}

			.ms-header__collapsible-hamburger {
				.ms-nav {
					.ms-nav__drawer {
						.drawer__button {
							@include font-content(
								var(--msv-font-weight-normal),
								var(--msv-header-font-size),
								$msv-line-height-m
							);
							color: var(--britax-grey);
							background: transparent;
							text-align: left;
							width: 100%;

							.ms-nav__drawer-open {
								@include add-icon($msv-ChevronUp, after);
							}

							.ms-nav__drawer-close {
								@include add-icon($msv-ChevronDown, after);
							}

							.drawer__glyph__end {
								position: absolute;
								right: 10px;
							}
						}

						.msc-btn {
							outline-offset: -1px;
						}
					}

					.ms-nav__list__item__link {
						@include font-content(
							var(--msv-font-weight-normal),
							var(--msv-header-font-size),
							$msv-line-height-m
						);
						align-items: center;
						display: flex;
						padding: $header-list-item-link-padding;
					}
				}
			}
		}

		.ms-header__account-info.account-mobile {
			display: unset;
			margin-left: 0;

			/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
			.drawer__button {
				@include font-content(
					var(--msv-font-weight-normal),
					var(--msv-header-font-size),
					$msv-line-height-m
				);
				@include add-icon($msv-Contact, before, 400);

				&::before {
					padding-right: $header-icon-padding-right;
				}
				background: transparent;
				text-align: left;
				width: 100%;

				.ms-header__drawer-open {
					@include add-icon($msv-ChevronUp, after);
				}

				.ms-header__drawer-close {
					@include add-icon($msv-ChevronDown, after);
				}

				/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
				.drawer__glyph__end {
					position: absolute;
					right: $header-icon-padding-right;
				}
			}

			.ms-header__signin-button {
				display: flex;
				justify-content: flex-start;

				@include add-icon($msv-Contact, before, 400);

				&::before {
					padding-right: $header-icon-padding-right;
				}
			}

			/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
			.msc-btn {
				outline-offset: -1px;
			}
		}

		.ms-header__collapsible-hamburger {
			background: var(--britax-white);
			left: 0;
			margin-top: 2px;
			position: absolute;
			width: 100%;
			z-index: 1012;

			.ms-header__wishlist-mobile {
				text-align: left;
				width: 100%;
			}

			.ms-search {
				justify-content: flex-start;
				display: flex;

				.ms-search__label {
					display: flex;
					width: 100%;

					.ms-search__icon {
						display: flex;
						justify-content: flex-start;
						width: 100%;
					}

					.ms-search__icon-text {
						display: unset;
						padding-left: $header-icon-padding-left;
					}
				}
			}
		}
	}

	@media (min-width: $britax-container-min-width-m) {
		.ms-header__desktop-view {
			display: block;
		}

		.ms-nav.desktop-vp {
			display: flex;
		}

		&__modal {
			display: none;
		}

		&__profile-button {
			color: var(--britax-primary);
			font-weight: var(--msv-font-weight-normal);
			background: var(--britax-white);

			&::after {
				@include msv-icon();
				content: $msv-ChevronDown;
				color: var(--britax-primary);
				font-size: var(--msv-header-font-size);
				margin-left: $header-myaccount-margin-left;
				text-align: center;
				margin-top: 5px;
			}
			&::before {
				@include britax-icon();
				content: $britax-icon-user;
				font-size: var(--britax-body-font-size-xs);
				margin-right: 10px;
				background: var(--britax-grey-2);
				padding: 5px;
				border-radius: 50%;
				width: 30px;
				height: 30px;
				line-height: 20px;
				text-align: center;
				float: left;
			}
			.ms-profile-button-text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				max-width: 150px;
				float: left;
				margin-top: 4px;
				text-transform: capitalize;
				color: var(--britax-primary);
				@media screen and (max-width: $britax-container-min-width-l) {
					max-width: 84px;
				}
				&::hover,
				&::focus {
					color: var(--britax-grey);
				}
			}
		}

		&__profile-button[aria-expanded="true"] {
			&::after {
				content: $msv-ChevronUp;
			}
		}

		&__profile-button[aria-expanded="false"] {
			&::after {
				content: $msv-ChevronDown;
			}
		}

		&__divider {
			background: $msv-divider-color;
			height: $divider-margin-height;
			margin-left: $divider-margin-left;
			margin-right: $divider-margin-right;
			width: $divider-margin-width;
		}

		.ms-header__account-info.account-desktop {
			display: block;
		}

		.ms-header__collapsible-hamburger {
			display: none;

			.ms-header__wishlist-mobile {
				text-align: left;
				width: 100%;
			}
		}

		.ms-search {
			display: block;
			border-radius: 3px;
			position: relative;
			border: solid 1px var(--britax-primary);

			&__icon {
				align-items: center;
				color: var(--britax-primary);
				font-weight: normal;

				&:focus {
					outline-offset: -2px;
				}
			}
		}

		.ms-header__wishlist-desktop {
			display: none;
		}

		.ms-header__nav-icon {
			display: none;
		}

		.ms-cart-icon {
			padding-left: $cart-icon-spacing-left;
			width: unset;
			float: right;

			&::before {
				width: unset;
				margin-right: 4px;
			}
		}
	}
	.header-sidebar {
		z-index: 99;
		.acccountBlock {
			float: right;
		}
		.searchBlock {
			clear: both;
			.ms-cart-icon {
				float: right;
			}
			.ms-search {
				float: right;
				width: 290px;
				position: relative;
				&__icon {
					width: auto;
				}
				&__icon-text {
					display: none;
				}
			}
		}
	}
	.top-navigation {
		background-color: var(--britax-grey-2);
		.ms-header__logo {
			display: none;
		}
	}
	.sticky-header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 17;
		background: var(--britax-white);
		// box-shadow: 0 0 5px var(--britax-silver-grey);
		// @media screen and (max-width: $britax-container-max-width-s) {
		//   display: none;
		// }
		// .ms-header__logo {
		//   position: absolute;
		//   z-index: 18;
		//   top: 5px;
		//   left: 30px;
		//   a img {
		//     max-width: 85px;
		//     margin-top: 0;
		//   }
		// }
		.ms-nav > .ms-nav__list {
			width: calc(100% - 280px);
			margin-left: 140px;
		}
	}
	&__main-header {
		align-items: center;
		padding: 0 0 5px;
		clear: both;
		@media screen and (max-width: $britax-container-max-width-m) {
			padding-bottom: 5px;
			padding-top: 10px;
		}
		&__logo-container {
			width: 160px;
			float: left;
			@media screen and (max-width: $britax-container-max-width-m) {
				width: 57%;
			}
		}
		&__search-container {
			max-width: 600px;
			margin: -10px auto 15px;
			position: relative;
			@media screen and (max-width: $britax-container-min-width-l) {
				max-width: 400px;
			}
			@media screen and (max-width: $britax-container-max-width-m) {
				max-width: 100%;
				display: block;
				clear: both;
				margin: 5px 0;
				.ms-search {
					height: 42px;
					&__icon {
						width: 100%;
					}
					&__autoSuggest {
						margin-top: 41px;
						box-shadow: 0 0 10px var(--britax-grey-2);
						border-top: 0;
						border-radius: 0 0 5px 5px;
						overflow-y: auto;
						max-height: calc(100vh - 110px);
					}
				}
			}
			.search-by-barcode-btn {
				position: absolute;
				top: 10px;
				right: 70px;
				border: 0;
				background: transparent;
				text-decoration: underline;
				color: var(--britax-primary);
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-xs);
				&:hover,
				&:focus {
					color: var(--britax-grey);
				}
			}
			.close-search {
				position: absolute;
				top: 11px;
				right: 45px;
				font-size: 0;
				border: 0;
				color: var(--britax-primary);
				background-color: transparent;
				&:after {
					@include msv-icon();
					content: $msv-Cancel;
					font-size: var(--britax-font-size-sm);
				}
			}
			.search-by-barcode {
				border-radius: 3px;
				position: relative;
				padding: 0 10px;
				border: solid 1px var(--britax-cold-grey);
				input[type="text"] {
					width: 98%;
					border: 0;
					padding: 10px 0;
					&:focus-visible {
						border: 0;
						outline: 0;
					}
				}
				p {
					position: absolute;
					margin: 11px 0;
				}
				.search-btn {
					position: absolute;
					top: 10px;
					right: 10px;
					font-size: 0;
					border: 0;
					background-color: transparent;
					color: var(--britax-grey);
					text-decoration: none;
					&:after {
						@include msv-icon();
						content: $msv-magnifying-glass;
						font-size: var(--britax-font-size-sm);
					}
				}
			}
			.ms-search__icon {
				width: calc(100% - 25px);
			}
		}
		&__right-container {
			width: 300px;
			float: right;
			margin-top: 12px;
			@media screen and (max-width: $britax-container-min-width-l) {
				width: 244px;
			}
			@media screen and (max-width: $britax-container-max-width-m) {
				width: 65px;
				margin-top: 0;
			}
			.ms-header__preferred-store-container {
				display: none;
			}
		}
	}
	&__head {
		@media screen and (max-width: $britax-container-max-width-m) {
			display: none;
		}
		padding: 5px 0;
		a {
			color: var(--britax-primary);
			padding: 3px 10px;
			line-height: 12px;
			float: right;
			margin-right: 10px;
			&.language {
				position: relative;
				padding-left: 20px;
				&:before {
					@include britax-icon();
					content: $britax-icon-language;
					position: absolute;
					top: 5px;
					left: 0;
				}
			}
		}
		.youtube {
			float: right;
			text-decoration: none;
			&:after {
				@include britax-icon();
				content: $britax-icon-youtube;
				font-size: var(--britax-font-size-xxml);
				margin-top: -4px;
				color: #659eb5;
			}
			&:hover {
				text-decoration: none;
			}
		}
		&__top-links {
			overflow: hidden;
			.language-picker {
				&__form {
					position: relative;
					padding: 0 10px 0 20px;
					float: right;
					&:before {
						@include britax-icon();
						content: $britax-icon-language;
						position: absolute;
						top: 2px;
						left: 0;
						color: var(--britax-primary);
					}
					select {
						background-color: transparent;
						border: 0;
						outline: none;
						color: var(--britax-primary);
						-webkit-appearance: none;
						padding-right: 20px;
						position: relative;
						z-index: 2;
						option {
							background-color: var(--britax-grey-2);
							padding: 5px;
						}
					}
					&:after {
						@include msv-icon();
						content: $msv-ChevronDown;
						font-size: var(--britax-body-font-size-xs);
						top: 3px;
						right: 12px;
						position: absolute;
						color: var(--britax-primary);
						z-index: 1;
					}
				}
			}
		}
	}
	.ms-cart-icon {
		float: right;
		.msc-flyout__close-button {
			display: none;
		}
	}
}
.ms-header__modal #header-nav-mobile .ms-header__account-info {
	display: flex;
	height: 70px;
	background: var(--britax-primary);
	align-items: center;
	.ms-header__profile-button {
		font-weight: var(--msv-font-weight-normal);
		display: flex;
		width: 100%;
		text-align: left;
		background: transparent;
		padding: 0 0 0 15px;
		color: var(--britax-white);
		align-items: center;

		&::after {
			@include msv-icon();
			content: $msv-ChevronDown;
			font-size: var(--msv-header-font-size);
			margin-left: $header-myaccount-margin-left;
			text-align: center;
			vertical-align: text-top;
			position: absolute;
			right: 35px;
			transform: rotate(-90deg);
		}
		&[aria-expanded="true"] {
			&::before {
				transform: none;
			}
		}

		&::before {
			@include britax-icon();
			content: $britax-icon-user;
			font-size: var(--britax-body-font-size-xs);
			margin-right: 10px;
			background: var(--britax-grey);
			color: var(--britax-primary);
			padding: 5px;
			border-radius: 50%;
			width: 36px;
			height: 36px;
			line-height: 25px;
			text-align: center;
		}
		.ms-profile-button-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
		}
	}
	@include font-content(
		var(--msv-font-weight-normal),
		var(--msv-header-font-size),
		$msv-line-height-m
	);
	&__account-link-container {
		height: $account-link-height;
	}

	/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
	a {
		align-items: center;
		display: flex;
		height: 100%;
		font-size: var(--britax-body-font-size-xs);
		line-height: $msv-text-line-height;
		padding: $signin-link-padding;
		color: var(--britax-grey);
		border-bottom: solid 1px var(--britax-grey-2);

		&:hover {
			text-decoration: none;
			color: var(--britax-primary);
		}
		&:last-child {
			border-bottom: none;
		}
		&::before {
			@include britax-icon();
			content: $britax-icon-signout;
			font-size: var(--britax-body-font-size-xs);
			margin-right: 10px;
		}
		&[title="My account"] {
			&::before {
				content: $britax-icon-account1;
			}
		}
		&[title="Order history"] {
			&::before {
				content: $britax-icon-order-history;
			}
		}
		&[title="My profile"] {
			&::before {
				content: $britax-icon-user;
			}
		}
	}

	.ms-header__signin-button {
		background-color: var(--britax-white);
		color: var(--britax-grey);
		font-weight: var(--msv-font-weight-normal);
		font-size: var(--britax-body-font-size-s);

		&:focus {
			outline-offset: -1px;
		}
	}

	.msc-popover {
		background-color: var(--britax-white);
		background-clip: padding-box;
		border: 0;
		box-shadow: 0px 0px 3px var(--britax-cold-grey);
		border-radius: 3px;
		padding: 0 10px;
		margin-top: 5px;
		display: block;
		left: 0;
		width: 100%;
		transform: none !important;
		position: absolute;
		top: 65px !important;
		word-wrap: break-word;
		z-index: 1060;
		text-transform: capitalize;

		.msc-btn {
			color: var(--britax-grey);
			font-weight: var(--msv-font-weight-normal);
		}

		.msc-arrow {
			display: none;
			height: 0.5rem;
			margin: 0 0.3rem;
			position: absolute;
			top: calc((0.5rem + 1px) * -1);
			width: 1rem;

			&::before,
			&::after {
				border-color: transparent;
				border-style: solid;
				border-width: 0 0.5rem 0.5rem 0.5rem;
				content: "";
				display: block;
				position: absolute;
			}

			&::before {
				border-bottom-color: var(--britax-cold-grey);
				top: 0;
			}

			&::after {
				border-bottom-color: var(--britax-silver-grey);
				top: 1px;
			}
		}
	}
}

@media (max-width: 999px) {
	.ms-header__modal {
		.ms-header__account-info {
			display: block;
		}
		.msc-modal__backdrop {
			display: none;
		}
		.msc-modal {
			top: 65px;
			.msc-modal__content {
				max-width: 100%;
				background: var(--britax-white);
				.ms-nav {
					ul {
						li {
							display: block;
							button {
								background: $msv-white-smoke;
								font-weight: 500;
							}
						}
					}
				}
				.msc-modal__header {
					display: none;
				}
			}
		}
	}
}
.guest-landing-page,
.business_partner {
	main#main {
		min-height: calc(100vh - 114px);
	}
	footer {
		.ms-footer {
			.ms-back-to-top {
				bottom: 10px;
			}
		}
	}
}
@media screen and (max-width: $britax-container-max-width-m) {
	#main {
		padding-top: 120px;
	}
	.b2bhome,
	.business_partner,
	div[data-exp-event-id*="sign-in.PageView"],
	div[data-exp-event-id*="sign-up.PageView"],
	div[data-exp-event-id*="PasswordReset.PageView"],
	div[data-exp-event-id*="ProfileEdit.PageView"],
	div[data-exp-event-id*="sign-up.PageView"],
	div[data-exp-event-id*="PasswordResetVerification .PageView"] {
		#main {
			padding-top: 0;
		}
	}
}
.login-header .col-12:last-child,
.login-header .row .col-12:last-child {
	position: absolute;
	top: 0;
	left: 0;
	min-height: auto;
	height: 110px;
	width: 200px;
	z-index: 17;
}
.skip-to-main {
	color: var(--britax-grey);
}
