$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

.msc-add-to-cart {
  @include primary-button();
  @include vfi();

  &:not(:disabled) {
    &:hover {
      cursor: pointer;
    }
  }
  &:focus {
    background-color: var(--britax-primary);
  }
}

// .msc-cart-line__quantity__select-menu {
//   display: none;
// }

.PromoHeader {
  font-size: var(--britax-body-font-size-xs);
  color: var(--britax-primary);
  position: relative;
  cursor: pointer;
}

.PromoContent {
  .msc-promo-code-heading {
    display: none;
  }

  .msc-promo-code__line-container {
    display: flex;
    justify-content: space-between;
  }

  .msc-promo-code__line-container > .msc-promo-code__line__btn-remove {
    padding: 0 0 0 0;
  }

  .msc-promo-code__line-container
    > .msc-promo-code__line-value
    .msc-promo-code__line-discount-value {
    display: none;
  }
}

.PromoContent-Line {
  .msc-promo-code-heading {
    display: none;
  }
  .msc-promo-code__form-container {
    display: none;
  }

  .msc-promo-code__discount {
    display: none;
  }

  .msc-promo-code__line-container > .msc-promo-code__line__btn-remove {
    display: none;
  }

  .msc-promo-code__line-container > .msc-promo-code__line-value {
    display: flex;
    justify-content: space-between;
  }

  .msc-promo-code__line-container
    > .msc-promo-code__line-value
    > .msc-promo-code__line-discount-value {
    font-weight: var(--britax-font-weight-700);
  }

  .msc-promo-code__line-container {
    border-bottom: none;
    font-size: var(--britax-body-font-size-xs);
  }
}

.msc-promo-code-heading {
  font-size: 0px;
}

.msc-promo-code-heading {
  margin-top: "-28px";
}
.counter {
  margin-left: 350px !important;
  margin-top: 1px;
}

span.quantity-box {
  margin-right: 20px !important;
  margin-left: 130px;
}
.msc-modal {
  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .msc-add-to-cart {
    &__dialog {
      max-width: $msv-dialog-width;

      .msc-modal__content {
        padding: $msv-dialog-modal-content-padding;
      }

      &__header {
        .msc-modal__title {
          @include font-content-l();

          span {
            &:nth-child(1) {
              font-weight: var(--msv-font-weight-bold);
            }
          }
        }
      }

      &__body {
        @include font-content-l();
        text-align: center;

        .msc-empty_image {
          @include image-placeholder($msv-dialog-msc-empty_image-size);
          padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
        }
      }

      &__image {
        margin-right: auto;
        margin-left: auto;
      }

      &__product-title {
        padding: $msv-dialog-modal-content-body-text-padding;
      }

      &__product-variants {
        @include font-content-m();
        padding: $msv-dialog-modal-content-body-text-padding;
      }

      &__product-price {
        font-weight: var(--msv-font-weight-bold);
        padding: $msv-dialog-modal-content-body-text-padding;
      }

      &__go-to-cart {
        @include primary-button();
        margin: $msv-dialog-modal-content-button-margin;
        width: $msv-dialog-button-width;
      }

      &__back-to-shopping {
        @include secondary-button();
        margin: $msv-dialog-modal-content-button-margin;
        width: $msv-dialog-button-width;
      }
    }
  }
}
