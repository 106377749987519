.claim-form {
  margin-top: 30px;
  margin-bottom: 30px;
  legend {
    background-color: var(--britax-grey-2);
    padding: 20px;
    margin: 15px 0 30px;
  }

  .__order-information {
    margin-bottom: 20px;
  }

  .productlegend {
    background-color: var(--britax-grey-2);
    padding: 20px;
    margin: 15px 0 0px;
  }
  .product-info_legend {
    background-color: var(--britax-grey-2);
    padding: 20px;
    margin: 15px 0 15px;
  }

  label {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .show {
    max-height: 30em;
    overflow: hidden;
    transition: max-height 1s, overflow 0.5s 0.5s;
    margin-bottom: 20px;
  }

  .hide {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s, overflow 0s;
  }

  .validation-error {
    font-weight: 300;
    color: rgb(222, 32, 32);
    line-height: 1.5;
  }

  .form-control {
    height: 60px;
    border-radius: 4px;
    border: solid 1px var(--britax-silver-grey);

    &_table {
      height: 30px;
      padding: 0 2px 0 2px;
      margin: 0;
      width: fit-content;
    }

    &_qnty {
      width: 60px;
    }

    &_color {
      width: 140px;
    }

    &_id {
      width: 130px;
    }

    &_serl {
      width: 127px;
    }

    &_reason {
      width: 200px;
    }

    &_product {
      width: 180px;
    }

    &_accident {
      width: 104px;
    }

    &_productname {
      width: 510px;
    }

    &__productnameshipment {
      width: 450px;
    }

    &_shipdate {
      width: 120px;
    }

    &_sku {
      width: 120px;
    }

    &_claimreason {
      width: 200px;
    }

    &_cmnt {
      width: 150px;
    }

    &_rcvd_qnty {
      width: 160px;
    }

    &_list {
      ul {
        list-style: none;
        padding: 0;
        margin: 6px 0 0 0;
        position: absolute;
        background: var(--britax-white);
        border: solid 1px var(--britax-silver-grey);
        z-index: 1;
        width: 25%;
        border-radius: 4px;

        &:empty {
          border: 0;
        }

        li {
          padding: 8px 15px;

          &:hover {
            background-color: var(--britax-grey-2);
          }
        }
      }
    }

    //line level style - start
    &.comment-line-level {
      height: 100px !important;
      width: 100%;
    }

    &.claim-reason-dropdown {
      width: 75%;
      height: 60px !important;
    }

    &.input-normal {
      width: 95%;
      height: 60px;
    }

    //line level style - end

    &.textarea {
      height: 150px;
    }

    &.comment {
      height: 80px;
      margin-bottom: 15px;
    }
  }

  .proof-purchase {
    padding: 15px;
  }

  &__footer {
    padding: 15px;

    &__submit {
      @include primary-button();
      margin-left: 10px;
      &.is-busy {
        &::before {
          @include msv-icon();
          content: $msv-Spinner;
          margin-right: $msv-checkout-icon-margin-right;
          -webkit-animation: spin 1s steps(8) infinite;
          animation: spin 1s steps(8) infinite;
        }

        cursor: progress;
      }
    }

    &__cancel {
      @include grey-button();
    }
  }

  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }

  .form-check-input {
    position: relative;
    margin: 0 5px 0 0;
  }

  .btn-submit {
    @include primary-button();
    margin-right: 10px;
  }

  .btn-cancel {
    @include grey-button();
  }

  .full-heading {
    width: 100%;
    margin-bottom: 5px;
  }

  .product-name {
    input {
      height: 60px;
      width: 100%;
      border-radius: 4px;
      margin: 10px 0 30px;
      padding: 0.375rem 0.75rem;
      border: solid 1px var(--britax-silver-grey);
      color: var(--britax-grey);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: -24px 0 0 0;
      position: absolute;
      background: var(--britax-white);
      border: solid 1px var(--britax-silver-grey);
      z-index: 1;
      width: calc(100% - 30px);
      border-radius: 4px;

      &:empty {
        border: 0;
      }

      li {
        padding: 8px 15px;

        &:hover {
          background-color: var(--britax-grey-2);
        }
      }
    }
  }

  .table-wrapper {
    max-height: 350px;
    overflow: auto;
  }

  .file-upload-btn {
    background-color: #7f9ccb;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px ridge black;
    font-size: 0.8rem;
    height: auto;
  }

  .file-upload-btn:hover {
    background-color: #4a78c3;
  }

  .selected-files {
    margin: 0 20px 10px 0;
    position: relative;
    display: inline-block;
    padding: 6px 12px;
    border: none;
    background-color: var(--britax-primary);
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .cross-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: #f00;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .selected-files:hover .cross-icon {
    opacity: 1;
  }

  .row-margin {
    margin-top: 19px;
  }
  .row-margin-linespecific {
    margin-top: 1px;
  }
}

.input-attachment {
  opacity: 0;
  width: 0px;
  height: 0px;
}
.modal-upload-file {
  display: flex;
  flex-direction: column;
}

.product-claim-modal-header {
  margin-top: 5px;
}
.product-modal {
  width: fit-content;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  height: 60px;
  border-radius: 4px;
  margin: 10px 0 30px;
  border: solid 1px var(--britax-silver-grey);
}

.claim-form__continue {
  @include primary-button-others();
  border-radius: 25px;
  margin-left: $msv-checkout-place-order-button-margin;
}

.claim-form_cancel {
  @include secondary-button-checkout-keep-shopping();
  border-radius: 25px;
  margin-left: $msv-checkout-place-order-button-margin;
}

.custom-table {
  width: 100%;
  margin-bottom: 40px;
}

// columns style in claim product table
.claims-modal__line {
  width: 100%;
  padding: 15px;
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:nth-child(odd) {
    background-color: var(--britax-silver-grey);
  }

  label {
    margin-bottom: 0;
  }

  .checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: transparent;
      border: 1px solid var(--britax-cold-grey);
      border-radius: 4px;
    }

    input:checked ~ .checkmark {
      background-color: var(--britax-primary);
      border-color: var(--britax-primary);
    }

    .checkmark::after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2.25px;
      width: 5px;
      height: 10px;
      border: solid var(--britax-white);
      background-color: var(--britax-primary);
      border-width: 0 2.75px 2.75px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input:checked ~ .checkmark::after {
      display: block;
    }
  }

  .linenumber {
    width: 5.25%;
    text-align: center;
  }

  .itemnumber {
    width: 8.5%;
    text-align: center;
  }

  .itemname {
    width: 40%;
    text-align: center;
  }

  .priceperunit {
    width: 8.5%;
    text-align: center;
  }

  .currency {
    width: 8.5%;
    text-align: center;
  }

  .quantity {
    width: 8.5%;
    text-align: center;
  }

  .discountpercentage {
    width: 8.5%;
    text-align: center;
  }
  .product {
    width: 40%;
    text-align: center;
  }
  .sku {
    width: 8.5%;
    text-align: center;
  }

  .price {
    width: 8.5%;
    text-align: center;
  }

  .qty {
    width: 8.5%;
    text-align: center;
  }

  .totalamount {
    width: 8.5%;
    text-align: center;
  }
}

.row-padding-wrapper {
  padding-left: 30px;
  width: 100%;
}

.row-padding {
  padding-left: 30px;
}

//PRICE CLAIM LINE LEVEL STYLING -- VSI CUSTOMIZATION: SAQLAIN - CR 959 - CLAIMS MANAGEMENT - START
.price-claim-line-container {
  width: 100%;
  padding: 15px;
  margin: 10px 0px;
  border: solid 1px var(--britax-primary);
  background-color: var(--britax-silver-grey);
  border-radius: 5px;

  .price-claim_line-product-info {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 2px;

    .linenumber {
      margin: 0 8px;
    }

    .itemnumber {
      margin: 0 8px;
    }

    .itemname {
      width: 285px;
      text-align: center;
      font-weight: bold;
    }

    .priceperunit {
      margin-right: 15px;
    }

    .currency {
      margin-right: 15px;
    }

    .quantity {
      margin-right: 15px;
    }

    .discountpercentage {
      margin-right: 15px;
    }
  }
}

.price-claim_line-product-info-header {
  width: 100%;
  margin: 10px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
  padding: 0px 15px 0px 15px;

  .linenumber {
    margin: 0px;
  }

  .itemnumber {
    margin: 0 8px;
  }

  .itemname {
    width: 390px;
    text-align: center;
    font-weight: bold;
  }

  .priceperunit {
    margin-right: 15px;
  }

  .currency {
    margin-right: 15px;
  }

  .quantity {
    margin-right: 5px;
  }

  .discountpercentage {
    margin-right: 15px;
  }
}

//PRICE CLAIM LINE LEVEL STYLING -- VSI CUSTOMIZATION: SAQLAIN - CR 959 - CLAIMS MANAGEMENT - END

//PRODUCT CLAIM

.product-claim-table table td {
  padding: 2px !important;
}
