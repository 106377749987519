$buybox-margin-top: 36px;
$buybox-padding-left: 40px;
$buybox-padding-right: 40px;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin: 20px;
$buybox-box-bottom-margin: 20px;
$buybox-store-description-margin-bottom: 8px;
$buybox-add-to-cart-extra-actions-margin: 8px;
$buybox-add-to-cart-extra-actions-width: 48px;
$buybox-product-status-padding-top: 18px;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width: 50%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$buybox-custom-amount-input-width: 80px;
$buybox-custom-amount-input-padding: 5px;
$buybox-thumbnail-list-margin-bottom: 8px;

$britax-image-path: "../../../";

//style presets
:root {
  --msv-buybox-btn-bg: #{$msv-white};
  --msv-buybox-btn-font-color: var(--msv-font-primary-color);
  --msv-buybox-btn-border-color: var(--msv-border-color);
  --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

  //title
  --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
  --msv-buybox-title-font-color: #{$msv-gray-900};

  // rating star
  --msv-buybox-rating-star: var(--msv-font-primary-color);
  --msv-buybox-btn-extra-actions-color: var(--msv-accent-brand-color);

  // wishlist button
  --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

  //text
  --msv-buybox-text-font-size: var(--msv-body-font-size-m);
  --msv-buybox-text-font-color: var(--msv-font-primary-color);

  // select box
  --msv-buybox-select-box-border: #{$msv-whisper};

  // increment button
  --msv-buybox-increment-btn-border: #{$msv-whisper};

  // decrement button
  --msv-buybox-decrement-btn-border: #{$msv-whisper};

  // quantity
  --msv-buybox-quantity-box-border: #{$msv-whisper};
  // primary button
  --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

  // secondary button
  --msv-buybox-secondary-btn-bg: var(--msv-bg-color);
  --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
  --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-buybox {
  display: flex;
  flex-flow: row;

  &__inventory-info {
    margin-top: $buybox-box-top-margin;
  }
  &__product-title {
    font-size: var(--britax-body-font-size-ml);
    color: var(--britax-primary);
    text-align: left;
    margin-bottom: 8px;
    line-height: var(--britax-body-font-size-xl);
    font-weight: var(--britax-font-weight-700);
    &:hover {
      color: var(--britax-grey);
    }
  }
  &__btn-view-detail-buybox {
		&.is-busy {
			@include add-spinner(before);
		}

		@include primary-button();
		&:focus {
			background-color: var(--britax-primary);
			color: var(--britax-white);
			border-color: var(--britax-primary);
		}
	}
  &__content {
    width: 50%;
    .per-unit-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .unit-left-blk {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .msc-add-to-cart {
          margin: 0;
        }
        .msc-add-to-cart-custom {
          margin: 0;
        }
        .msc-cart__btn-addcarttotemplate {
          margin-top: 10px;
        }
      }
      .unit-right-blk {
        .product-per_unit {
          margin-top: 8px;
        }
        .msc-add-to-cart {
          margin: 0;
        }
      }
    }
    &__bundle-product {
      &__line-product {
        overflow: hidden;
        padding-bottom: 15px;
        .bundle-master-product {
          float: left;
          width: 300px;
          overflow: hidden;
          @media screen and (max-width: $britax-container-max-width-l) {
            width: 250px;
          }
          @media screen and (max-width: $britax-container-max-width-m) {
            width: 100%;
          }
          &-image {
            float: left;
            width: 75px;
            margin-right: 10px;
            .msc-empty_image {
              width: 75px;
              height: 75px;
              background-position: center;
              background-size: contain;
            }
          }
          &-content {
            float: left;
            p {
              width: 210px;
              font-size: var(--britax-body-font-size-xs);
              margin-bottom: 2px;
              @media screen and (max-width: $britax-container-max-width-l) {
                width: 160px;
              }
              @media screen and (max-width: $britax-container-max-width-m) {
                width: 100%;
              }
              &.p-name {
                font-weight: var(--britax-font-weight-bold);
                margin-bottom: 5px;
              }
            }
            .label {
              font-weight: var(--britax-font-weight-bold);
            }
            .v-name {
              font-weight: var(--britax-font-weight-bold);
              s {
                font-weight: var(--britax-font-weight-normal);
                margin-right: 5px;
              }
            }
          }
        }
        .bundle-variant-product {
          float: right;
          overflow-x: auto;
          white-space: nowrap;
          width: calc(100% - 320px);
          @media screen and (max-width: $britax-container-max-width-l) {
            width: calc(100% - 270px);
          }
          @media screen and (max-width: $britax-container-max-width-m) {
            width: 100%;
            margin-top: 15px;
          }
          &-image {
            display: inline-block;
            width: 75px;
            padding: 0 5px 10px;
            position: relative;
            border-bottom: solid 5px transparent;
            input[type="radio"] {
              opacity: 0;
              width: 100%;
              height: 75px;
              left: 0;
              top: 0;
              position: absolute;
            }
            &.selected {
              border-color: var(--britax-primary);
            }
            .msc-empty_image {
              width: 75px;
              height: 75px;
              background-position: center;
              background-size: contain;
            }
          }
          .control-dots,
          .carousel-status {
            display: none;
          }
          .carousel .control-next.control-arrow:before {
            border-left-color: var(--britax-grey);
          }
          .carousel .control-prev.control-arrow:before {
            border-right-color: var(--britax-grey);
          }
          .slider-wrapper {
            &.axis-horizontal {
              width: 85%;
              margin: auto;
              .slider {
                .slide {
                  min-width: 82px;
                  padding-bottom: 10px;
                  border-bottom: solid 5px transparent;
                  input[type="radio"] {
                    opacity: 0;
                    width: 65px;
                    height: 60px;
                    left: 0;
                    position: absolute;
                  }
                  &.selected {
                    border-color: var(--britax-primary);
                  }
                }
              }
            }
          }
          .slick-slider {
            overflow: hidden;
            padding: 0 20px;
            .slick-arrow {
              width: 32px;
              height: 32px;
              margin-top: -16px;
              &:before {
                @include msv-icon();
                color: var(--britax-primary);
                content: $msv-ChevronDown;
                opacity: 1;
              }
              &.slick-prev {
                left: 0;
                transform: rotate(90deg);
              }
              &.slick-next {
                right: 0;
                transform: rotate(-90deg);
              }
              &:hover {
                &::before {
                  color: var(--britax-grey);
                }
              }

              &.slick-disabled {
                &::before {
                  color: var(--britax-silver-grey);
                }
              }
            }
          }
        }
        @media screen and (max-width: $britax-container-max-width-m) {
          border-bottom: solid 1px var(--britax-silver-grey);
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
      }
      &__footer {
        overflow: hidden;
        clear: both;
        padding-top: 50px;
        margin-bottom: 10px;
        > div {
          float: left;
        }
        > button {
          float: right;
          @media screen and (max-width: $britax-container-max-width-m) {
            float: none;
            margin-top: 15px;
            width: 100%;
          }
        }
        .quantity-box {
          display: inline-block;
          border-radius: 4px;
          overflow: hidden;
          margin: 0 !important;
          background-color: var(--msv-bg-color);
          padding: 0 10px;
          height: 45px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          @media screen and (max-width: $britax-container-max-width-m) {
            float: right;
          }
          button {
            background-color: var(--britax-primary);
            border: 0;
            padding: 0;
            width: 18px;
            height: 18px;
            color: var(--msv-bg-color);
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            border-radius: 50%;
            span {
              position: relative;
              top: -1px;
            }
            &.disabled,
            &:disabled {
              background-color: var(--britax-silver-grey);
              pointer-events: none;
            }
            &:hover {
              background-color: var(--britax-grey);
            }
          }
          input {
            background-color: transparent;
            border: 0;
            font-size: var(--britax-body-font-size-xxs);
            width: 40px;
            text-align: center;
            color: var(--britax-primary);
            -moz-appearance: textfield;
            &:focus,
            &:focus-within {
              outline-color: var(--britax-primary);
            }
          }
        }
      }
      &__price {
        width: 33.33%;
        @media screen and (max-width: $britax-container-max-width-m) {
          width: calc(100% - 100px);
        }
        h3 {
          font-weight: var(--britax-font-weight-bold-full);
        }
      }
    }
    .load-text {
      display: inline-block;
      font-weight: var(--britax-font-weight-bold);
      color: var(--britax-primary);
      font-size: var(--britax-body-font-size-xs);
      cursor: pointer;
      &:hover {
        color: var(--britax-grey);
      }
    }
    .description-text {
      margin-bottom: 30px;
      line-height: var(--britax-line-height-m);
    }
    .isretired {
      background: var(--britax-pending-color);
      color: var(--britax-white);
      padding: 5px 10px 6px;
      border-radius: 4px;
      display: inline-block;
      &:before {
        display: inline-block;
        content: "!";
        margin-right: 7px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background: var(--britax-white);
        color: var(--britax-pending-color);
        font-weight: var(--britax-font-weight-bold-full);
      }
    }
    .loader {
      text-align: center;
      margin-top: 50px;
    }
  }
  &__inventory-label {
    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-body-font-size-l),
      $msv-line-height-m
    );
  }

  &__product-description {
    font-size: var(--britax-body-font-size-xs);
    text-align: left;
    color: var(--britax-grey);

    margin-top: $buybox-box-top-margin;
    margin-bottom: $buybox-box-bottom-margin;
  }
  &__product-tagline {
    font-size: var(--britax-body-font-size-xs);
    text-align: left;
    font-weight: var(britax-font-weight-bold-full);
    color: var(--britax-grey);
    margin-top: $buybox-box-top-margin;
    margin-bottom: $buybox-box-bottom-margin;
  }

  .msc-rating {
    &__count,
    &__star {
      color: var(--msv-buybox-rating-star);
    }

    &__half-star {
      &::after {
        color: var(--msv-buybox-rating-star);
      }
    }
  }

  .msc-price__actual {
    font-size: var(--britax-font-size-sml);
    color: var(--britax-grey-600);
    font-weight: var(--britax-font-weight-700);
    line-height: var(--britax-line-height-sml);
  }

  .msc-price__strikethrough {
    @include font-content-xl(var(--msv-font-weight-light));
  }

  &__configure {
    @include font-content-l(var(--msv-font-weight-normal));
  }

  &__dropdown {
    margin-top: 20px;

    &-quantity-label {
      @include font-content-l(var(--msv-font-weight-normal));
      color: var(--msv-buybox-title-font-color);
    }
  }

  &__media-gallery {
    display: block;
    flex-basis: $media-gallery-width;
    min-width: $media-gallery-width;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-right: $gutter-width;
    .slick-slider {
      .slick-prev,
      .slick-next {
        &:before {
          @include msv-icon();
          content: $msv-ChevronDown;
          color: var(--britax-primary);
          transform: rotate(-90deg);
          opacity: 1;
          :hover {
            color: var(--britax-grey);
          }
        }
      }
      .slick-prev {
        &:before {
          transform: rotate(90deg);
        }
      }
      .slick-dots {
        position: relative;
        > ul {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          > li {
            width: auto;
            height: auto;
            margin: 0;
            .thumbnail {
              img {
                width: 75px;
                height: 75px;
                overflow: hidden;
              }
              .msc-empty_image {
                width: auto;
                height: auto;
                background-size: cover;
              }
            }
            @media screen and (max-width: $britax-container-max-width-m) {
              width: 15px;
              height: 6px;
              margin: 0 3px;
              border-radius: 4px;
              background: var(--britax-silver-grey);
              transition: all 0.3s;
              .thumbnail {
                display: none;
              }
              &.slick-active {
                background-color: var(--britax-primary);
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: $britax-container-min-width-m) {
      .ms-media-gallery {
        &__thumbnails-container {
          display: none;
        }
      }
    }
    .msc-empty_image {
      width: 100%;
      height: 600px;
      background-size: cover;
    }
  }

  .msc-dropdown__select {
    @include form-control();
    border: 1px solid var(--msv-buybox-select-box-border);
  }

  .quantity {
    .quantity-input {
      @include form-control();
      width: 80px;
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      -moz-appearance: textfield;
      padding: $buybox-quantity-input;
      vertical-align: bottom;
      border: 1px solid var(--msv-buybox-quantity-box-border);

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    .quantity__controls {
      padding: $buybox-incr-decr;
      padding-left: 0;
      padding-right: 0;
      background: var(--msv-buybox-btn-bg);
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid var(--msv-buybox-btn-border-color);
      min-width: auto;
      width: 48px;
      height: 48px;
      cursor: pointer;

      &:disabled {
        color: $msv-gray-300;
        pointer-events: none;
      }
    }

    .increment {
      @include add-icon($msv-Add, after);
      color: var(--msv-buybox-btn-font-color);
      border: 1px solid var(--msv-buybox-increment-btn-border);

      &.disabled {
        color: $msv-gray-300;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        color: var(--msv-buybox-btn-font-color);
      }
    }

    .decrement {
      @include add-icon($msv-Remove, after);
      color: var(--msv-buybox-btn-font-color);
      border: 1px solid var(--msv-buybox-decrement-btn-border);

      &.disabled {
        color: $msv-gray-300;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        color: var(--msv-buybox-btn-font-color);
      }
    }
  }
  .product-variant-head-simple {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    @media screen and (max-width: $britax-container-max-width-s) {
      min-width: 540px;
    }
    .blk {
      width: 23.33%;
      h2 {
        color: var(--britax-primary);
        text-transform: uppercase;
        font-size: 0.85rem;
      }
      &:first-of-type {
        width: 30%;
      }
    }
  }
  .product-variant-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    @media screen and (max-width: $britax-container-max-width-s) {
      min-width: 540px;
    }
    .blk {
      width: 23.33%;
      h2 {
        color: var(--britax-primary);
        text-transform: uppercase;
        font-size: 0.85rem;
      }
      &:first-of-type {
        width: 30%;
      }
    }
  }
  &__quantity {
    width: 100%;
    overflow: hidden;
    border: 0;
    margin: 0 0 20px 0;
    @media screen and (max-width: $britax-container-max-width-s) {
      min-width: 540px;
    }
    .product-variant-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 25px 0;
      .blk {
        width: 23.33%;
        h2 {
          color: #c4c4c4;
          text-transform: uppercase;
          font-size: 0.8rem;
        }
        &:first-of-type {
          width: 30%;
        }
      }
    }
    .product-variant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .variant-blk {
        width: 23.33%;
        position: relative;
        &:first-of-type {
          width: 30%;
        }
        .status {
          font-size: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: inline-block;
          border: solid 2px var(--britax-white);
          position: absolute;
          top: 0;
          left: 0;
          &.AVAIL {
            background: var(--britax-success-color);
          }
          &.OOS {
            background: var(--britax-error-color);
          }
          &.LOW {
            background: var(--britax-pending-color);
          }
        }
        .msc-empty_image {
          max-width: 40px;
          height: 40px;
          background-size: 100%;
          margin-right: 15px;
          float: left;
        }
        img {
          float: left;
        }
      }
      .img-box {
        > a {
          float: left;
          cursor: pointer;
          padding-bottom: 5px;
          border-bottom: solid 3px transparent;
          transition: all 0.3s;
          &:hover {
            border-bottom-color: var(--britax-primary);
          }
        }
      }
    }
    img {
      max-width: 40px;
      height: auto;
      margin-right: 15px;
    }
    h5 {
      font-size: var(--britax-body-font-size-xs);
      font-weight: var(--britax-font-weight-bold);
      white-space: normal;
      margin: 0 0 5px;
      &.name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        max-height: 36px;
        margin-bottom: 0;
      }
    }
    .reference-number {
      font-size: var(--britax-body-font-size-xxs);
    }
    .quantity-box {
      display: inline-block;
      border-radius: 4px;
      overflow: hidden;
      margin: 0 !important;
      background-color: var(--msv-bg-color);
      padding: 0 10px;
      height: 40px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      button {
        background-color: var(--britax-primary);
        border: 0;
        padding: 0;
        width: 18px;
        height: 18px;
        color: var(--msv-bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 50%;
        span {
          position: relative;
          top: -1px;
        }
        &.disabled,
        &:disabled {
          background-color: var(--britax-silver-grey);
          pointer-events: none;
        }
        &:hover,
        &:focus {
          background-color: var(--britax-grey);
        }
      }
      input {
        background-color: transparent;
        border: 0;
        font-size: var(--britax-body-font-size-xxs);
        width: 35px;
        text-align: center;
        color: var(--britax-grey);
        -moz-appearance: textfield;
        &:focus,
        &:focus-within {
          outline-color: var(--britax-silver-grey);
        }
      }
    }
  }

  &__product-quantity-label {
    @include font-content-l(var(--msv-font-weight-normal));
    color: var(--msv-buybox-title-font-color);
    display: block;
    margin-bottom: 0;
  }

  .ms-buybox__key_in_price {
    margin-top: 20px;

    .ms-buybox__key_in_price-label {
      @include font-content-l(var(--msv-font-weight-light));
      color: var(--msv-buybox-title-font-color);
      display: block;
      margin-bottom: $buybox-label-margin-bottom;
    }

    .ms-buybox__key_in_price_custom-amount__input {
      @include form-control();
      -moz-appearance: textfield;
      padding: $buybox-custom-amount-input-padding;
      text-align: center;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .ms-text-block {
    margin-top: 0;
    margin-bottom: 1rem;

    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-buybox-text-font-size),
      $msv-line-height-m
    );
  }

  .product-add-to-cart {
    margin-top: 20px;

    .buttons {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .msc-add-to-cart {
      @include primary-button(
        var(--msv-buybox-primary-btn-bg),
        var(--msv-buybox-primary-btn-font-color),
        var(--msv-buybox-primary-btn-border)
      );
      font-size: var(--msv-buybox-btn-font-size);
      line-height: $msv-line-height-l;
      flex-grow: 1;
    }

    .ms-wishlist-items__product-status {
      width: 100%;
      padding-top: $buybox-product-status-padding-top;
    }

    .msc-add-to-cart-extra-actions {
      @include font-glyph-standard();
      @include secondary-button(
        var(--msv-buybox-secondary-btn-bg),
        var(--msv-buybox-secondary-btn-font-color),
        var(--msv-buybox-secondary-btn-border)
      );
      color: var(--msv-buybox-btn-extra-actions-color);
      padding-left: 0;
      padding-right: 0;
      position: relative;
      min-width: $buybox-add-to-cart-extra-actions-width;
      width: $buybox-add-to-cart-extra-actions-width;
      flex: 0 0 $buybox-add-to-cart-extra-actions-width;
      margin-left: $buybox-add-to-cart-extra-actions-margin;

      &:not(:disabled):hover,
      &:not(:disabled):not(.disabled):active:focus {
        color: var(--msv-buybox-btn-extra-actions-color);
        border-color: transparent;
      }
    }

    .msc-add-to-wishlist {
      color: var(--msv-buybox-btn-wishlist-color);
    }
  }

  &__find-in-store {
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
  }

  &__find-in-store-heading {
    @include font-content-l(var(--msv-font-weight-normal));
  }

  &__find-in-store-description {
    margin-top: 8px;
    margin-bottom: $buybox-store-description-margin-bottom;

    @include font-content-s(var(--msv-font-weight-normal));
  }

  &__find-in-store-pickup-option {
    margin-top: 8px;
    margin-bottom: $buybox-store-description-margin-bottom;

    @include font-content-s();
    @include add-icon($msv-Checkbox-Circle-Checked, before);

    &::before {
      margin-right: 10px;
      color: --msv-buybox-primary-btn-font-color;
    }
  }

  &__minmax_label_text {
    margin-top: 12px;
    font-weight: var(--msv-font-weight-bold);

    &::before {
      @include msv-icon();
      content: $msv-InfoCircle;
      margin-right: $msv-icon-margin-right;
    }
  }

  &__find-in-store-button {
    @include button-default();
    @include secondary-button(
      var(--msv-buybox-secondary-btn-bg),
      var(--msv-buybox-secondary-btn-font-color),
      var(--msv-buybox-secondary-btn-border)
    );
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
  }

  &__shop-similar-looks-button {
    @include button-default();
    @include secondary-button(
      var(--msv-buybox-secondary-btn-bg),
      var(--msv-buybox-secondary-btn-font-color),
      var(--msv-buybox-secondary-btn-border)
    );
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
    margin-bottom: 20px;
  }

  &__shop-similar-description-button {
    @include button-default();
    @include secondary-button(
      var(--msv-buybox-secondary-btn-bg),
      var(--msv-buybox-secondary-btn-font-color),
      var(--msv-buybox-secondary-btn-border)
    );
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
  }

  &__dialog {
    max-width: $msv-dialog-width;

    .msc-modal__content {
      padding: $msv-dialog-modal-content-padding;
    }

    &__header {
      .msc-modal__title {
        @include font-content-l();

        span {
          &:nth-child(1) {
            font-weight: var(--msv-font-weight-bold);
          }
        }
      }
    }

    &__body {
      @include font-content-l();
      text-align: center;

      .msc-empty_image {
        @include image-placeholder($msv-dialog-msc-empty_image-size);
        padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
      }
    }

    &__image {
      margin-right: auto;
      margin-left: auto;
    }

    &__product-title {
      padding: $msv-dialog-modal-content-body-text-padding;
    }

    &__product-variants {
      @include font-content(
        var(--msv-font-weight-normal),
        var(--msv-buybox-text-font-size),
        $msv-line-height-m
      );
      padding: $msv-dialog-modal-content-body-text-padding;
    }

    &__product-price {
      font-weight: var(--msv-font-weight-bold);
      padding: $msv-dialog-modal-content-body-text-padding;
    }

    &__go-to-cart {
      @include primary-button(
        var(--msv-buybox-primary-btn-bg),
        var(--msv-buybox-primary-btn-font-color),
        var(--msv-buybox-primary-btn-border)
      );
      margin: $msv-dialog-modal-content-button-margin;
      width: $msv-dialog-button-width;
    }

    &__back-to-shopping {
      @include secondary-button();
      margin: $msv-dialog-modal-content-button-margin;
      width: $msv-dialog-button-width;
    }
  }

  @media (max-width: $msv-breakpoint-l) {
    &__media-gallery {
      display: block;
      flex-basis: $media-gallery-width;
      min-width: $media-gallery-width;
      flex-grow: 1;
      flex-wrap: nowrap;
      padding-right: $gutter-width;
    }

    .ms-media-gallery {
      padding-bottom: 54px;

      .msc-carousel {
        &__indicators {
          bottom: -36px;
        }

        &__control {
          &__prev,
          &__next {
            bottom: -40px;
            height: auto;
            top: auto;
            width: auto;
          }

          &__prev__icon {
            @include add-icon($msv-ChevronLeft);
            margin-left: 0;
          }

          &__next__icon {
            @include add-icon($msv-ChevronRight);
            margin-right: 0;
          }
        }
      }
    }
  }

  //mobile vp

  &__content {
    .msc-cart__btn-addcarttotemplate {
      background-color: transparent;
      border: 0;
      padding: 0;
      margin-top: 15px;
      color: var(--britax-primary);
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        text-decoration: underline;
        outline: none;
        border: 0;
        color: var(--britax-grey);
        box-shadow: 0 0;
      }
      &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
          text-decoration: underline;
          outline: none;
          border: 0;
          color: var(--britax-grey);
          box-shadow: 0 0;
        }
      }
    }
    .pdp-quantity {
      overflow: hidden;
      @media screen and (max-width: $britax-container-max-width-s) {
        max-width: 100%;
        overflow-x: auto;
      }
    }
    .per-unit-box {
      padding-top: 40px;
      overflow: hidden;
      .ms-buybox__add-to-cart-container {
        float: right;
      }
    }
    .viewmore {
      background-color: var(--britax-grey-2);
      overflow: hidden;
      padding: 15px;
      button {
        background: transparent;
        border: 0;
        padding: 0;
        color: var(--britax-primary);
        font-weight: var(--britax-font-weight-bold);
        &:hover,
        &:focus {
          text-decoration: underline;
          color: var(--britax-grey);
        }
      }
      &__price {
        color: var(--britax-primary);
        font-size: var(--britax-font-size-xm);
        font-weight: var(--britax-font-weight-bold);
        float: right;
        width: 22%;
      }
    }
  }
}

.user-manual-container {
  background: var(--britax-bg-grey);
  padding: 50px 0;
  margin-top: 30px;
  border-top: solid 1px var(--britax-grey);
  border-bottom: solid 1px var(--britax-grey);
  .ms-content-block {
    display: flex;
    flex-direction: row-reverse;
    &__image {
      flex: 0 0 60%;
    }
    &__details {
      flex: 0 0 40%;
      padding-right: 40px;
      .ms-content-block__cta {
        > a {
          color: var(--britax-primary);
          font-size: var(--britax-font-size-sm);
          display: block;
          padding: 15px;
          padding-left: 0;
          margin-bottom: 10px;
          overflow: hidden;
          border-bottom: solid 1px var(--britax-silver-grey);
          &:before {
            @include britax-icon();
            content: $britax-icon-pdf;
            color: var(--britax-primary);
            font-size: var(--britax-font-size-xsml);
            float: left;
            margin-right: 15px;
          }
          &:hover {
            color: var(--britax-grey);
            text-decoration: none;
          }
        }
      }
    }
  }
  .container-heading {
    text-align: center;
    flex: auto;
    margin-bottom: 30px;
    font-weight: var(--britax-font-weight-bold-full);
  }
  img {
    height: auto;
  }
  a {
    color: var(--britax-primary);
    font-size: var(--britax-font-size-sm);
    display: block;
    padding: 15px;
    padding-left: 0;
    margin-bottom: 10px;
    margin-right: 40px;
    overflow: hidden;
    border-bottom: solid 1px var(--britax-silver-grey);
    &:before {
      @include britax-icon();
      content: $britax-icon-pdf;
      color: var(--britax-primary);
      font-size: var(--britax-font-size-xsml);
      float: left;
      margin-right: 15px;
    }
    &:hover {
      color: var(--britax-grey);
      text-decoration: none;
    }
    &.noguide {
      color: var(--britax-cold-grey);
      pointer-events: none;
      &:before {
        color: var(--britax-cold-grey);
      }
    }
  }
}
.buybox-container {
  padding: 50px 0;
  background: var(--britax-bg-grey);
  border-bottom: solid 1px var(--britax-grey);
  min-height: calc(100vh - 180px);
}
.pdp-related-products {
  .msc-ss-carousel-slide {
    width: 100%;
  }
  .ms-product-collection {
    &__heading {
      font-size: var(--britax-font-size-sml-32);
      color: var(--britax-primary);
      line-height: initial;
      padding-right: 0;
      margin-bottom: 15px;
      text-align: center;
    }
    &__item {
      width: calc(25% - 18px);
      background: var(--britax-bg-grey);
      margin: 0 10px;
      padding: 10px 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.ms-product-collection {
    margin-bottom: 30px;
    .msc-product__details {
      padding: 0;
    }
  }
}
.accessories-collection {
  background: var(--britax-bg-grey);
  border-top: solid 1px var(--britax-grey);
  .ms-product-collection {
    .msc-ss-carousel {
      margin-bottom: 50px;
      .msc-ss-carousel-slide {
        width: 100%;
      }
    }
    &__heading {
      font-size: var(--britax-font-size-sml-32);
      color: var(--britax-primary);
      line-height: initial;
      padding-right: 0;
      margin-top: 0;
      margin: 50px 0 15px;
      text-align: center;
    }
    &__item {
      width: calc(25% - 20px);
      background: var(--britax-white);
      margin: 0 10px;
      padding: 10px 15px;
      > .msc-product {
        background-color: transparent;
      }
    }
  }
  .ms-product-collection {
    margin-bottom: 0;
  }
}

.atp-explaination {
  padding-bottom: 10px;
  padding-top: 20px;
}
//custom CSS Start

.product-per_unit {
  font-size: var(--britax-body-font-size-xxs);
  line-height: var(--britax-line-height-xxxs);
}
.qty-of-each-product {
  font-size: var(--britax-font-size-sm);
  font-weight: var(--britax-font-weight-light);
  line-height: var(--britax-line-height-l);
}
.product-precautions {
  font-size: var(--britax-font-size-sm);
  font-weight: var(--britax-font-weight-normal);
  border-bottom: solid 1px var(--britax-silver-grey);
  padding-bottom: 10px;
  margin-bottom: 15px;
  .size__icon {
    margin-right: 10px;
    .infant-icon,
    .baby-icon,
    .child-icon {
      &:before {
        @include britax-icon();
        content: $britax-icon-infant;
        color: var(--britax-silver-grey);
        font-size: var(--britax-body-font-size-m);
        margin-right: 5px;
        display: inline-block;
      }
    }
    .baby-icon {
      &:before {
        content: $britax-icon-child;
        font-size: var(--britax-font-size-xsml);
      }
    }
    .child-icon {
      &:before {
        content: $britax-icon-child;
        font-size: var(--britax-font-size-xxml);
      }
    }
    .infant-icon.selected,
    .baby-icon.selected,
    .child-icon.selected {
      &:before {
        color: var(--britax-grey);
      }
    }
  }
  &.direction {
    .icon-forward {
      width: 27px;
      height: 30px;
      margin: 0 0 -9px;
      display: inline-block;
      background: url("#{$britax-image-path}forward-facing.svg") no-repeat top
        left;
    }
    .icon-rearward {
      width: 27px;
      height: 30px;
      margin: 0 0 -9px;
      display: inline-block;
      background: url("#{$britax-image-path}rearward-facing.svg") no-repeat top
        left;
    }
  }
}
.pdp-video-gallery-slider {
  .row {
    justify-content: center;
    .col-12 {
      flex: 0 0 80%;
      margin-top: 50px;
      .carousel-root {
        .carousel-slider {
          .control-dots,
          .carousel-status,
          button {
            display: none;
          }
        }
        .carousel {
          .control-prev {
            &::before {
              font-family: "Font Awesome 5 Free";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              font-weight: 900;
              text-rendering: auto;
              line-height: 1;
              content: "";
              border: none;
              margin: 0;
            }
          }
          .control-next {
            &::before {
              font-family: "Font Awesome 5 Free";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              font-weight: 900;
              text-rendering: auto;
              line-height: 1;
              content: "";
              border: none;
              margin: 0;
            }
          }
          .thumbs {
            .thumb {
              padding-bottom: 10px;
              &:hover,
              &:focus {
                border: none;
                border-bottom: 3px solid var(--britax-primary);
              }
            }
            .selected {
              padding-bottom: 10px;
              border: none;
              border-bottom: 3px solid var(--britax-primary);
            }
          }
        }
      }
      .carousel {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: $msv-breakpoint-m) {
  .pdp-related-products,
  .accessories-collection {
    .ms-product-collection__item {
      width: calc(33.33% - 10px);
    }
  }

  .b2bpdp .ms-buybox {
    flex-wrap: wrap;
    &__media-gallery {
      padding-right: 0;
      margin-bottom: 50px;
      .ms-media-gallery {
        padding: 0;
        max-height: initial;
        &__carousel {
          position: relative;
          .msc-carousel__item {
            max-height: initial !important;
          }
        }
      }
    }

    &__content {
      width: 100%;
    }
  }
  //flex-flow: column;
}
@media (max-width: $britax-container-max-width-s) {
  .pdp-related-products,
  .accessories-collection {
    .ms-product-collection__item {
      width: calc(100% - 10px);
    }
  }
  .user-manual-container .ms-content-block {
    flex-wrap: wrap;
    &__image,
    &__details {
      flex: 100%;
    }
  }
}
.video-description {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  h2 {
    margin-bottom: 10px;
  }
}

.cartDiscountExplaination {
  margin-top: 10px;
}
//custom CSS end
