$msv-checkout-description-margin-left: 25px;
$msv-checkout-option-margin-bottom: 38px;
$msv-checkout-delivery-options-price-margin-left: 12px;
$msv-checkout-delivery-options-description-margin-left: 0;

//style presets
:root {
	--msv-checkout-delivery-options-font-size: var(--msv-body-font-size-m);
	--msv-checkout-delivery-options-font-color: #{msv-gray-900};
}

.ms-checkout-delivery-options {
	&__price {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-normal);
		line-height: var(--britax-line-height-xs);
		color: var(--britax-primary);
		margin-left: auto;
	}

	&__description {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-xs);
		color: var(--britax-primary);
		margin-left: $msv-checkout-description-margin-left;
	}
	&__option {
		margin-bottom: $msv-checkout-option-margin-bottom;
		display: flex;
		align-items: center;

		&-selected {
			display: flex;
			// flex-direction: column;
			span {
				margin-bottom: 10px;
			}
			.ms-checkout-delivery-options__price {
				margin-left: 0;
			}

			.ms-checkout-delivery-options__description {
				margin-left: 0;
			}
		}
	}

	&__input-radio {
		&:checked::before {
			background: grey;
		}
	}

	@media screen and (min-width: $msv-breakpoint-l) {
		&__description {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}

		&__price {
			flex: 0 0 16.66667%;
			max-width: 16.66667%;
			margin-left: 0;
		}
	}
}
