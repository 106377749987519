$msv-notifications-list-z-index: 1010;
$msv-notifications-list-right: 30px;
$msv-notifications-list-bottom: 0;
$msv-notifications-list-scrollbar-width: 8px;
$msv-notifications-list-scrollbar-background: none;
$msv-notifications-list-scrollbar-thumb-background: #D5D5D5;
$msv-notifications-list-scrollbar-thumb-width: 8px;
$msv-notifications-list-scrollbar-thumb-border-radius: 8px;

:root {
    --msv-notifications-list-z-index: #{$msv-notifications-list-z-index};
    --msv-notifications-list-right: #{$msv-notifications-list-right};
    --msv-notifications-list-bottom: #{$msv-notifications-list-bottom};
    --msv-notifications-list-scrollbar-width: #{$msv-notifications-list-scrollbar-width};
    --msv-notifications-list-scrollbar-background: #{$msv-notifications-list-scrollbar-background};
    --msv-notifications-list-scrollbar-thumb-background: #{$msv-notifications-list-scrollbar-thumb-background};
    --msv-notifications-list-scrollbar-thumb-width: #{$msv-notifications-list-scrollbar-thumb-width};
    --msv-notifications-list-scrollbar-thumb-border-radius: #{$msv-notifications-list-scrollbar-thumb-border-radius};
}

.msc-global-notifications-list-container {
    /* stylelint-disable declaration-no-important -- Width should be fixed and be the same for all the notifications. */
    min-width: 340px !important;
    max-width: 340px !important;
    width: 340px !important;
    /* stylelint-enable declaration-no-important */

    // Enable click through.
    pointer-events: none;

    position: fixed;
    right: var(--msv-notifications-list-right);
    z-index: var(--msv-notifications-list-z-index);
    bottom: var(--msv-notifications-list-bottom);

    @media (max-width: $msv-breakpoint-m) {
        right: unset;
        left: 50%;
        transform: translate(-50%, 0);
    }

    * {
        pointer-events: auto;
    }

    ::-webkit-scrollbar {
        background: var(--msv-notifications-list-scrollbar-background);
        width: var(--msv-notifications-list-scrollbar-width);
        pointer-events: auto;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--msv-notifications-list-scrollbar-thumb-background);
        width: var(--msv-notifications-list-scrollbar-thumb-width);
        border-radius: var(--msv-notifications-list-scrollbar-thumb-border-radius);
        pointer-events: auto;
    }

    .msc-notifications-list__scrollable {
        overflow: auto;
        padding: 20px 10px;
    }
}
