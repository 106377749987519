@mixin order-template-image-container(
    $msv-order-template-image-size: 140px,
    $msv-order-template-image-margin: 3px,
    $msv-order-template-image-container-size: $msv-order-template-image-size * 2 + $msv-order-template-image-margin * 6
) {
    width: $msv-order-template-image-container-size;
    height: $msv-order-template-image-container-size;
    padding: $msv-order-template-image-margin;
    margin-left: 12px;
    margin-right: 12px;
    background: #F9F9F9;
    display: flex;
    flex-wrap: wrap;
}

@mixin order-template-image(
    $msv-order-template-image-size: 140px,
    $msv-order-template-image-margin: 3px
) {
    margin: $msv-order-template-image-margin;
    width: $msv-order-template-image-size;
    height: $msv-order-template-image-size;
    max-width: $msv-order-template-image-size;
    max-height: $msv-order-template-image-size;
}

@mixin order-template-empty-image(
    $msv-order-template-image-size: 140px,
) {
    @include image-placeholder($msv-order-template-image-size);
}
