$msv-flyout-msc-cart-line-product-image-size: 120px;
$msv-flyout-msc-cart-line-product-empty-image-size: 80px;
$msv-flyout-padding: 15px;
$msv-flyout-cart-icon-subtotal-padding: 15px;
$msv-flyout-cart-icon-subtotal-margin: 0 -15px;
$msv-flyout-cart-icon-btn-checkout-margin-right: 10px;
$mini-cart-width: 400px;
$subtotal-border: 1px solid $msv-gray-300;
$msv-flyout-empty-img-border: 1px solid $msv-flyout-empty-img-border;

//style presets
:root {
	--msv-flyout-heading-font-color: var(--msv-font-primary-color);
	--msv-flyout-heading-font-size: var(--msv-body-font-size-l);
	--msv-flyout-text-font-color: var(--msv-font-primary-color);
	--msv-flyout-text-font-size: var(--msv-body-font-size-m);

	// primary button
	--msv-flyout-primary-btn-bg: var(--msv-accent-brand-color);
	--msv-flyout-primary-btn-font-color: var(--msv-font-secondary-color);
	--msv-flyout-primary-btn-border: var(--msv-accent-brand-color);

	// secondary button
	--msv-flyout-secondary-btn-bg: var(--msv-secondary-button-background-color);
	--msv-flyout-secondary-btn-font-color: var(--msv-font-primary-color);
	--msv-flyout-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-cart-icon__flyout-container {
	background: var(--britax-white);
	box-shadow: 0px 0px 3px var(--britax-cold-grey);
	display: block;
	margin: 0;
	opacity: 0;
	position: absolute;
	width: $mini-cart-width;
	z-index: 1060;
	border-radius: 3px;

	&.show {
		opacity: 1;
		padding: $msv-flyout-padding;
		@media screen and (max-width: $britax-container-max-width-ipad) {
			opacity: 0;
		}
	}

	.ms-cart-icon__btn-checkout,
	.ms-cart-icon__btn-gotocart {
		width: 100%;
	}

	.msc-flyout-inner {
		.ms-cart-icon__subtotal {
			@include font-content(
				var(--britax-font-weight-normal),
				var(--britax-font-size-sm),
				$msv-line-height-l
			);
			color: var(--britax-primary);
			border-top: $subtotal-border;
			margin: $msv-flyout-cart-icon-subtotal-margin;
			padding: $msv-flyout-cart-icon-subtotal-padding;
			text-align: right;
			&:hover {
				color: var(--britax-grey);
			}
		}

		.ms-cart-icon__btn-gotocart {
			background-color: var(--britax-white);
			color: var(--britax-primary);
			text-decoration: none;
			padding: 10px 20px 0;
			height: auto;
			line-height: initial;
			text-decoration: none;
			font-weight: var(--britax-font-weight-above-normal);
			font-size: var(--britax-body-font-size-xs);
			&:hover {
				color: var(--britax-grey);
			}
		}

		.ms-cart-icon__btn-checkout {
			@include primary-button(
				var(--britax-primary),
				var(--britax-white),
				var(--britax-primary)
			);
			height: auto;
		}
	}

	.msc-cart-line__product-image {
		width: $msv-flyout-msc-cart-line-product-image-size;
	}

	.msc-empty_image {
		@include image-placeholder(
			$msv-flyout-msc-cart-line-product-empty-image-size
		);
		border: $msv-flyout-empty-img-border;
	}

	.msc-cart-line__product-savings-label,
	.msc-cart-line__product-savings-text,
	.msc-cart-line-item-product-discount {
		display: none;
	}

	.msc-cart-line__remove-item {
		margin-left: 104px;
	}

	.msc-cart-line__bopis-method {
		margin-left: 108px;
	}

	.msc-cart-line__remove-item,
	.msc-cart-line__bopis-method {
		height: auto;
		padding: 6px 0;
		margin-top: 5px;
		position: absolute;
		bottom: 16px;
		right: 0;
		margin: 0;
	}

	.msc-cart-line__content {
		display: block;
		.msc-cart-line__product-title {
			display: none;
			max-width: 180px;
			&:first-child {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:hover {
				&:first-child {
					color: var(--britax-grey);
				}
			}
		}
		.msc-price__actual {
			font-size: var(--britax-body-font-size-m);
		}
		.product-subtotal {
			position: absolute;
			top: 50px;
			right: 0;
			color: var(--britax-primary);

			.mobile-label {
				display: none;
			}
			&:hover,
			&:hover > span,
			&:hover > s,
			&:hover > h6 {
				color: var(--britax-grey);
			}
		}
		.product_price {
			margin-top: 6px;
			color: var(--britax-primary);
			&:hover,
			&:hover > span,
			&:hover > s,
			&:hover > h6 {
				color: var(--britax-grey);
			}
		}
	}

	.msc-cart-line__product-savings {
		text-align: left;
		margin: 0;
	}

	.msc-cart-line__product-savings,
	.msc-cart-line__quantity,
	.msc-cart-line__product-variants {
		margin-top: 6px;
		padding-top: 0;
	}

	.msc-cart-line__product-variants {
		& > .msc-cart-line__product-variant-item {
			display: inline;

			&::after {
				content: ",";
			}

			&:last-child::after {
				content: "";
			}
		}
	}

	.msc-cart-line__product-variant-size,
	.msc-cart-line__product-variant-style,
	.msc-cart-line__product-configuration,
	.msc-cart-line__product-variant-color {
		font-size: 0;

		.name {
			@include font-content(
				var(--msv-font-weight-normal),
				var(--msv-flyout-text-font-size),
				$msv-line-height-m
			);
			color: var(--msv-flyout-text-font-color);
		}
	}

	.ms-cart-icon__flyout-title,
	.msc-cart-line__product-title {
		@include font-content(
			var(--msv-font-weight-bold),
			var(--msv-flyout-heading-font-size),
			$msv-line-height-l
		);
		color: var(--msv-flyout-heading-font-color);
	}

	.ms-cart-icon__flyout-title {
		margin-bottom: 10px;
		display: block;
		font-size: var(--britax-font-size-sm);
		color: var(--britax-primary);
		font-weight: var(--britax-font-weight-above-normal);
		&:hover {
			color: var(--britax-grey);
		}
	}

	.msc-cart-line__product-title {
		font-weight: var(--britax-font-weight-bold);
		font-size: var(--britax-body-font-size-xs);
	}

	.ms-cart-icon__cart-lines {
		max-height: 400px;
		overflow: auto;

		.msc-cart-lines-item {
			min-height: 140px;
			position: relative;
			overflow: hidden;
			&:last-child {
				padding-bottom: 0;
			}
			&__price {
				margin-left: 107px;
				display: none;
				font-size: var(--britax-font-size-sm);
			}
			&__sku {
				color: var(--britax-primary);
				&:hover {
					color: var(--britax-grey);
				}
			}
			.isretired {
				background: var(--britax-pending-color);
				color: var(--britax-white);
				border-radius: 4px;
				display: inline-block;
				padding: 5px 15px;
				position: absolute;
				top: 18px;
				right: -33px;
				width: 150px;
				text-align: center;
				transform: rotate(35deg);
				&:before {
					display: inline-block;
					content: "!";
					margin-right: 7px;
					width: 20px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					border-radius: 50%;
					background: var(--britax-white);
					color: var(--britax-pending-color);
					font-weight: var(--britax-font-weight-bold-full);
				}
			}
		}
		.msc-cart-line__product-price {
			display: block;
			margin: 0;
			position: absolute;
			top: 48px;
			right: 0;
			width: 86px;
		}

		&::-webkit-scrollbar,
		&::-webkit-scrollbar-button {
			width: 8px;
		}

		&::-webkit-scrollbar-button {
			height: 5px;
		}

		&::-webkit-scrollbar-track {
			background: #eeeeee;
			border: thin solid #dfdfdf;
			box-shadow: 0 0 3px #dfdfdf inset;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: #999999;
			border: thin solid #323130;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #7d7d7d;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
	.msc-cart-line__product-variant-item,
	.msc-cart-line__quantity,
	.msc-cart-line__bopis-method {
		font-size: var(--britax-body-font-size-xs);
		color: var(--britax-grey);
	}
	.msc-cart-line__quantity {
		color: var(--britax-primary);
		.quantity-label {
			&::after {
				content: " : ";
			}
		}
		.quantity-value {
			&::before {
				content: " ";
			}
		}
		&:hover,
		&:hover > .quantity-label,
		&:hover > .quantity-value {
			color: var(--britax-grey);
		}
	}
	.msc-empty_image {
		background-size: contain;
	}
}
