$britax-image-path: "../../../";
.full-width-banner {
	margin: 40px 0;
	@media (max-width: $britax-container-max-width-l) {
		margin: 20px 0;
	}
	@media (max-width: $britax-container-max-width-m) {
		margin: 13px 0 15px;
	}
	@media (max-width: $britax-container-max-width-s) {
		margin: 10px 0 15px;
	}
	.ms-content-block {
		&__image {
			img {
				width: 100%;
			}
		}
		&__details {
			padding: 20px 60px 50px 60px;
			text-align: center;
			@media (max-width: $britax-breakpoint-mbl-sm) {
				padding: 20px 10px 10px 10px;
			}
		}
		&__title {
			font-size: var(--britax-body-font-size-xxl);
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			line-height: 50px;
			margin-bottom: 15px;
			font-family: $britax-primary-font-family;
			@media (max-width: $britax-container-max-width-s) {
				font-size: var(--britax-body-font-size-xl);
			}
		}
		&__text {
			font-size: var(--britax-body-font-size-m);
			line-height: 22px;
			font-family: $britax-primary-font-family;
		}
		&__cta {
			.msc-cta__primary {
				@include primary-button();
				div {
					width: 100%;
					text-align: center;
				}
			}
		}
	}
}
.half-banner-with-title {
	min-height: auto;
	padding: 0;
	margin: 40px 0;
	overflow: hidden;
	@media (max-width: $britax-container-max-width-l) {
		margin: 20px 0;
	}
	@media (max-width: $britax-container-max-width-m) {
		margin: 13px 0 15px;
	}
	@media (max-width: $britax-container-max-width-s) {
		margin: 10px 0 15px;
	}
	.ms-content-block {
		&__image {
			overflow: hidden;
			img {
				width: 100%;
			}
		}
		&__details {
			position: relative;
			height: auto;
			padding: 0;
		}
		&__title {
			width: 100%;
			position: relative;
			padding: 12px;
			text-align: center;
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			line-height: 50px;
			font-family: $britax-primary-font-family;
			@media (max-width: $britax-container-max-width-s) {
				margin: 14px 0 10px;
				padding: 0;
				text-align: center;
				max-width: 100%;
				font-size: var(--britax-body-font-size-xl);
				line-height: initial;
			}
		}
		&__text {
			text-align: center;
			line-height: $britax-line-height-m;
			font-family: $britax-primary-font-family;
		}
	}
}
.ms-content-block {
	&.filter-navigation {
		&[data-m-layout="full-width"] {
			min-height: auto;
			margin-bottom: 30px;
			.ms-content-block {
				&__details {
					display: block;
					padding: 0;
					position: relative;
					width: 100%;
					height: auto;
					text-align: center;
				}
				&__title {
					font-size: var(--britax-body-font-size-ml);
					font-weight: var(--britax-font-weight-bold);
					color: var(--britax-grey);
					max-width: 100%;
					font-family: $britax-primary-font-family;
				}
				&__cta {
					margin-top: 0;
					display: block;
					a,
					.msc-cta__primary,
					.msc-cta__secondary {
						min-height: 29px;
						max-height: 29px;
						line-height: initial;
						min-width: 215px;
						border: 1px solid var(--britax-grey);
						font-size: var(--britax-body-font-size-s);
						font-weight: var(--britax-font-weight-normal);
						border-radius: 4px;
						background-color: transparent;
						color: var(--britax-grey);
						justify-content: center;
						align-items: center;
						display: inline-flex;
						cursor: pointer;
						padding: 0;
						position: relative;
						-webkit-transform: perspective(1px) translateZ(0);
						transform: perspective(1px) translateZ(0);
						position: relative;
						-webkit-transition-duration: 0.3s;
						transition-duration: 0.3s;
						overflow: hidden;
						@media (max-width: $britax-container-min-width-m) {
							min-width: 173px;
						}
						@media (max-width: $britax-container-max-width-s) and (min-width: $britax-breakpoint-mbl) {
							min-width: 120px;
						}
						@media (max-width: $britax-breakpoint-mbl) {
							min-width: 100%;
							margin-left: 0;
							margin-right: 0;
						}
						&:after {
							content: "";
							position: absolute;
							z-index: -1;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background: var(--britax-silver-grey);
							-webkit-transform: scaleX(0);
							transform: scaleX(0);
							-webkit-transform-origin: 0 50%;
							transform-origin: 0 50%;
							-webkit-transition-property: transform;
							transition-property: transform;
							-webkit-transition-duration: 0.3s;
							transition-duration: 0.3s;
							-webkit-transition-timing-function: ease-out;
							transition-timing-function: ease-out;
						}
						&:hover,
						&:focus,
						&:active {
							color: var(--britax-grey);
							background: var(--britax-white);
							text-decoration: none;
							&:after {
								-webkit-transform: scaleX(1);
								transform: scaleX(1);
							}
						}
					}
				}
			}
		}
	}
}
.two-column-layout {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
	margin-top: 30px;
	@media (max-width: $britax-container-max-width-s) {
		display: block;
	}
	.ms-content-block {
		&__image {
			width: 40%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media (max-width: $britax-container-max-width-s) {
				width: 100%;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		&__details {
			width: 60%;
			padding-left: 50px;
			display: flex;
			flex-direction: column;
			align-items: normal;
			justify-content: center;
			@media (max-width: $britax-container-max-width-s) {
				width: 100%;
				margin-top: 15px;
				padding-left: 0;
			}
			h4 {
				color: var(--britax-primary);
				font-size: var(--britax-body-font-size-m);
			}
		}
		&__title {
			color: var(--britax-primary);
			font-weight: var(--britax-font-weight-bold);
			font-size: var(--britax-body-font-size-xl);
			font-family: $britax-primary-font-family;
			padding-bottom: 15px;
			line-height: $britax-line-height-xl;
			max-width: 560px;
			@media (max-width: $britax-container-max-width-s) {
				font-size: var(--britax-body-font-size-ml);
				line-height: $britax-line-height-ml;
				max-width: 100%;
			}
		}
		&__text {
			font-size: var(--britax-font-size-xsml);
			font-family: $britax-primary-font-family;
			line-height: var(--britax-line-height-sml);
			max-width: 580px;
			h4 {
				color: var(--britax-primary);
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-ml);
				line-height: 24px;
			}
			p {
				line-height: var(--britax-line-height-sml);
				margin-bottom: 10px;
				font-family: $britax-primary-font-family;
				color: var(--britax-primary);
			}
		}
		&__cta {
			> a {
				@include button-default();
				@include primary-button(
					var(--britax-primary),
					var(--britax-white),
					var(--britax-primary)
				);
				font-size: var(--britax-body-font-size-m);
				border-radius: 25px;
				text-decoration: none;
				line-height: initial;
				cursor: pointer;
				margin-top: 15px;
				transition: all 0.3s;
				div {
					margin: auto;
				}
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-white);
					background: var(--britax-grey);
					text-decoration: none;
				}
			}
		}
	}
	&.imageplacement__right {
		.ms-content-block {
			&__image {
				order: 2;
			}
			&__details {
				order: 1;
				padding-left: 0;
				padding-right: 50px;
				@media (max-width: $britax-container-max-width-s) {
					padding-right: 0;
				}
			}
		}
	}
	&.portrait {
		.ms-content-block {
			&__image,
			&__details {
				width: calc(50% - 10px);
				@media (max-width: $britax-container-max-width-s) {
					width: 100%;
				}
			}
		}
	}
	&.small-image {
		.ms-content-block {
			&__image {
				width: calc(33.33% - 10px);
				@media (max-width: $britax-container-max-width-s) {
					width: 100%;
				}
			}
			&__details {
				width: calc(66.66% - 10px);
				@media (max-width: $britax-container-max-width-s) {
					width: 100%;
				}
			}
			&__text {
				ul {
					padding-left: 25px;
					li {
						display: inline-block;
						margin-right: 50px;
						width: calc(50% - 30px);
						&:nth-child(even) {
							margin-right: 0;
						}
						@media (max-width: $britax-container-max-width-s) {
							width: 100%;
							margin-right: 0;
						}
					}
				}
			}
		}
	}
	&.store-column {
		margin-top: 30px;
		.ms-content-block {
			&__link,
			&__details {
				width: calc(50% - 10px);
				@media (max-width: $britax-container-max-width-s) {
					width: 100%;
				}
				.ms-content-block__image {
					width: 100%;
				}
			}
			@media (max-width: $britax-container-max-width-s) {
				&__link {
					order: 2;
				}
				&__details {
					order: 1;
				}
			}
		}
		@media (max-width: $britax-container-max-width-s) {
			display: flex;
			.ms-content-block {
				&__details {
					@media (max-width: $britax-container-max-width-s) {
						text-align: left;
						padding-left: 0;
					}
				}
			}
		}
	}
	&.home-page-our-partner {
		.ms-content-block {
			&__image {
				img {
					width: 100%;
					max-width: 400px;
					margin: auto;
				}
			}
		}
	}
}
.grey-bg {
	background: url("#{$britax-image-path}light-grey-bg.svg") no-repeat top right;
	@media (max-width: $britax-container-max-width-s) {
		background-size: cover;
		padding-top: 30px;
	}
}
.three-column-layout,
.six-column-layout,
.two-column-container {
	.row {
		justify-content: center;
		.col-12 {
			flex: 0 0 33.33%;
			max-width: 33.33%;
			padding: 0 10px;
			@media (max-width: $britax-container-max-width-s) {
				flex: 0 0 100%;
				max-width: 100%;
				padding: 0;
			}
		}
	}
	.heading {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		border-bottom: solid 1px rgba(102, 102, 102, 0.5);
		font-size: var(--britax-body-font-size-xxl);
		margin: 30px 10px;
		padding-bottom: 15px;
		line-height: 36px;
		width: 100%;
		@media (max-width: $britax-container-max-width-s) {
			margin: 30px 0;
			text-align: center;
		}
	}
	.ms-content-block {
		&__image {
			img {
				margin: auto;
			}
		}
		&__details {
			text-align: center;
			margin-bottom: 30px;
		}
		&__title {
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-normal);
			font-size: var(--britax-body-font-size-ml);
			margin: 10px 0;
			line-height: 26px;
			font-family: $britax-primary-font-family;
		}
		&__text {
			font-size: var(--britax-body-font-size-m);
			line-height: $britax-line-height-m;
		}
		&__cta {
			> a {
				font-weight: var(--britax-font-weight-bold);
				text-decoration: none;
				margin-top: 15px;
				margin-right: 10px;
				color: --britax-primary;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		&__link {
			display: block;
		}
	}
	.row {
		@media (min-width: $britax-container-max-width-s) {
			margin: 0 -10px;
		}
		.col-auto {
			@media (min-width: $britax-container-max-width-s) {
				padding: 0 10px;
			}
			@media (max-width: $britax-container-max-width-s) {
				width: 100%;
			}
		}
	}
	.imageplacement__left {
		.ms-content-block {
			&__details {
				text-align: left;
			}
		}
	}
	&.six-column-layout {
		.row {
			.col-12 {
				flex: 0 0 16.66667%;
				max-width: 16.66667%;
				padding: 0 10px;
				@media (max-width: $britax-container-max-width-l) {
					flex: 0 0 33.33%;
					max-width: 33.33%;
				}
				@media (max-width: $britax-container-max-width-s) {
					flex: 0 0 100%;
					max-width: 100%;
					padding: 0;
				}
			}
		}
	}
	&.two-column-container {
		.row {
			.col-12 {
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 10px;
				@media (max-width: $britax-container-max-width-s) {
					flex: 0 0 100%;
					max-width: 100%;
					padding: 0;
				}
			}
		}
	}
}
.two-column-text,
.full-width-text-block {
	margin-bottom: 30px;
	.heading {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		border-bottom: solid 1px rgba(102, 102, 102, 0.5);
		font-size: var(--britax-body-font-size-xxl);
		line-height: 36px;
		margin: 30px 10px 5px;
		padding-bottom: 15px;
		width: 100%;
		@media (max-width: $britax-container-max-width-m) {
			margin: 15px 0;
			text-align: center;
		}
		@media (max-width: $britax-container-max-width-s) {
			font-size: var(--britax-body-font-size-xl);
		}
	}
	.ms-content-block {
		padding-bottom: 5px;
		&__title {
			font-size: var(--britax-body-font-size-xl);
			color: var(--britax-grey);
			line-height: 30px;
			margin-bottom: 10px;
			font-weight: var(--britax-font-weight-bold);
			font-family: $britax-primary-font-family;
		}
		&__text {
			font-size: var(--britax-body-font-size-s);
			line-height: $britax-line-height-m;
		}
		&__cta {
			> a {
				text-align: center;
				margin-top: 20px;
				position: relative;
				transition: all 0.3s;
				div {
					display: block;
					width: 100%;
				}
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-grey);
					background: var(--britax-white);
					text-decoration: none;
				}
			}
		}
	}
	.row {
		@media (min-width: $britax-container-max-width-s) {
			margin: 0 -10px;
		}
		.col-12 {
			@media (min-width: $britax-container-max-width-s) {
				padding: 0 10px;
			}
			@media (max-width: $britax-container-max-width-s) {
				width: 100%;
			}
		}
	}
	ul {
		padding-left: 25px;
		li {
			list-style: none;
			margin-bottom: 10px;
			text-align: left;
			&:before {
				// @include britax-icon();
				// content: $britax-icon-arrow;
				font-size: var(--britax-breadcrumb-icon);
				transform: rotate(90deg);
				color: var(--britax-grey);
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-xs);
				margin: 3px 10px 5px -22px;
				float: left;
			}
		}
	}
	h3 {
		font-size: var(--britax-body-font-size-xl);
		color: var(--britax-grey);
		line-height: 30px;
		margin-bottom: 10px;
		font-weight: var(--britax-font-weight-bold);
	}
	h2 {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		border-bottom: solid 1px rgba(102, 102, 102, 0.5);
		font-size: var(--britax-body-font-size-xxl);
		line-height: 36px;
		margin: 15px 0;
		padding-bottom: 10px;
		font-family: $britax-primary-font-family;
		@media (max-width: $britax-container-max-width-s) {
			font-size: var(--britax-body-font-size-xl);
		}
	}
	ol {
		padding-left: 16px;
		li {
			margin-bottom: 10px;
			text-align: left;
		}
	}
	&.grey-bg,
	.grey-bg {
		background-color: var(--britax-silver-grey);
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 1px;
	}
	&.grey-bg {
		padding: 15px 15px 5px;
	}
	p {
		line-height: $britax-line-height-m;
		margin-bottom: 10px;
	}
}
.quick-facts {
	margin-bottom: 30px;
	.ms-text-block {
		h2 {
			font-size: var(--britax-body-font-size-xl);
			color: var(--britax-grey);
			line-height: 30px;
			margin-bottom: 10px;
			font-weight: var(--britax-font-weight-bold);
			font-family: $britax-primary-font-family;
		}
		p {
			font-size: var(--britax-body-font-size-s);
			margin-bottom: 16px;
		}
	}
	.col-12:first-child {
		.ms-text-block {
			padding-right: 15px;
		}
	}
	.col-12:nth-child(2) {
		.ms-text-block {
			border: solid 1px var(--britax-primary);
			h3 {
				background: var(--britax-primary);
				color: var(--britax-grey);
				font-size: var(--britax-body-font-size-ml);
				padding: 10px 15px;
			}
			ul {
				padding: 10px 16px;
				margin: 0;
				list-style: none;
				li {
					overflow: hidden;
					margin: 10px 0;
					padding-left: 20px;
					text-align: left;
					&:before {
						// @include britax-icon();
						// content: $britax-icon-arrow;
						font-size: var(--britax-breadcrumb-icon);
						transform: rotate(90deg);
						color: var(--britax-grey);
						font-weight: var(--britax-font-weight-bold);
						font-size: var(--britax-body-font-size-xs);
						margin: 3px 10px 5px -22px;
						float: left;
					}
				}
			}
		}
	}
}
.ms-accordion {
	&.accordion-panel {
		margin-bottom: 30px;
		.drawer__button {
			padding: 5px 30px 10px 0;
			height: auto;
			border-bottom: solid 1px var(--britax-silver-grey);
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			font-size: var(--britax-body-font-size-m);
			position: relative;
			text-align: left;
			&:after {
				display: none;
			}
			&:before {
				// @include britax-icon();
				// content: $britax-icon-arrow;
				transform: rotate(180deg);
				color: var(--britax-grey);
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-xs);
				position: absolute;
				right: 10px;
				top: 8px;
			}
			&[aria-expanded="true"] {
				&:before {
					transform: rotate(0deg);
				}
			}
		}
		.ms-accordion-item-content {
			border-bottom: solid 1px var(--britax-silver-grey);
			.ms-text-block {
				margin-top: 0;
				p {
					line-height: $britax-line-height-m;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				ul {
					padding: 10px 16px;
					margin: 0;
					list-style: none;
					li {
						overflow: hidden;
						margin: 10px 0;
						padding-left: 20px;
						text-align: left;
						&:before {
							// @include britax-icon();
							// content: $britax-icon-arrow;
							font-size: var(--britax-breadcrumb-icon);
							transform: rotate(90deg);
							color: var(--britax-grey);
							font-weight: var(--britax-font-weight-bold);
							font-size: var(--britax-body-font-size-xs);
							margin: 3px 10px 5px -22px;
							float: left;
						}
					}
				}
				ol {
					padding-left: 16px;
					li {
						margin-bottom: 10px;
						text-align: left;
					}
				}
			}
		}
		.ms-accordion-header-section {
			display: none;
		}
	}
	&-item {
		&__drawer {
			border-top: 0;
		}
	}
}
.accordion-panel {
	.ms-accordion {
		margin-bottom: 30px;
		.drawer__button {
			padding: 5px 30px 10px 0;
			height: auto;
			border-bottom: solid 1px var(--britax-silver-grey);
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			font-size: var(--britax-body-font-size-m);
			position: relative;
			text-align: left;
			&:after {
				display: none;
			}
			&:before {
				// @include britax-icon();
				// content: $britax-icon-arrow;
				transform: rotate(180deg);
				color: var(--britax-grey);
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-xs);
				position: absolute;
				right: 10px;
				top: 8px;
			}
			&[aria-expanded="true"] {
				&:before {
					transform: rotate(0deg);
				}
			}
		}
		.ms-accordion-item-content {
			border-bottom: solid 1px var(--britax-silver-grey);
			.ms-text-block {
				margin-top: 0;
				p {
					line-height: $britax-line-height-m;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				ul {
					padding: 10px 16px;
					margin: 0;
					list-style: none;
					li {
						overflow: hidden;
						margin: 10px 0;
						padding-left: 20px;
						text-align: left;
						&:before {
							// @include britax-icon();
							// content: $britax-icon-arrow;
							font-size: var(--britax-breadcrumb-icon);
							transform: rotate(90deg);
							color: var(--britax-grey);
							font-weight: var(--britax-font-weight-bold);
							font-size: var(--britax-body-font-size-xs);
							margin: 3px 10px 5px -22px;
							float: left;
						}
					}
				}
				ol {
					padding-left: 16px;
					li {
						margin-bottom: 10px;
						text-align: left;
					}
				}
			}
		}
		.ms-accordion-header-section {
			display: none;
		}
	}
	&-item {
		&__drawer {
			border-top: 0;
		}
	}
	.col-12:first-child {
		padding-right: 10px;
		@media (max-width: $britax-container-max-width-s) {
			padding-right: 0;
		}
	}
	.col-12:last-child {
		padding-left: 10px;
		@media (max-width: $britax-container-max-width-s) {
			padding-left: 0;
		}
	}
	&.default-container-3-slots {
		margin-bottom: 30px;
		.heading {
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			border-bottom: solid 1px rgba(102, 102, 102, 0.5);
			font-size: var(--britax-body-font-size-xxl);
			margin: 15px 0 5px;
			padding-bottom: 10px;
			line-height: 36px;
			width: 100%;
		}
		.ms-accordion {
			margin-bottom: 0;
			.drawer {
				&__button {
					color: var(--britax-grey);
					font-size: var(--britax-body-font-size-ml);
					float: left;
					width: auto;
					clear: both;
					border: 0;
					padding: 0 30px 5px 0;
				}
				.collapse,
				.collapsing {
					overflow: hidden;
					width: 100%;
				}
			}
			.ms-accordion-item-content {
				border: 0;
				padding: 5px 0;
			}
		}
		@media (min-width: $britax-container-max-width-m) {
			.col-auto {
				&:nth-child(1) {
					padding-right: 16px;
				}
				&:nth-child(2) {
					padding: 0 8px;
				}
				&:nth-child(3) {
					padding-left: 16px;
				}
			}
		}
	}
}
.listing-style {
	margin-bottom: 30px;
	.heading {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		border-bottom: solid 1px rgba(102, 102, 102, 0.5);
		font-size: var(--britax-body-font-size-xxl);
		line-height: 36px;
		padding-bottom: 15px;
		width: 100%;
	}
	.ms-text-block {
		margin-top: 0;
		p {
			line-height: $britax-line-height-m;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		ul {
			padding: 10px 0;
			margin: 0;
			list-style: none;
			li {
				overflow: hidden;
				margin: 10px 0;
				padding-left: 20px;
				text-align: left;
				display: inline-block;
				width: calc(33.33% - 3px);
				@media (max-width: $britax-container-max-width-m) {
					width: calc(50% - 2px);
				}
				a {
					color: var(--britax-grey);
					font-weight: var(--britax-font-weight-bold);
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				&:before {
					// @include britax-icon();
					// content: $britax-icon-arrow;
					font-size: var(--britax-breadcrumb-icon);
					transform: rotate(90deg);
					color: var(--britax-grey);
					font-weight: var(--britax-font-weight-bold);
					font-size: var(--britax-body-font-size-xs);
					margin: 3px 10px 5px -22px;
					float: left;
				}
			}
		}
		ol {
			padding-left: 16px;
			li {
				margin-bottom: 10px;
				text-align: left;
				a {
					color: var(--britax-grey);
					font-weight: var(--britax-font-weight-bold);
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.carousel-container {
	margin: 30px 0;
	.row {
		.col-12 {
			align-self: center;
		}
	}
	.ms-carousel {
		&.carousel-block {
			background: url("#{$britax-image-path}shape.png") no-repeat top left;
			background-size: contain;
			width: 424px;
			height: 420px;
			padding: 30px;
			display: flex;
			float: right;
			@media screen and (max-width: $britax-container-max-width-s) {
				width: 340px;
				height: 330px;
				float: none;
				margin: 30px auto 0;
			}
			.ms-content-block {
				&__text {
					color: var(--britax-white);
					text-align: left;
					font-family: $britax-primary-font-family;
				}
			}
			.msc-carousel {
				&__inner {
					max-width: 350px;
					margin: 10px 0 0 20px;
					@media screen and (max-width: $britax-container-max-width-s) {
						max-width: 265px;
						margin: 40px 0 0 5px;
					}
					.ms-content-block__text {
						@media screen and (max-width: $britax-container-max-width-s) {
							font-size: var(--britax-body-font-size-xs);
						}
					}
				}
				&__control {
					&__prev,
					&__next {
						display: none;
					}
				}
			}
		}
	}
	.ms-text-block {
		text-align: center;
		padding: 20px;
		margin-top: -50px;
		a {
			color: var(--britax-grey);
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.content-with-image {
	.row {
		align-items: center;
	}
	.ms-content-block {
		&__details {
			padding: 0;
			display: block;
			position: relative;
			height: auto;
			margin: 20px 0 40px;
			width: 100%;
			.ms-content-block__cta {
				display: inline-block;
				margin-top: 0;
				a,
				.msc-cta__primary,
				.msc-cta__secondary {
					min-height: 29px;
					max-height: 29px;
					line-height: initial;
					min-width: 215px;
					border: 1px solid var(--britax-grey);
					font-size: var(--britax-body-font-size-s);
					font-weight: var(--britax-font-weight-normal);
					border-radius: 4px;
					background-color: transparent;
					color: var(--britax-grey);
					justify-content: center;
					align-items: center;
					display: inline-flex;
					cursor: pointer;
					padding: 0 15px;
					margin: 10px 0;
					transition: all 0.3s;

					@media (max-width: $britax-container-min-width-m) {
						min-width: 173px;
					}

					@media (max-width: $britax-container-max-width-s) and (min-width: $britax-breakpoint-mbl) {
						min-width: 130px;
					}

					@media (max-width: $britax-breakpoint-mbl) {
						min-width: 150px;
					}
					&:hover,
					&:focus,
					&:active {
						color: var(--britax-grey);
						background: var(--britax-white);
						text-decoration: none;
					}
				}
			}
		}
		&__title {
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			border-bottom: solid 1px rgba(102, 102, 102, 0.5);
			font-size: var(--britax-body-font-size-xxl);
			padding-bottom: 15px;
			margin-bottom: 15px;
			line-height: 30px;
			font-family: $britax-primary-font-family;
		}
		&__text {
			font-size: var(--britax-body-font-size-m);
			line-height: $britax-line-height-m;
			> p {
				line-height: $britax-line-height-m;
				margin-bottom: 10px;
			}
		}
	}
	.col-12:nth-child(2) {
		.ms-content-block {
			text-align: center;
			img {
				margin: auto;
			}
			&__title {
				display: none;
			}
		}
	}
}
.video-container {
	margin: 30px auto;
	.col-12 {
		max-width: 952px;
		margin: auto;
		text-align: center;
	}
	h2 {
		color: var(--britax-grey);
		font-size: var(--britax-body-font-size-xxl);
		font-weight: var(--britax-font-weight-bold);
		line-height: initial;
		margin-bottom: 15px;
		@media screen and (max-width: $britax-container-max-width-m) {
			font-size: var(--britax-body-font-size-xxl);
		}
	}
	.ms-iframe {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		margin-bottom: 15px;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			border: 0;
			width: 100%;
			height: 100%;
		}
	}
	.ms-text-block {
		margin-bottom: 30px;
		h3,
		h4,
		h5 {
			margin-bottom: 10px;
		}
	}
}
.promo-banner {
	margin-bottom: 30px;
	text-align: center;
	.ms-content-block {
		&__image {
			img {
				margin: 0 auto 15px;
				width: 100%;
			}
		}
		&__details {
			p {
				line-height: initial;
				margin-bottom: 10px;
			}
		}
	}
}
.text-with-link {
	padding-top: 25px;
	padding-bottom: 25px;
	border-top: 1px solid var(--britax-primary);
	border-bottom: 1px solid var(--britax-primary);
	margin-bottom: 40px;
	min-height: auto;
	.ms-content-block {
		&__details {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding-top: 0;
			margin-left: auto;
			margin-right: auto;
			@media screen and (max-width: $britax-container-max-width-m) {
				display: block;
				text-align: center;
			}
		}
		&__text {
			margin-right: 40px;
			margin-top: 0;
			font-size: var(--britax-body-font-size-ml);
			font-weight: var(--britax-font-weight-bold);
			@media screen and (max-width: $britax-container-max-width-m) {
				margin-right: 0;
				margin-bottom: 15px;
			}
		}
		&__cta {
			display: inline-block;
			margin-top: 0;
			a {
				min-height: 38px;
				max-height: 38px;
				line-height: initial;
				min-width: 215px;
				border: 1px solid var(--britax-grey);
				font-size: var(--britax-body-font-size-l);
				font-weight: var(--britax-font-weight-normal);
				border-radius: 4px;
				background-color: transparent;
				color: var(--britax-grey);
				justify-content: center;
				align-items: center;
				display: inline-flex;
				cursor: pointer;
				padding: 6px;
				transition: all 0.3s;
				margin-right: 15px;
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-grey);
					background: var(--britax-white);
					text-decoration: none;
				}
				@media (max-width: $britax-container-min-width-m) {
					min-width: 173px;
				}
				@media (max-width: $britax-container-max-width-s) and (min-width: $britax-breakpoint-mbl) {
					min-width: 130px;
				}
				@media (max-width: $britax-breakpoint-mbl) {
					min-width: 150px;
				}
			}
		}
		@media (max-width: $britax-breakpoint-mbl-sm) {
			.ms-content-block {
				&__details {
					flex-direction: column;
				}
				&__text {
					font-size: var(--britax-body-font-size-ml);
					margin-right: 0;
					margin-bottom: 20px;
				}
				&__cta a {
					margin: auto;
					margin-right: 10px;
				}
			}
		}
	}
}
.default-container-3-slots {
	&.time-fragment-col-spacing {
		[class^="col"] {
			padding-right: 30px;
			&:last-child {
				padding-right: 0;
			}
			@media (max-width: $britax-container-max-width-s) {
				width: 100%;
				padding-right: 0;
			}
		}
	}
}
.time-slots {
	h3 {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		border-bottom: solid 1px rgba(102, 102, 102, 0.3);
		font-size: var(--britax-body-font-size-ml);
		line-height: 28px;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 15px 0 30px;
		li {
			overflow: hidden;
			margin-bottom: 7px;
			padding-bottom: 7px;
			border-bottom: solid 1px rgba(102, 102, 102, 0.2);
			span {
				float: right;
				width: calc(100% - 82px);
				text-align: right;
			}
			&:last-child {
				border-bottom: 0;
				margin-bottom: 30px;
			}
		}
	}
}
.restaurant-msg {
	display: flex;
	align-items: center;
	background: var(--britax-white-primary);
	color: var(--britax-grey);
	min-height: 236px;
	padding: 25px;
	text-align: center;
	margin-bottom: 30px;
	h3 {
		font-size: var(--britax-body-font-size-ml);
		margin-bottom: 15px;
	}
	.white-filled-btn {
		text-decoration: none;
	}
}
.safety-section {
	text-align: center;
	margin-bottom: 30px;
	h2 {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		font-size: var(--britax-body-font-size-xl);
		line-height: 32px;
		width: 100%;
		font-family: $britax-primary-font-family;
	}
	h3 {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		font-size: var(--britax-body-font-size-ml);
		line-height: 28px;
		width: 100%;
	}
	.ms-content-block {
		&__image {
			img {
				margin: auto;
			}
		}
		&__cta {
			> a {
				font-weight: var(--britax-font-weight-bold);
				display: block;
				padding: 5px;
				margin-top: 5px;
				cursor: pointer;
			}
		}
	}
}
.filter-navigation {
	.ms-tab-tabs-section {
		display: flex;
		justify-content: center;
		@media (max-width: $britax-container-max-width-s) {
			display: block;
		}
		&-li {
			padding: 0;
			margin-right: 15px;
			cursor: pointer;
			@media (max-width: $britax-container-max-width-s) {
				text-align: center;
				margin-right: 0;
			}
			&:focus {
				border: 0;
				outline: 0;
			}
			.ms-tab-item-header {
				background-color: transparent;
				border: 1px solid var(--britax-grey);
				color: var(--britax-grey);
				border-radius: 4px;
				text-decoration: none;
				font-size: var(--britax-body-font-size-s);
				font-weight: var(--britax-font-weight-normal);
				padding: 5px 30px;
				cursor: pointer;
				transition: all 0.3s;
				div {
					margin: auto;
				}
				&:before {
					display: none;
				}
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-grey);
					background: var(--britax-white);
					text-decoration: none;
				}
			}
			&[aria-selected="true"] {
				.ms-tab-item-header {
					background: var(--britax-silver-grey);
				}
			}
		}
	}
}
.container.restraunt-location {
	padding-left: 0;
	padding-right: 0;
}
.line-button {
	background-color: transparent;
	border: 1px solid var(--britax-grey);
	color: var(--britax-grey);
	border-radius: 4px;
	text-decoration: none;
	font-size: var(--britax-body-font-size-s);
	font-weight: var(--britax-font-weight-normal);
	padding: 5px 30px;
	margin-top: 5px;
	cursor: pointer;
	transition: all 0.3s;
	div {
		margin: auto;
	}
	&:hover,
	&:focus,
	&:active {
		color: var(--britax-grey);
		background: var(--britax-white);
		text-decoration: none;
	}
}
.white-filled-btn {
	background-color: var(--britax-grey);
	border-radius: 4px;
	display: inline-block;
	padding: 2px 12px;
	text-decoration: none;
	margin-top: 10px;
}
.iframe-block {
	h2 {
		color: var(--britax-grey);
		font-weight: var(--britax-font-weight-bold);
		font-size: var(--britax-body-font-size-xxl);
		margin: 15px 0;
		line-height: 42px;
		width: 100%;
	}
	iframe {
		border: 0;
		height: 100vh;
	}
}
.category-shop {
	padding-top: 66px;
	padding-bottom: 82px;
	.col-12 {
		@media (min-width: $britax-container-max-width-s) {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}
	}
	.heading {
		@include tile-heading();
		font-size: 30px;
		width: 100%;
		text-align: center;
		margin-bottom: 21px;
	}
	.ms-content-block {
		&__title {
			@include cat-link();
		}
		&__image {
			margin: 0 0 7px;
			img {
				margin: auto;
			}
		}
		&__details {
			text-align: center;
			.msc-cta__primary {
				color: var(--britax-grey);
			}
		}
	}
	@media (max-width: $britax-container-min-width-l) {
		padding-top: 5px;
		padding-bottom: 46px;
		.heading {
			margin-bottom: 43px;
			margin-top: 30px;
		}
		.ms-content-block {
			&__image {
				margin: 0 0 4px;
			}
		}
	}
	@media (max-width: $britax-container-min-width-s) {
		padding-bottom: 36px;
		.heading {
			margin-bottom: 42px;
		}
		.ms-content-block {
			&__image {
				margin: 0 auto 5px;
				width: 100px;
			}
		}
	}
	@media (max-width: $britax-container-max-width-m) {
		.col-12 {
			&:nth-child(6),
			&:nth-child(7) {
				display: none;
			}
		}
	}
	@media (max-width: $britax-container-max-width-s) {
		padding-top: 3px;
		padding-bottom: 19px;
		.heading {
			margin-bottom: 39px;
		}
		.ms-content-block {
			min-height: 167px;
		}
		.col-12 {
			max-width: 50%;
		}
	}
	.col-md:last-child {
		.ms-content-block[data-m-layout="tile"]:last-child {
			margin-right: auto;
		}
	}
}
.store-temporarily-closed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: 999;
	.ms-content-block {
		background: var(--britax-white);
		width: 600px;
		height: 220px;
		margin: auto;
		position: absolute;
		top: 50vh;
		left: 50%;
		margin-top: -50px;
		margin-left: -300px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		@media (max-width: $britax-container-max-width-s) {
			width: 90%;
			left: 0;
			margin-left: 5%;
			padding: 30px;
			height: auto;
		}
		&__title {
			margin-bottom: 15px;
			font-family: $britax-primary-font-family;
		}
		&__text {
			> p {
				margin-bottom: 10px;
			}
		}
		&__cta {
			text-align: center;
			> a {
				background-color: var(--britax-silver-grey);
				height: 44px;
				font-size: var(--britax-body-font-size-m);
				font-weight: var(--britax-font-weight-bold);
				line-height: 44px;
				margin: 10px 5px 0;
				padding: 0 25px;
				text-align: center;
				border-radius: 4px;
				cursor: pointer;
				transition: all 0.3s;
				border: 0;
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-grey);
					background: var(--britax-silver-grey);
					text-decoration: none;
				}
				> div {
					width: 100%;
				}
			}
		}
	}
}
.text-center {
	text-align: center;
}
.seprator {
	background-color: var(--britax-grey);
	height: 1px;
	margin: 30px 0;
}
.landing-page-header {
	position: absolute;
	width: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	margin-top: 0;
	padding: 17px 10px;
	text-align: right;
	@media screen and (max-width: $britax-container-max-width-s) {
		background-color: var(--britax-white);
	}
	// &:before {
	//     background: url('#{$britax-image-path}logo-white.svg') no-repeat top left;
	//     width: 158px;
	//     height: 87px;
	//     content: "";
	//     position: absolute;
	//     top: 15px;
	//     left: 15px;
	//     @media (max-width: $britax-container-max-width-s) {
	//         background: url('#{$britax-image-path}logo.svg') no-repeat top left;
	//         width: 84px;
	//         height: 44px;
	//         top: 5px;
	//         left: 10px;
	//         background-size: 100%;
	//     }
	// }
	> a {
		color: var(--britax-primary);
		text-decoration: none;
		margin: 0 10px;
		position: relative;
		float: right;
		@media (max-width: $britax-container-max-width-s) {
			font-size: var(--britax-body-font-size-xs);
			color: var(--britax-primary);
			margin: 0 3px;
		}
		@media (max-width: $britax-breakpoint-mbl-sm) {
			font-size: var(--britax-body-font-size-xxs);
		}

		&:hover {
			text-decoration: underline;
			color: var(--britax-grey);
		}
	}
	.language-picker {
		&__form {
			position: relative;
			padding: 0 10px;
			float: right;
			color: var(--britax-primary);
			@media (max-width: $britax-container-max-width-s) {
				font-size: var(--britax-body-font-size-xs);
				color: var(--britax-grey);
				margin: 0 5px 0 10px;
			}
			@media (max-width: $britax-breakpoint-mbl-sm) {
				font-size: var(--britax-body-font-size-xxs);
			}
			&:before {
				@include britax-icon();
				content: $britax-icon-language;
				position: absolute;
				top: 3px;
				left: -4px;
			}
			select {
				background-color: transparent;
				border: 0;
				outline: none;
				color: var(--britax-primary);
				margin-left: 8px;
				-webkit-appearance: none;
				padding-right: 20px;
				position: relative;
				z-index: 2;
				@media (max-width: $britax-container-max-width-s) {
					font-size: var(--britax-body-font-size-xs);
					color: var(--britax-primary);
					margin-left: 5px;
				}
				@media (max-width: $britax-breakpoint-mbl-sm) {
					font-size: var(--britax-body-font-size-xxs);
				}
				option {
					background-color: var(--britax-grey);
					color: var(--britax-primary);
					padding: 5px;
				}
				&:hover,
				&:focus {
					background-color: var(--britax-primary);
					color: var(--britax-grey);
				}
			}
			&:after {
				@include msv-icon();
				content: $msv-ChevronDown;
				font-size: var(--britax-body-font-size-xxs);
				margin-left: -12px;
			}
		}
	}
}
.landing-page-content {
	.ms-content-block {
		&__details {
			display: flex;
			flex-direction: column;
			align-items: normal;
			justify-content: center;
			@media (max-width: $britax-container-max-width-s) {
				width: 100%;
				text-align: center;
				margin-top: 15px;
				padding-left: 0;
			}
		}
		&__title {
			color: var(--britax-grey);
			font-weight: var(--britax-font-weight-bold);
			font-size: var(--britax-body-font-size-xl);
			padding-bottom: 15px;
			line-height: $britax-line-height-xl;
			max-width: 500px;
			font-family: $britax-primary-font-family;
			@media (max-width: $britax-container-max-width-s) {
				font-size: var(--britax-body-font-size-ml);
				line-height: $britax-line-height-ml;
			}
		}
		&__text {
			font-size: var(--britax-body-font-size-s);
			line-height: $britax-line-height-m;
			max-width: 460px;
			h4 {
				color: var(--britax-grey);
				font-weight: var(--britax-font-weight-bold);
				font-size: var(--britax-body-font-size-ml);
				line-height: 24px;
				font-family: $britax-primary-font-family;
			}
			p {
				line-height: $britax-line-height-m;
				margin-bottom: 10px;
				font-family: $britax-primary-font-family;
			}
		}
		&__cta {
			> a {
				@include button-default();
				@include primary-button(
					var(--britax-primary),
					var(--britax-white),
					var(--britax-primary)
				);
				font-size: var(--britax-body-font-size-m);
				border-radius: 25px;
				text-decoration: none;
				line-height: initial;
				cursor: pointer;
				margin-top: 15px;
				transition: all 0.3s;
				div {
					margin: auto;
				}
				&:hover,
				&:focus,
				&:active {
					color: var(--britax-white);
					background: var(--britax-grey);
					text-decoration: none;
				}
			}
		}
	}
}
.landing-page {
	position: absolute;
	top: 0;
	left: 0;
	min-height: auto;
	height: 110px;
	width: 200px;
	z-index: 17;
	.ms-content-block__link {
		display: block;
		height: 110px;
		width: 200px;
		&:before {
			background: url("#{$britax-image-path}logo-white.svg") no-repeat top left;
			width: 158px;
			height: 87px;
			content: "";
			position: absolute;
			top: 15px;
			left: 15px;
			@media (max-width: $britax-container-max-width-s) {
				background: url("#{$britax-image-path}logo.svg") no-repeat top left;
				width: 84px;
				height: 44px;
				top: 5px;
				left: 10px;
				background-size: 100%;
			}
		}
	}
}
