$msv-checkout-customer-account-max-flex-basis: 100%;
$msv-checkout-customer-account-padding-bottom: 20px;
$msv-checkout-customer-account-input-label-margin-bottom: 10px;
$msv-checkout-customer-account-button-border-radius: 2px;
$msv-checkout-customer-account-button-margin-left: 5px;
$msv-checkout-customer-account-button-margin-top: 20px;
$msv-checkout-customer-account-input-height: 32px;
$msv-checkout-customer-account-input-border-radius: 2px;
$msv-checkout-customer-account-input-padding: 6px 8px;
$msv-checkout-customer-account-input-width: 40%;
$msv-checkout-customer-account-error-icon-margin-right: 8px;
$msv-checkout-customer-account-input-max-width: 610px;
$msv-checkout-customer-account-input-fields-padding-right: 20px;
$msv-checkout-customer-account-input-fields-min-width: 130px;
$msv-checkout-customer-account-input-fields-width: 25%;
$msv-checkout-customer-account-input-num-fields-width: 50%;
$msv-checkout-customer-account-border-bottom: 1px dashed $msv-divider-color;
$msv-checkout-customer-account-title-margin-bottom: 8px;

//style presets
:root {
  --msv-checkout-customer-account-font-size: var(--msv-body-font-size-l);
  --msv-checkout-customer-account-font-color: var(--msv-font-primary-color);

  // title
  --msv-checkout-customer-account-title-font-size: var(--msv-body-font-size-s);

  // form
  --msv-checkout-customer-account-form-bg: #{$msv-white};
  --msv-checkout-customer-account-form-font-color: var(
    --msv-font-primary-color
  );

  // primary button
  --msv-checkout-customer-account-btn-bg: var(--msv-accent-brand-color);
  --msv-checkout-customer-account-btn-font-color: var(
    --msv-font-secondary-color
  );
  --msv-checkout-customer-account-btn-border: var(--msv-accent-brand-color);
  --msv-checkout-customer-account-btn-font-size: var(--msv-body-font-size-m);
}

.ms-checkout-customer-account {
  padding-bottom: $msv-checkout-customer-account-padding-bottom;

  &__form {
    flex-flow: row wrap;
    &-edit {
      .add-credit-balance {
        position: relative;
        .ms-checkout-customer-account__btn-pay {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          padding: 0 15px;
          background-color: transparent;
          &.disabled {
            color: var(--britax-grey);
          }
        }
      }
    }
  }

  &__title {
    display: block;
    font-size: var(--msv-checkout-customer-account-title-font-size);
    font-weight: var(--msv-font-weight-bold);
    line-height: $msv-line-height-s;
    margin-bottom: $msv-checkout-customer-account-title-margin-bottom;
  }

  &__input-label {
    font-size: var(--britax-font-size-sm);
    font-weight: var(--britax-font-weight-normal);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-grey);
    display: block;
    margin-bottom: $msv-checkout-customer-account-input-label-margin-bottom;
    flex-basis: $msv-checkout-customer-account-max-flex-basis;
    padding-top: 15px;
    width: 75%;
  }

  &__input-amount {
    @include form-input-el(
      var(--msv-checkout-customer-account-form-bg),
      var(--msv-checkout-customer-account-form-font-color)
    );
    font-size: var(--britax-body-font-size-xxs);
    color: var(--britax-grey);
    padding-left: 22px;
    width: 100%;
    border: 1px solid rgba(98, 108, 118, 0.18);
    box-sizing: border-box;
    border-radius: 3px;
  }

  &__input-alert-label {
    @include form-input-alert-message();
    display: block;
  }

  &__btn-pay {
    font-size: var(--britax-body-font-size-xxs);
    color: var(--britax-primary);
    // background: none;
    font-weight: var(--britax-font-weight-normal);
    // padding-right: 0px;
    margin-top: 5px;
    &::after {
      font-family: "Font Awesome 5 Free";
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      font-weight: 900;
      text-rendering: auto;
      line-height: 1;
      content: "";
      display: inline-flex;
      margin-left: 10px;
      text-align: right;
    }
  }

  &__input-error {
    @include form-input-error-message();
    width: 100%;
    display: block;
  }

  &__account-name {
    font-size: var(--britax-font-size-sm);
    font-weight: var(--britax-font-weight-700);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-primary);
  }
  &__account-number {
    padding: 5px 0 15px;
    font-size: var(--britax-font-size-sm);
    font-weight: var(--britax-font-weight-normal);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-primary);
  }

  &__customer-since {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: var(--britax-font-size-sm);
    font-weight: var(--britax-font-weight-normal);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-primary);

    display: none;
  }

  &__account-credit {
    font-size: var(--britax-body-font-size-xs);
    font-weight: var(--britax-font-weight-700);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-primary);
    background: var(--britax-grey-2);
    float: left;
    padding: 10px 10px 10px 5px;
    &-label {
      font-size: var(--britax-body-font-size-xs);
      font-weight: var(--britax-font-weight-normal);
      line-height: var(--britax-line-height-xs);
      color: var(--britax-primary);
      background: var(--britax-grey-2);
      float: left;
      padding: 10px;
      border-radius: 3px 0 0;
    }
  }

  &__bottom-border {
    padding-top: 20px;
    width: 100%;
    border-bottom: 1px dashed rgb(209, 209, 209);
  }

  &__input-fields {
    display: inline-block;
    flex-grow: 1;
    width: 100%;
  }

  &__btn-apply {
    margin-left: $msv-checkout-customer-account-button-margin-left;
    margin-top: $msv-checkout-customer-account-button-margin-top;
    width: auto;
  }

  &__account-credit-toggle-down {
    @include add-icon($msv-ChevronDown, after);
    padding: 7px 10px 6px 3px;
    font-size: var(--britax-body-font-size-xxs);
    background: var(--britax-grey-2);
    color: var(--britax-primary);
    display: inline-block;
    border-radius: 0 3px 0 0;
    width: auto;
  }

  &__account-credit-toggle-up {
    @include add-icon($msv-ChevronUp, after);
    padding: 7px 10px 6px 3px;
    font-size: var(--britax-body-font-size-xxs);
    background: var(--britax-grey-2);
    color: var(--britax-primary);
    display: inline-block;
    border-radius: 0 3px 0 0;
    width: auto;
  }

  &__credit-details-section {
    background-color: var(--britax-grey-2);
    padding: 10px;
    font-size: var(--britax-body-font-size-xs);
    color: var(--britax-primary);
    border-radius: 0 3px 3px 3px;
  }

  &__account-credit-current-order-preview {
    margin-top: 0;
    margin-bottom: 8px;
    padding-bottom: 3px;
    font-weight: bold;
    color: var(--britax-primary);
    border-bottom: solid 1px var(--britax-grey);
  }

  &__account-credit-current-order-icon {
    @include add-icon($msv-ListAlt, after);
    margin-right: 5px;
  }

  &__available-credit {
    float: right;
    font-weight: bold;
  }

  &__excess-credit {
    float: right;
    font-weight: bold;
  }

  &__order-total {
    float: right;
    font-weight: bold;
  }

  &__applied-line {
    margin-top: 15px;
    width: 75%;
    display: flex;
    flex-direction: column;
  }

  &__applied-label {
    padding-top: 20px;
    font-size: var(--britax-font-size-sm);
    font-weight: var(--britax-font-weight-700);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-primary);
  }

  &__applied-amount {
    padding-top: 20px;
    font-size: var(--britax-font-size-sm);
    font-weight: var(--britax-font-weight-normal);
    line-height: var(--britax-line-height-xs);
    color: var(--britax-primary);
  }

  &__remove {
    font-size: var(--britax-font-size-sm);
    color: var(--britax-primary);
    text-decoration: underline;
    display: inline-block;
    margin-top: 5px;
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &.msc-btn {
      margin-left: 0;
    }
  }

  @media screen and (min-width: $msv-breakpoint-l) {
    &__input-text {
      width: auto;
      flex-grow: 1;
      max-width: $msv-checkout-customer-account-input-max-width;
    }
  }
}
#ms-checkout-customer-account__amount {
  background: var(--britax-grey-2);
  display: inline-block;
  padding: 12px 25px;
  color: var(--britax-primary);
}

.non-display-non-admin-case {
  display: none;
}
