;
@import "bootstrap/scss/bootstrap";
@import "00-settings/index";
@import "01-tools/index";
@import "02-generic/index";
@import "03-components/index";
@import "04-modules/index";
@import "05-rtl/index";


$brandPrimaryColor: #FFFFFF;
$brandSecondaryColor: #CCCCCC;
$backgrounDefaultColor: #FFFFFF;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;


:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgrounDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}
