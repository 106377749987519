/* Dots */
/* Slider */
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: var(--britax-primary);
  border: none;
  outline: none;
  background: transparent;
  left: -25px;
  &:hover {
    color: var(--britax-grey);
    outline: none;
    background: transparent;
    &:before {
      opacity: 1;
    }
  }
  &:focus {
    color: var(--britax-grey);
    outline: none;
    background: transparent;
    &:before {
      opacity: 1;
    }
  }
  &:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: var(--britax-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "←";
    &:hover {
      color: var(--britax-grey);
    }
  }
}
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: var(--britax-primary);
  border: none;
  outline: none;
  background: transparent;
  right: -25px;
  &:hover {
    color: var(--britax-grey);
    outline: none;
    background: transparent;
    &:before {
      opacity: 1;
    }
  }
  &:focus {
    color: var(--britax-grey);
    outline: none;
    background: transparent;
    &:before {
      opacity: 1;
    }
  }
  &:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: var(--britax-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "→";
    :hover {
      color: var(--britax-grey);
    }
  }
}
.slick-prev.slick-disabled {
  &:before {
    opacity: 0.25;
  }
}
.slick-next.slick-disabled {
  &:before {
    opacity: 0.25;
  }
}
[dir="rtl"] {
  .slick-prev {
    right: -25px;
    left: auto;
    &:before {
      content: "→";
    }
  }
  .slick-next {
    right: auto;
    left: -25px;
    &:before {
      content: "←";
    }
  }
  .slick-slide {
    float: right;
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &:hover {
        outline: none;
        &:before {
          opacity: 1;
        }
      }
      &:focus {
        outline: none;
        &:before {
          opacity: 1;
        }
      }
      &:before {
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  li.slick-active {
    button {
      &:before {
        opacity: 0.75;
        color: black;
      }
    }
  }
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: table;
    content: "";
  }
  &:after {
    display: table;
    content: "";
    clear: both;
  }
}
.slick-loading {
  .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    visibility: hidden;
  }
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  img {
    display: block;
  }
}
.slick-slide.slick-loading {
  img {
    display: none;
  }
}
.slick-slide.dragging {
  img {
    pointer-events: none;
  }
}
.slick-initialized {
  .slick-slide {
    display: block;
  }
}
.slick-vertical {
  .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
