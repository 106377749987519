.react-bootstrap-table {
    tr {
        th {
            position: relative;
            color: var(--britax-grey);
            font-weight: 500;
            text-align: left;
            padding: 16px;
            background: var(--britax-grey-2);
            border-bottom: 1px solid #f0f0f0;
            .order {
                float: right;
                position: relative;
                text-align: center;
                width: 15px;
                height: 22px;
                color: var(--britax-cold-grey);
                .dropdown {
                    position: absolute;
                    .caret {
                        @include add-icon($msv-sortup);
                    }
                }
                .dropup {
                    position: absolute;
                    .caret {
                        @include add-icon($msv-sortdown);
                    }
                }
            }
            .react-bootstrap-table-sort-order {
                float: right;
                position: relative;
                text-align: center;
                width: 15px;
                height: 22px;
                color: var(--britax-primary);
                .caret {
                    @include add-icon($msv-sortdown);
                }
                &.dropup {
                    .caret {
                        @include add-icon($msv-sortup);
                    }
                }
            }
            .multiSelectContainer {
                height: 20px;
                position: absolute;
                top: 15px;
                right: 0;
                .searchWrapper {
                    padding: 0;
                    border: 0;
                    text-align: right;
                    color: var(--britax-cold-grey);
                    @include add-icon($msv-Filter);
                    &:before {
                        margin: 7px 10px 0 0;
                        font-size: 80%;
                    }
                    input.searchBox {
                        width: 100%;
                        margin-top: 0;
                        font-size: 0;
                        height: 0;
                    }
                    .chip {
                        display: none;
                    }
                }
                .optionListContainer {
                    background-color: var(--britax-grey-2);
                    min-width: 180px;
                    .highlightOption {
                        background-color: var(--britax-grey-2);
                        color: var(--britax-grey);
                        &:hover {
                            background-color: var(--britax-primary);
                            color: var(--britax-white);
                        }
                    }
                }
                li {
                    font-size: var(--britax-body-font-size-xs);
                    padding: 8px 0 8px 8px;
                    line-height: 16px;
                    &:hover {
                        background-color: var(--britax-primary);
                        color: var(--britax-white);
                    }
                    input[type=checkbox] {
                        float: left;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        td {
            border-bottom: 1px solid #f0f0f0;
            position: relative;
            padding: 16px;
            overflow-wrap: break-word;
            transition: all 0.5s;
            &:first-child {
                background: var(--britax-grey-2);
            }
            a {
                color: var(--britax-grey);
            }
        }
        &:hover {
            td {
                background-color: #fafafa;
            }
        }
    }
}
.react-bootstrap-table-pagination {
    .react-bs-table-sizePerPage-dropdown {
        display: none;
    }
    .react-bootstrap-table-pagination-list {
        padding-right: 0;
    }
}
.pagination {
    justify-content: flex-end;
    .page-item.active .page-link {
        background-color: var(--britax-primary);
        border-color: var(--britax-primary);
        text-decoration: none;
        cursor: default;
    }
    .page-link {
        color: var(--britax-grey);
        text-decoration: none;
        cursor: pointer;
    }
    .active {
        text-decoration: none;
    }
}