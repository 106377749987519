$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 20px;
$msv-details-text-margin-right: 5px;
$msv-details-container-max-width: 794px;
$msv-details-order-information-half-width: 50%;
$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-border: 1px solid $msv-black;
$msv-details-group-padding: 20px 0;
$msv-details-group-pickup-border: 1px solid $msv-gray-300;
$msv-details-group-delivery-items-text-color: $msv-gray-500;
$msv-details-group-delivery-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-price-left: 210px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 81px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 12px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-payment-method-padding-m: 20px 0 10px 0;
$msv-details-default-padding: 20px 0;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-details-shipping-items-label-padding-top: 30px;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;
$msv-main-container-margin: 0 auto;
$msv-font-weight-600: 600;
$msv-font-weight-355: 355;

.ms-order-confirmation {
  width: 100%;
  margin: auto;
  // max-width: $msv-details-container-max-width;
  position: relative;

  .top-blk {
    background: rgba(144, 185, 173, 0.1);
    padding: 25px 10px;
    text-align: center;
    margin: 35px auto 50px auto;
  }
  .ms-order-confirmation-sales-wrap {
    .ms-order-confirmation-sales-labels {
      display: flex;
      @media screen and (max-width: $msv-container-max-width-s) {
        border-top: solid 1px var(--britax-grey);
      }
      label {
        width: 21.66%;
        font-weight: 500;
        text-transform: uppercase;
        font-family: var(--britax-primary-font-family);
        @media screen and (max-width: $msv-container-max-width-s) {
          display: none;
        }
        &:first-of-type {
          padding: 0 0 0 15px;
          width: 43%;
        }
        &:last-of-type {
          width: 10%;
        }
      }
    }
    .ms-order-confirmation__sales-lines {
      padding: 0;
      .order-confirmation-cartItem {
        display: flex;
        @media screen and (max-width: $msv-container-max-width-s) {
          display: block;
        }
        .blk {
          width: 18.33%;
          @media screen and (max-width: $msv-container-max-width-s) {
            width: calc(100% - 82px);
            margin-left: 82px;
          }
          > span {
            > label {
              display: none;
              @media screen and (max-width: $msv-container-max-width-s) {
                display: inline-block;
              }
            }
          }
          &.product-info {
            img {
              max-width: 65px;
            }
          }
          span {
            margin: 0;
          }
          &:first-of-type {
            width: 45%;
            display: flex;
            @media screen and (max-width: $msv-container-max-width-s) {
              width: 100%;
              margin-left: 0;
            }
            .info-blk {
              display: flex;
              flex-direction: column;
              margin-left: 15px;
              span {
                text-transform: capitalize;
                &:first-of-type {
                  font-weight: 700;
                  margin: 5px 0 8px 0;
                }
              }
            }
          }
          &:last-of-type {
            width: 10%;
            @media screen and (max-width: $msv-container-max-width-s) {
              width: calc(100% - 82px);
            }
          }
        }
      }
      .sku-number {
        margin-left: 110px;
        display: block;
        font-size: var(--britax-body-font-size-xs);
        @media screen and (max-width: $britax-container-max-width-s) {
          margin: 10px 0 0 60px;
        }
      }
    }
  }
  &__heading {
    @include font-content-xl(var(--msv-font-weight-heavy));
    margin-bottom: 40px;
    @media screen and (max-width: $britax-container-max-width-s) {
      margin: 20px 0;
    }
  }

  &__order-information {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 30px;
    padding: 0 15px;
    @media screen and (max-width: $britax-container-max-width-s) {
      flex-direction: column-reverse;
      justify-content: center;
    }
    > span {
      width: 33.33%;
      @media screen and (max-width: $britax-container-max-width-s) {
        width: 100%;
      }
    }
    &-channel-reference-id,
    &-sales-id,
    &-receipt-id,
    &-receipt-email,
    &-created-date {
      @include font-content-m();
      display: block;
      margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

      span {
        font-weight: $msv-font-weight-600;
        display: block;
        word-break: break-all;
      }
      &.ms-order-confirmation__order-information-channel-reference-id {
        font-weight: normal;
      }
    }
    &-receipt-email {
      display: none;
    }

    // &-created-date {
    //   @include font-content-m();
    //   position: absolute;
    //   top: 8px;
    //   right: 0;
    // }

    @media screen and (max-width: $msv-breakpoint-m) {
      &-created-date {
        position: relative;
        top: 0;
        display: block;
        margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
      }
    }

    &-count {
      border-right: 1px solid $msv-black;
      padding-left: $msv-details-order-information-spacing;
      padding-right: $msv-details-order-information-spacing;
    }

    &-amount {
      padding-left: $msv-details-order-information-spacing;
    }
    .ms-order-confirmation__order-information-qr-code {
      display: none;
    }
  }

  &__btn-keep-shopping {
    margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;
    text-transform: uppercase;
    font-size: var(--britax-body-font-size-s);
    @include grey-button();
    color: var(--britax-primary) !important;
    &:hover {
      color: var(--britax-white) !important;
    }
  }

  &__sales-lines {
    // display: flex;
    // flex-wrap: wrap;
    padding: $msv-details-sale-lines-padding;

    &-shipping-items-label,
    &-picking-items-label,
    &-emailing-items-label {
      display: block;
      font-weight: $msv-font-weight-600;
    }

    &:first-child {
      border-top: none;
    }
  }

  &__groups {
    @include font-content-m();
  }

  &__group {
    &-delivery {
      padding-right: $msv-details-sale-line-spacing;
      padding-top: $msv-details-group-delivery-padding-top;

      &-heading {
        @include font-content-m(var(--msv-font-weight-heavy));
        margin-right: $msv-details-text-margin-right;
      }

      &-total-items {
        @include font-content-xl();
        color: $msv-details-group-delivery-items-text-color;
        margin-right: $msv-details-text-margin-right;
      }

      &-processing-label {
        display: block;
      }
    }
  }

  &__tracking-link {
    text-decoration: underline;
  }

  &__sales-line {
    // margin: $msv-details-sale-line-margin;
    border-bottom: solid 1px var(--britax-label);
    padding: 0 0 30px;
    position: relative;
    overflow: hidden;
    margin-top: 30px;

    .msc-cart-line {
      display: block;
      width: $msv-details-sale-line-width;

      &__product {
        display: none;
      }

      &__product-image {
        margin: 0;
        width: 42.6%;
        flex-direction: row;
        margin: 0 5px 0 0;
        display: flex;
        @media screen and (max-width: $britax-container-max-width-s) {
          width: auto;
          margin: 0;
        }
        img {
          width: 100px;
          @media screen and (max-width: $britax-container-max-width-s) {
            width: 50px;
          }
        }
        .product-info {
          margin-left: 10px;
          width: calc(100% - 110px);
          @media screen and (max-width: $britax-container-max-width-s) {
            width: calc(100% - 60px);
          }
        }
      }

      &__product-title {
        font-size: $msv-text-size;
        line-height: $msv-text-line-height;
      }

      &__quantity {
        .quantity-label {
          font-size: $msv-font-size-s;
          line-height: $msv-text-line-height;
        }

        .quantity-value {
          color: var(--britax-primary);
          display: inline;
          font-size: $msv-font-size-s;
          margin-left: $msv-details-text-margin-right;
        }
      }

      &__product-price,
      &__product-savings {
        display: none;
      }

      &__quantity.single-quantity {
        visibility: hidden;
      }

      &-item-product-discount {
        display: none;
        color: var(--msv-success-color);
      }
    }
    .small-blks {
      width: 21.66%;
      @media screen and (max-width: $britax-container-max-width-s) {
        width: calc(33.33% - 20px);
        margin-left: 20px;
        float: left;
        &:nth-child(2) {
          margin-left: 60px;
        }
      }
      @media screen and (max-width: $britax-breakpoint-mbl) {
        width: 100%;
        margin-left: 60px;
        float: none;
        &.msc-cart-line__quantity {
          margin-top: 10px;
        }
      }
      span {
        width: auto;
        margin: 0;
        @media screen and (max-width: $britax-container-max-width-s) {
          display: block;
        }
        @media print {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
      }
      @media screen and (min-width: $msv-container-min-width-m) {
        .mobile-label {
          display: none;
        }
      }
      &:last-of-type {
        width: 10%;
        @media screen and (max-width: $britax-container-max-width-s) {
          width: auto;
        }
      }
      .msc-price__actual {
        font-size: var(--britax-font-size-sm);
      }
    }
    &-btn-buy-it-again {
      margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
      width: $msv-details-sale-line-buy-again-btn-width;

      @include secondary-button();
    }
    &:last-child {
      @media screen and (max-width: $msv-container-max-width-s) {
        border-bottom: 0;
      }
    }
    .isretired {
      background: var(--britax-pending-color);
      color: var(--britax-white);
      border-radius: 4px;
      display: inline-block;
      padding: 5px 15px;
      position: absolute;
      top: 23px;
      right: -36px;
      width: 170px;
      text-align: center;
      transform: rotate(35deg);
      &:before {
        display: inline-block;
        content: "!";
        margin-right: 7px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background: var(--britax-white);
        color: var(--britax-pending-color);
        font-weight: var(--britax-font-weight-bold-full);
      }
    }
  }

  &__order-summary {
    @include font-content-m();

    &::after {
      clear: both;
    }

    &-heading {
      display: none;
    }

    &-line-subtotal,
    &-line-shipping,
    &-line-tax-amount {
      margin-bottom: 25px;
      width: 100%;
      @media screen and (max-width: $britax-container-max-width-s) {
        margin-bottom: 15px;
        font-size: var(--britax-body-font-size-xs);
      }
      span {
        &:last-of-type {
          font-weight: 700;
        }
      }
    }

    &-line-total-amount {
      border-top: 1px solid var(--britax-label);
      margin-bottom: $msv-details-order-summary-line-spacing;
      padding-top: $msv-details-order-summary-line-spacing;
      width: 100%;
      font-weight: 500;
      .ms-order-details__order-summary-label {
        font-weight: var(--msv-font-weight-bold);
      }
    }

    &-line-earned-points {
      .ms-order-details__order-summary-label {
        font-weight: var(--msv-font-weight-bold);
      }
    }

    &-price {
      float: right;
      @media screen and (max-width: $britax-container-max-width-s) {
        .msc-price__actual {
          font-size: var(--britax-body-font-size-xs);
        }
      }
    }
  }

  &__help {
    @include font-content-m();
    padding: $msv-details-default-padding;

    &-title {
      @include font-content-m(var(--msv-font-weight-heavy));
      margin-bottom: $msv-details-help-heading-margin-bottom;
    }

    &-content-number {
      @include vfi();
    }
  }

  &__payment-methods {
    @include font-content-m();
    padding: $msv-details-default-padding;
    width: 50%;
    float: left;

    &-title {
      @include font-content-m(var(--msv-font-weight-heavy));
      margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
    }

    &-line {
      margin-bottom: $msv-details-payment-methods-line-space;
    }

    &-card-amount,
    &-loyalty-amount,
    &-gift-card-amount {
      margin-left: 4px;
    }
  }

  &__address {
    font-weight: $msv-font-weight-355;
    margin-bottom: 6px;
    margin-top: 6px;
    span {
      line-height: 1.5rem;
      font-weight: 400;
    }
    &-header {
      display: none;
    }

    &-city,
    &-threeletterisoregionname {
      &::before {
        content: "\A";
        white-space: pre;
      }
    }

    &-county {
      display: none;
    }

    &-name,
    &-phone {
      display: block;
    }
    &-phone {
      position: initial !important;
      margin-top: 30px;
      span {
        &:first-of-type {
          display: block;
          font-weight: bold;
        }
      }
    }
  }

  &__pickup-date-timeslot {
    padding-top: 30px;
    margin-bottom: 6px;

    &-title {
      font-weight: $msv-font-weight-600;
      padding-bottom: 8px;
    }

    &-content {
      font-weight: $msv-font-weight-355;
    }
  }

  &__alert {
    display: block;
    margin-bottom: $msv-order-details-alert-margin-bottom;
    margin-top: $msv-order-details-alert-margin-top;
  }
  .btm-wrap {
    @media screen and (min-width: $msv-container-min-width-m) {
      display: flex;
      justify-content: space-between;
    }
    .ms-order-confirmation__groups {
      @media screen and (min-width: $msv-container-min-width-m) {
        width: 68%;
      }
    }
    .summary-blk {
      @media screen and (min-width: $msv-container-min-width-m) {
        width: 30%;
      }
      .ms-order-confirmation__group-delivery {
        padding: 15px;
        background: #f4f8f7;
        margin-top: 30px;
      }
    }
  }
  @media screen and (min-width: $msv-container-min-width-m) {
    margin: $msv-main-container-margin;

    &__sales-lines {
      &-shipping-items-label,
      &-picking-items-label {
        padding-top: $msv-details-shipping-items-label-padding-top;
      }

      &-emailing-items-label {
        margin-top: auto;
      }
    }

    &__address {
      &-phone {
        position: absolute;
      }
    }

    &__groups {
      & > div:nth-child(2n) + div::before {
        content: "";
        display: block;
        width: 200%;
        border-top: 1px solid $msv-gray-900;
      }
    }

    &__payment-methods {
      padding: $msv-details-payment-method-padding-m;
    }
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__groups {
      display: block;

      & > div {
        border-top: 1px solid $msv-gray-900;
      }

      & > div:first-of-type {
        border: none;
      }

      &_delivery {
        padding-right: 0;
      }
    }

    &__sales-lines {
      width: 100%;

      &-shipping-items-label,
      &-picking-items-label {
        padding-top: $msv-details-shipping-items-label-padding-top-mobile;
      }
    }

    &__order-summary,
    &__payment-methods,
    &__help {
      float: none;
      padding-right: 0;
      width: 100%;
      position: relative;
      margin-left: 0;
    }

    &__help,
    &__order-summary {
      border-top: solid 1px var(--britax-label);
    }

    &__order-summary {
      &-heading,
      &-line-subtotal,
      &-line-shipping,
      &-line-tax-amount,
      &-line-total-amount {
        max-width: 100%;
      }
    }

    &__btn-keep-shopping,
    &__sales-line-btn-buy-it-again {
      width: 100%;
    }

    .msc-cart-line__product-price {
      position: unset;
    }
  }

  .msc-empty_image {
    @include image-placeholder($msv-order-empty-image-width);
    background-size: 100%;
    height: 100px;
    width: 100px;
  }
}
