// desktop
$cookie-compliance_container-padding-l: 60px;
$cookie-compliance_container-padding-m: 20px;
$cookie-compliance_container-padding-s: 16px;
$cookie-compliance_container-max-width: $msv-breakpoint-xl;
$cookie-compliance_content-width: 100%;
$cookie-compliance_accept-btn-margin-left: 48px;
$cookie-compliance_accept-btn-width: 160px;

// mobile
$cookie-compliance_accept-btn-width-mobile: 243px;
$cookie-compliance_accept-content-margin-bottom-mobile: 24px;

// style preset
:root {
	// background
	--msv-cookie-compliance-bg-color: var(--msv-bg-color);

	// font
	--msv-cookie-compliance-font-color: var(--msv-font-primary-color);
	--msv-cookie-compliance-font-size: var(--msv-body-font-size-m);

	// link
	--msv-cookie-compliance-link-color: var(--msv-font-primary-color);

	// primary button
	--msv-cookie-compliance-primary-btn-bg: var(--msv-accent-brand-color);
	--msv-cookie-compliance-primary-btn-font-color: var(
		--msv-font-secondary-color
	);
	--msv-cookie-compliance-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-cookie-compliance {
	// background-color: var(--msv-cookie-compliance-bg-color);
	// color: var(--msv-cookie-compliance-font-color);
	// font-size: var(--msv-cookie-compliance-font-size);
	// position: fixed;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%);
	// z-index: 9999;
	// padding: 30px 50px;
	// background-color: #ffffff;
	// border-radius: 5px;

	&__container {
		display: flex;
		font-weight: normal;
		// max-width: $cookie-compliance_container-max-width;
		// margin: 0 auto;
		flex-direction: column;
		.msc-modal__content {
			h2 {
				font-size: var(--britax-font-size-xxml);
				color: var(--britax-primary);
				margin-bottom: 10px;
				text-transform: capitalize;
			}
			padding: 30px;
			> a {
				display: inline-block;
				text-align: center;
				padding: 5px;
				margin: 0;
				:hover {
					color: var(--britax-grey);
				}
			}
		}
		.form-check {
			display: flex;
			align-items: center;
			.form-check-label {
				font-weight: var(--britax-font-weight-700);
				color: var(--britax-grey);
				margin-left: 10px;
			}
			.form-check-input {
				height: 20px;
				width: 20px;
				border-radius: 5px;
				border: 1px solid #b6bcc1;
				&:checked {
					background-color: var(--britax-dark-grey);
					border-color: var(--britax-dark-grey);
				}
			}
		}
		button.ml-auto {
			background: none;
			border: none;
			color: var(--msv-cookie-compliance-link-color);
			font-size: var(--britax-body-font-size-xxs);
			color: var(--britax-grey);
			margin: 10px 0;
			&::after {
				font-family: "Font Awesome 5 Free";
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				font-weight: 900;
				text-rendering: auto;
				line-height: 1;
				content: "";
				margin-left: 5px;
			}
		}
		.collapse {
			h4 {
				font-size: var(--britax-font-size-sm);
				font-weight: var(--britax-font-weight-700);
				color: var(--britax-grey);
			}
			p {
				font-size: var(--britax-font-size-sm);
				color: var(--britax-grey);
			}
		}

		@media screen and (min-width: $msv-breakpoint-s) {
			padding-left: $cookie-compliance_container-padding-s;
			padding-right: $cookie-compliance_container-padding-s;
		}

		@media (min-width: $msv-breakpoint-m) {
			padding-left: $cookie-compliance_container-padding-m;
			padding-right: $cookie-compliance_container-padding-m;
		}
	}

	&__content {
		width: $cookie-compliance_content-width;
	}

	&__accept-button {
		float: right;
		margin-left: $cookie-compliance_accept-btn-margin-left;
		width: $cookie-compliance_accept-btn-width;
		border-radius: 25px;
		@include primary-button(
			var(--britax-primary),
			var(--britax-white),
			var(--britax-primary)
		);
		margin: 0 auto;
	}

	&__cta-layer .link {
		color: var(--msv-cookie-compliance-link-color);
		text-decoration: underline;
		font-size: var(--britax-body-font-size-xxs);
		color: var(--britax-grey);
		display: flex;
		justify-content: center;
		margin: 10px 0;
	}

	&__text {
		font-size: var(--msv-cookie-compliance-font-size);
		color: var(--britax-grey);
		text-align: justify;
		a {
			color: var(--britax-grey);
			&:hover {
				color: var(--britax-primary);
			}
		}
	}
}

@media (max-width: $msv-breakpoint-m) {
	.ms-cookie-compliance {
		&__content {
			margin-bottom: $cookie-compliance_accept-content-margin-bottom-mobile;
		}

		&__accept-button {
			float: none;
			margin: 0;
			width: $cookie-compliance_accept-btn-width-mobile;
		}

		&__container {
			align-items: center;
			flex-direction: column;
		}
	}
}

// Cookie Consent style Start
#CybotCookiebotDialog {
	border: 0 !important;
	border-radius: 0 !important;
	div {
		color: var(--britax-primary);
	}
	#CybotCookiebotDialogBody {
		#CybotCookiebotDialogPoweredbyLink {
			display: none;
		}
		#CybotCookiebotDialogBodyContent {
			padding-left: 15px;
			#CybotCookiebotDialogBodyContentTitle {
				color: var(--britax-primary);
			}
		}
	}
}
#CybotCookiebotDialogBodyLevelButtons {
	margin-top: 15px;
}
.CookieDeclaration {
	display: none !important;
}
#CookiebotWidget {
	min-height: 30px !important;
	box-shadow: 0 0 !important;
	bottom: 6px !important;
	margin-left: calc(50% - 545px) !important;
	.CookiebotWidget-body {
		.CookiebotWidget-consents-list {
			li.CookiebotWidget-approved svg {
				fill: var(--britax-primary) !important;
			}
			li:nth-child(4) {
				display: none !important;
			}
		}
	}
	#CookiebotWidget-buttons {
		#CookiebotWidget-btn-withdraw {
			border-radius: 25px;
			background-color: var(--britax-grey) !important;
			border-color: var(--britax-grey) !important;
			color: var(--britax-white) !important;
		}
		#CookiebotWidget-btn-change {
			border-radius: 25px;
			background-color: var(--britax-primary) !important;
			border-color: var(--britax-primary) !important;
			color: var(--britax-white) !important;
		}
	}
	.CookiebotWidget-consent-details {
		button {
			color: var(--britax-primary) !important;
		}
	}
	* {
		color: var(--britax-grey) !important;
	}
	&.CookiebotWidget-open {
		#CookiebotWidget-widgetContent {
			border: solid 1px var(--britax-silver-grey);
		}
	}
}
#CybotCookiebotDialogBodyLevelButtonsSelectPane > div:first-child {
	display: none !important;
}
#CookiebotWidget:not(.CookiebotWidget-open) .CookiebotWidget-logo {
	color: var(--britax-grey);
	font-size: var(--britax-font-size-sm);
	font-weight: var(--britax-font-weight-normal);
	display: none;
	&:hover {
		text-decoration: underline;
	}
	svg {
		opacity: 0;
	}
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
	padding: 10px 20px !important;
	border-radius: 25px;
	width: auto !important;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
	background-color: var(--britax-silver-grey) !important;
	border: 1px solid var(--britax-silver-grey) !important;
	color: var(--britax-primary) !important;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
	background-color: var(--britax-primary) !important;
	border: 1px solid var(--britax-primary) !important;
	color: var(--britax-white) !important;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
	background-color: var(--britax-primary) !important;
	border: 1px solid var(--britax-primary) !important;
	color: var(--britax-white) !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll:hover {
	background-color: var(--britax-grey) !important;
	border: 1px solid var(--britax-grey) !important;
	color: var(--britax-white) !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover {
	background-color: var(--britax-grey) !important;
	border: 1px solid var(--britax-grey) !important;
	color: var(--britax-white) !important;
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover {
	background-color: var(--britax-grey) !important;
	border: 1px solid var(--britax-grey) !important;
	color: var(--britax-white) !important;
}

// Cookie Consent style End
