$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 32px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-items-border-radius: 2px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

//style presets
:root {
  --msv-order-summary-bg: var(--msv-bg-color);
  --msv-order-summary-font-color: var(--msv-font-primary-color);
  --msv-order-summary-font-size: var(--msv-body-font-size-l);
  --msv-order-summary-border: var(--msv-font-primary-color);

  // heading
  --msv-order-summary-heading-font-color: var(--msv-font-primary-color);
  --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.msc-order-summary {
  &-wrapper {
    align-items: center;
    background-color: var(--msv-order-summary-bg);
    display: flex;
    flex: 0 0 400px;
    flex-direction: column;
    margin: 0 auto;
    // padding: $msv-order-summary-padding;
    // padding-top: 20px;
    height: fit-content;
    margin-top: -42px;
    @media screen and (max-width: $britax-container-max-width-m) {
      flex: 0 0 100%;
      margin-top: 0;
    }
    .msc-cart__btn-checkout {
      @include primary-button();
      font-size: var(--britax-line-height-xxxs);
      font-weight: var(--britax-font-weight-above-normal);
      margin-top: 0px;
    }
    .msc-cart__btn-backtoshopping {
      background: transparent;
      border: 0;
      padding: 0;
      font-size: var(--britax-font-size-sm);
      height: auto;
      text-align: center;
      text-decoration: underline;
      border: 0;
      &:hover {
        color: var(--britax-grey);
      }
    }
    .msc-cart__btn-addcarttotemplate {
      @include grey-button();
      font-size: var(--britax-line-height-xxxs);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cart-button-end {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
    }
    .msc-price__actual {
      font-size: var(--britax-body-font-size-xs);
    }
  }

  @media screen and (max-width: $msv-breakpoint-s) {
    margin: unset;
  }

  &__heading {
    font-size: var(--britax-font-size-xm);
    line-height: var(--britax-line-height-m);
    font-weight: var(--britax-font-weight-normal);
    // @include font-content(var(--msv-font-weight-heavy),
    // var(--msv-order-summary-heading-font-size), $msv-line-height-xl);
    // color: var(--msv-order-summary-heading-font-color);
    // font-style: $msv-order-summary-font;
    // padding-bottom: $msv-order-summary-heading-padding-bottom;
    width: 100%;
    margin-bottom: 20px;
  }

  &__items {
    border-radius: $msv-order-summary-items-border-radius;
    border-top: 1px solid var(--msv-cart-border);

    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-order-summary-font-size),
      $msv-line-height-l
    );
    width: 100%;
    padding-top: 15px;

    .msc-promo-code {
      &__group {
        display: flex;
        padding: 5px 15px 5px 5px;
        border: 1px solid var(--britax-primary);
        box-sizing: border-box;
        border-radius: 3px;
        margin-top: 10px;
      }

      &__input-box {
        font-size: var(--britax-body-font-size-xxs);
        flex: auto;
        margin-right: 20px;
        padding-left: 8px;
        height: 40px;
      }
    }
  }

  &__line {
    &-net-price,
    &-tax-amount,
    &-shipping,
    &-loyalty,
    &-gift-card,
    &-other-charges,
    &-total-discounts,
    &-sub-total {
      padding-top: 15px;
      margin-bottom: 0px;
      font-size: var(--britax-body-font-size-xs);
    }
    &-sub-total {
      margin-top: 0;
    }

    &-total {
      // background: linear-gradient(
      //     249.47deg,
      //     rgba(106, 160, 183, 0.1) 13.73%,
      //     rgba(255, 127, 10, 0.1) 110.11%);
      // border-radius: 4px;
      // width: 159px;
      // height: 91px;
      // float: right;
      // display: flex;
      // flex-direction: column-reverse;
      // align-items: center;
      // justify-content: center;
      // margin-top: 25px;
      font-weight: var(--britax-font-weight-700);
      margin-top: 10px;
      padding-top: 10px;
      border-top: solid 1px var(--britax-silver-grey);
      // @media screen and (max-width: $britax-container-max-width-m) {
      //     width: 100%;
      //     margin-top: 10px;
      // }
      .msc-order-summary__value {
        font-size: var(--britax-font-size-xsml);
        font-weight: var(--britax-font-weight-700);
      }
      .msc-order-summary__label {
        font-weight: var(--britax-font-weight-700);
        font-size: var(--britax-font-size-xsml);
      }
    }
  }

  &__value {
    font-weight: var(--britax-font-weight-700);
    float: right;
    text-align: right;
  }
}
.msc-order-summary__label {
  font-size: var(--britax-body-font-size-xs);
}
