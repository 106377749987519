$msv-details-text-margin-right: 5px;
$msv-details-order-information-padding-bottom: 32px;
$msv-details-order-information-padding-top: 8px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-channel-name-margin-bottom: 12px;
$msv-details-order-information-channel-address-margin-bottom: 12px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-group-delivery-padding-bottom: 10px;
$msv-details-sales-status-top-desktop: -144px;
$msv-details-sales-status-top-mobile: -174px;
$msv-details-sales-lines-width: 770px;
$msv-details-sales-lines-margin-right: 120px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-padding: 20px 0;
$msv-details-sale-line-image-size: 147px;
$msv-details-sale-line-image-size-mobile: 80px;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-details-sale-line-buy-again-btn-left: 167px;
$msv-details-sale-line-buy-again-btn-bottom: 0;
$msv-details-sale-line-buy-again-btn-padding-left: 33px;
$msv-details-sale-line-buy-again-btn-padding-right: 33px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-order-summary-payment-menthods-help-margin-top-mobile: 20px;
$msv-order-empty-image-width: 147px;
$msv-order-empty-image-width-mobile: 80px;
$msv-details-sales-line-padding-bottom-mobile: 40px;
$msv-details-sales-line-btn-buy-it-again-margin-left-mobile: 100px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-font-weight: 600;
$msv-details-order-summary-line-spacing: 20px;
$msv-details-order-summary-line-total-amount-margin-top: 20px;
$msv-details-order-summary-line-total-amount-margin-bottom: 20px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-bottom: 28px;
$msv-details-help-heading-margin-bottom: 8px;
$msv-details-help-heading-margin-top: 20px;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 8px;
$msv-details-payment-methods-line-margin-bottom: 20px;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 768px;
$msv-details-footer-block-margin-right: 120px;
$msv-details-footer-block-padding-bottom: 20px;
$msv-details-help-width: 100%;
$msv-details-address-spacing: 20px;
$msv-msc-cart-line-width: 100%;
$msv-smaller-port-m: 320px;
$msv-order-details-order-summary-container-padding: 20px;
$msv-details-address-padding-m: 20px 0;
$msv-details-address-padding: 43px 0 20px 0;
$msv-details-address-icon-padding-right: 5px;
$msv-details-tracking-info-padding-top: 15px;
$msv-text-max-width: 80%;
$msv-order-details-group-heading-icon-padding-right: 10px;
$msv-order-details-group-multiple-heading-padding-bottom: 0;
//style presets
:root {
  --msv-order-details-border: #{$msv-gray-300};

  // heading
  --msv-order-details-heading-font-size: var(--msv-body-font-size-xl);

  // title
  --msv-order-details-title-font-size: var(--msv-body-font-size-l);

  // text
  --msv-order-details-text-font-size: var(--msv-body-font-size-m);

  // secondary button
  --msv-order-details-secondary-btn-bg: var(
    --msv-secondary-button-background-color
  );
  --msv-order-details-secondary-btn-font-color: var(--msv-font-primary-color);
  --msv-order-details-secondary-btn-border: var(--msv-accent-brand-color);

  // link
  --msv-order-buy-again-link-color: var(--msv-font-primary-color);
}

.paymentTitlePrice {
  text-align: right;
  font-weight: var(--britax-font-weight-bold);
}

.customerDetail {
  padding-bottom: 20px;
}
.msc-shipment-claim {
  @include primary-button();
  @include vfi();

  &:not(:disabled) {
    &:hover {
      cursor: pointer;
    }
  }
  &:focus {
    background-color: var(--britax-primary);
  }
}

.ms-order-details {
  margin-top: 30px;
  margin-bottom: 30px;
  .orderdetail-sales-labels {
    display: flex;
    border-bottom: solid 1px var(--britax-silver-grey);
    border-top: solid 1px var(--britax-silver-grey);
    padding: 10px 0;
    margin-top: 10px;
    label {
      width: 17.5%;
      font-weight: 500;
      margin: 0;
      &:first-of-type {
        width: 50%;
        padding-left: 15px;
      }
      &:last-of-type {
        width: 15%;
      }
    }
  }
  &__heading {
    display: none;
  }

  &__order-information {
    padding-bottom: $msv-details-order-information-padding-bottom;
    padding-top: $msv-details-order-information-padding-top;

    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-order-details-text-font-size),
      $msv-line-height-m
    );
    color: var(--britax-primary);

    &-channel-name {
      color: var(--britax-primary);
      display: block;
      font-size: $msv-heading-text-size;
      font-weight: var(--msv-font-weight-heavy);
      line-height: $msv-heading-line-height;
      margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
    }

    &-channel-address {
      display: block;
      font-size: $msv-heading-text-size;
      font-weight: var(--msv-font-weight-heavy);
      line-height: $msv-heading-line-height;
      margin-bottom: $msv-details-order-information-channel-address-margin-bottom;
      margin-top: -$msv-details-order-information-channel-name-margin-bottom;
    }

    &-channel-reference-id,
    &-sales-id,
    &-receipt-id,
    &-receipt-email {
      display: block;
      margin-bottom: 5px;
    }
    &-qr-code {
      display: none;
    }

    &-created-date {
      padding-right: $msv-details-order-information-spacing;
    }

    &-count {
      display: inline-block;
      padding-left: $msv-details-order-information-spacing;
      padding-right: $msv-details-order-information-spacing;
      border-right: 1px solid $msv-gray-900;
    }

    &-amount {
      padding-left: $msv-details-order-information-spacing;
    }

    @media screen and (max-width: $msv-smaller-port-m) {
      &-amount {
        padding-left: 0;
      }
    }
    .orderInformationClass {
      .date {
        color: var(--britax-primary);
        margin-bottom: 5px;
        display: block;
        word-break: break-all;
      }
    }
    .amount {
      text-align: right;
      h4 {
        font-weight: var(--britax-font-weight-bold-full);
        color: var(--britax-primary);
      }
      .status {
        font-weight: var(--britax-font-weight-normal);
        color: var(--britax-white);
        display: inline-block;
        padding: 4px 15px 5px;
        border-radius: 25px;
        margin-bottom: 10px;
        &.processing {
          background-color: rgba(255, 127, 10, 0.11);
          color: var(--britax-pending-color);
        }
        &.pickedUp,
        &.shipped,
        &.readyForPickup,
        &.invoiced {
          background-color: var(--britax-success-bg-color);
          color: var(--britax-success-font-color);
        }
        &.canceled {
          background-color: var(--britax-danger-bg-color);
          color: var(--britax-danger-font-color);
        }
      }
    }
  }

  &__btn-keep-shopping {
    margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;

    @include primary-button();
  }

  &__sales-lines {
    flex: 3;

    &:first-child {
      border-top: none;
    }

    .ms-order-details__tracking-info {
      &:not(:first-child) {
        padding-top: $msv-details-tracking-info-padding-top;
      }
    }
    .ms-order-details__sales-status {
      display: none;
    }
  }

  &__groups {
    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-order-details-text-font-size),
      $msv-line-height-m
    );
    margin-bottom: 30px;
    .ms-order-details__group-delivery {
      display: none;
    }
  }

  &__group {
    flex-wrap: wrap;
    padding-top: $msv-details-group-padding-top;

    &-delivery {
      padding-bottom: $msv-details-group-delivery-padding-bottom;
      overflow: hidden;

      &-heading {
        @include font-content(
          var(--msv-font-weight-bold),
          var(--msv-order-details-heading-font-size),
          $msv-line-height-xl
        );
        color: var(--britax-grey);
        margin-right: $msv-details-text-margin-right;

        .ms-order-details__sales-status {
          color: var(--britax-grey);
          float: left;
          top: 0;
        }

        .pickUp-icon {
          @include add-icon($msv-cube, before);
          @include font-content(
            var(--msv-font-weight-bold),
            var(--msv-order-details-title-font-size),
            $msv-line-height-l
          );

          &::before {
            padding-right: $msv-order-details-group-heading-icon-padding-right;
          }
        }

        .ship-icon {
          @include add-icon($msv-DeliveryTruck, before);
          @include font-content(
            var(--msv-font-weight-bold),
            var(--msv-order-details-title-font-size),
            $msv-line-height-l
          );

          &::before {
            padding-right: $msv-order-details-group-heading-icon-padding-right;
          }
        }

        .email-icon {
          @include add-icon($msv-DeliveryTruck, before);
          @include font-content(
            var(--msv-font-weight-bold),
            var(--msv-order-details-title-font-size),
            $msv-line-height-l
          );

          &::before {
            padding-right: $msv-order-details-group-heading-icon-padding-right;
          }
        }
      }

      &-total-items {
        @include font-content(
          var(--msv-font-weight-normal),
          var(--msv-order-details-text-font-size),
          $msv-line-height-m
        );
        color: var(--britax-grey);
        margin-right: $msv-details-text-margin-right;
      }
    }

    &:first-child {
      border-top: none;

      .ms-order-details__sales-lines {
        border-top: none;
      }
    }
  }

  .multiple-pickUp {
    padding-bottom: $msv-order-details-group-multiple-heading-padding-bottom;
  }

  &__tracking-link {
    text-decoration: underline;
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  &__tracking-info {
    color: var(--britax-grey);
    font-weight: var(--britax-font-weight-bold);
    float: right;
    a {
      color: var(--britax-grey);
    }
  }

  &__sales-line {
    padding: 0 0 20px;
    border-bottom: 1px solid var(--msv-order-details-border);
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    &:last-child {
      border-bottom: 0;
    }

    .msc-cart-line {
      position: relative;
      display: flex;
      .mobile-label {
        display: none;
      }
      &__product-image {
        width: 50%;
        flex-direction: row;
        margin: 0;
        display: flex;
        img {
          width: 90px;
        }
        .msc-empty_image {
          @include image-placeholder($cart-empty-image-width);
        }
        .product-info {
          margin-left: 10px;
          width: calc(100% - 100px);
          .msc-cart-line__product-variant-color {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            padding-bottom: 2px;
          }
          a.msc-cart-line__product-title {
            width: 100%;
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-height: 55px;
            min-height: 30px;
          }
        }
      }
      .small-blks {
        width: 17.5%;

        &:last-of-type {
          width: 15%;
        }
        .quantity {
          border: 1px solid var(--msv-cart-border);
          border-radius: 3px;
          padding: 5px 10px;
          display: inline-flex;
          align-items: center;
          height: 40px;
          @media screen and (max-width: $britax-container-max-width-s) {
            height: 32px;
          }

          .decrement {
            color: var(--britax-white);
            font-size: var(--britax-font-size-xsml);
            background-color: var(--britax-primary);
            border: 1px solid var(--britax-primary);
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: relative;
            padding: 9px;
            @media screen and (max-width: $britax-container-max-width-s) {
              padding: 0;
              line-height: 14px;
            }
            &::after {
              content: "-";
              position: absolute;
              top: -3px;
            }
          }
          .increment {
            color: var(--britax-white);
            font-size: var(--britax-font-size-xsml);
            background-color: var(--britax-primary);
            border: 1px solid var(--britax-primary);
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: relative;
            padding: 9px;
            @media screen and (max-width: $britax-container-max-width-s) {
              padding: 0;
              line-height: 14px;
            }
            &::after {
              content: "+";
              position: absolute;
              top: -3px;
            }
          }
          &-input {
            border: none;
            width: 50px;
          }
        }
        span {
          width: auto;
          margin: 0;
        }
        .msc-cart-line__quantity {
          // display: none;
          .quantity-label {
            display: none;
          }
        }
        .msc-price__actual {
          font-size: var(--britax-font-size-sm);
          font-weight: var(--britax-font-weight-bold);
        }
      }
    }

    .status {
      font-weight: var(--britax-font-weight-normal);
      color: var(--britax-white);
      display: inline-block;
      padding: 4px 15px 5px;
      border-radius: 25px;
      margin-left: 100px;
      @media screen and (max-width: $britax-container-max-width-s) {
        margin-left: 60px;
        margin-top: 10px;
      }
      &.processing {
        background-color: rgba(255, 127, 10, 0.11);
        color: var(--britax-pending-color);
      }
      &.pickedUp,
      &.shipped,
      &.readyForPickup,
      &.invoiced {
        background-color: var(--britax-success-bg-color);
        color: var(--britax-success-font-color);
      }
      &.canceled {
        background-color: var(--britax-danger-bg-color);
        color: var(--britax-danger-font-color);
      }
    }
    .sku-number {
      margin-left: 100px;
      font-size: var(--britax-body-font-size-xs);
      @media screen and (max-width: $britax-container-max-width-s) {
        margin-left: 60px;
        margin-top: 10px;
      }
    }

    &-btn-buy-it-again {
      @include primary-button();
      padding: 7px 20px 8px;
      font-size: var(--britax-body-font-size-s);
      display: block;
      text-align: center;

      &.msc-btn {
        @include primary-button();
        font-size: var(--britax-body-font-size-s);
        min-width: 140px;
        display: block;
        text-align: center;
      }
    }

    &-btn-buy-it-again-link {
      @include button-link(var(--msv-order-buy-again-link-color));
      @include vfi();
      display: initial;
      left: $msv-details-sale-line-buy-again-btn-left;
      padding: 0;
      position: relative;
      width: $msv-details-sale-line-buy-again-btn-width;
      text-decoration: underline;

      &::after {
        content: "";
      }
    }
    .buyAgainButton {
      position: absolute;
      bottom: 25px;
      right: 0;
      .ms-order-details__sales-line-btn-buy-it-again.msc-btn {
        position: initial;
      }
      @media screen and (max-width: $britax-container-max-width-s) {
        position: relative;
        bottom: -10px;
        margin-left: 60px;
      }
    }
    .claim-form-link {
      margin-left: 100px;
      color: var(--britax-primary);
      text-decoration: underline;
      &:hover {
        color: var(--britax-grey);
      }
      @media screen and (max-width: $britax-container-max-width-s) {
        margin-left: 60px;
      }
    }
    .tracking-id {
      max-width: calc(100% - 260px);
      margin-left: 100px;
      margin-top: 10px;
      @media screen and (max-width: $britax-container-max-width-s) {
        max-width: 100%;
        margin-left: 60px;
      }
      a {
        color: var(--britax-primary);
        margin-left: 5px;
        &:hover {
          color: var(--britax-grey);
        }
      }
    }
  }

  &__order-summary {
    font-size: var(--britax-body-font-size-xs);
    flex: 4;

    &-heading {
      display: none;
    }

    &-line-subtotal,
    &-line-shipping,
    &-line-tax-amount {
      margin-bottom: $msv-details-order-summary-line-spacing;
    }

    &-line-total-amount {
      padding-top: 15px;
      border-top: solid 1px var(--britax-silver-grey);
      .ms-order-details__order-summary-label {
        font-weight: var(--msv-font-weight-bold);
        font-size: var(--britax-font-size-xsml);
      }

      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      .msc-price__actual {
        font-size: var(--britax-font-size-xsml);
        line-height: $msv-line-height-xl;
      }
    }

    &-line-earned-points {
      border-top: 1px solid $msv-divider-color;
      padding-top: $msv-details-order-summary-line-earned-points-padding-top;

      .ms-order-details__order-summary-label {
        font-weight: var(--msv-font-weight-bold);
      }
    }

    &-price {
      float: right;
      font-weight: var(--msv-font-weight-bold);
    }
    .msc-price__actual {
      font-size: var(--britax-body-font-size-xs);
    }
  }

  &__help {
    color: var(--britax-grey);

    &-title {
      font-weight: var(--britax-font-weight-bold);
      font-size: var(--britax-font-size-xm);
    }

    &-label {
      display: none;
    }

    &-content-number {
      color: var(--britax-grey);
      font-weight: var(--britax-font-weight-bold);
      font-size: var(--britax-font-size-xm);
    }
  }

  &__order-summary-container {
    color: var(--britax-grey);
    padding: $msv-order-details-order-summary-container-padding;
  }

  &__payment-methods {
    color: var(--britax-primary);
    flex: 1;

    &-title {
      font-weight: var(--britax-font-weight-bold);
      border-bottom: solid 1px var(--britax-silver-grey);
      margin-bottom: 15px;
      padding-bottom: 10px;
      font-size: var(--britax-font-size-xm);
    }

    &-line {
      margin-bottom: $msv-details-payment-methods-line-margin-bottom;
    }

    &-card-amount::before,
    &-loyalty-amount::before,
    &-cash-amount::before,
    &-gift-card-amount::before,
    &-customer-account-amount::before {
      content: " ";
    }
  }

  &__address {
    color: var(--britax-primary);
    display: block;
    padding: $msv-details-address-padding-m;
    flex: 1;

    &-header {
      font-weight: bold;
      padding-bottom: 8px;
    }

    &-city,
    &-threeletterisoregionname {
      &::before {
        content: "\A";
        white-space: pre;
      }
    }

    &-name,
    &-phone {
      display: block;

      &-label {
        display: none;
      }

      &-icon {
        padding-right: $msv-details-address-icon-padding-right;

        @include add-icon($msv-Phone);
      }
    }
  }

  &__delivery-container {
    flex: 1;
  }

  &__pickup-date-timeslot {
    padding-bottom: 20px;
    padding-top: 20px;

    &-title {
      font-weight: var(--msv-font-weight-bold);
      padding-bottom: 8px;
    }
  }

  &__alert {
    display: block;
    margin-bottom: $msv-order-details-alert-margin-bottom;
    margin-top: $msv-order-details-alert-margin-top;
  }

  .msc-empty_image {
    @include image-placeholder($msv-order-empty-image-width);
  }

  @media screen and (min-width: $msv-breakpoint-l) {
    &__order-summary-container {
      display: flex;
      justify-content: space-between;
    }

    &__sales-lines_container {
      display: flex;
      justify-content: space-between;
    }

    &__group-deliveryType-ship {
      &.salesStatus-shipped {
        .ms-order-details__address {
          padding: $msv-details-address-padding;
        }
      }
    }
  }

  @media screen and (max-width: $msv-breakpoint-l) {
    &__sales-lines {
      width: 100%;
    }

    &__sales-line .msc-cart-line {
      width: 100%;
    }
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__group {
      display: block;

      &_delivery {
        padding-right: 0;
      }
    }

    &__sales-lines {
      width: 100%;
    }

    &__sales-status {
      top: $msv-details-sales-status-top-mobile;
    }

    &__order-summary,
    &__payment-methods,
    &__help {
      float: none;
      // margin-top: $msv-details-order-summary-payment-menthods-help-margin-top-mobile;
      padding-right: 0;
      width: 100%;
    }

    &__btn-keep-shopping {
      width: 100%;
    }

    &__sales-line {
      padding-bottom: $msv-details-sales-line-padding-bottom-mobile;

      &-btn-buy-it-again {
        display: block;
        left: 0;
        margin-left: $msv-details-sales-line-btn-buy-it-again-margin-left-mobile;
        top: 0;
        width: auto;
      }
    }

    .msc-cart-line {
      width: 100%;

      &__product-price {
        position: relative;
        text-align: left;
        margin-left: 0;
        width: 0;
      }

      &__product-image img {
        max-width: $msv-details-sale-line-image-size-mobile;
        width: $msv-details-sale-line-image-size-mobile;
        height: $msv-details-sale-line-image-size-mobile;
      }
    }

    .msc-empty_image {
      @include image-placeholder($msv-order-empty-image-width-mobile);
    }
  }
  .order-details {
    overflow: hidden;
    &__order-row {
      @media screen and (min-width: $britax-container-min-width-m) {
        float: left;
        width: calc(70% - 5%);
      }
    }
    &__sidebar {
      @media screen and (min-width: $britax-container-min-width-m) {
        float: right;
        width: 30%;
      }
      &__help-section {
        background-color: var(--britax-shade-grey);
        padding: 15px 25px;
        border-radius: 4px;
        margin-bottom: $msv-details-help-margin-bottom;
        .call-centers {
          margin: 15px 0 0;
        }
      }
      &__summary {
        overflow: hidden;
      }
      &__payment {
        margin-top: 28px;
        margin-bottom: 28px;
      }
      @media screen and (max-width: $britax-container-max-width-m) {
        margin-top: 30px;
      }
    }
  }
}
@media (max-width: $britax-container-max-width-s) {
  .ms-order-details {
    .orderdetail-sales-labels {
      display: none;
    }
    .msc-cart-line {
      flex-wrap: wrap;
      .msc-cart-line__product-image {
        img {
          width: 50px;
          height: auto;
        }
        .product-info {
          width: calc(100% - 60px);
          a.msc-cart-line__product-title {
            min-height: initial;
            max-height: initial;
            margin-top: 5px;
          }
        }
      }
      .small-blks {
        margin-left: 60px;
        &.product-quantity {
          .mobile-label {
            float: left;
            margin-top: 4px;
          }
        }
      }
      .msc-cart-line__product-image,
      .small-blks {
        width: 100%;
        margin-bottom: 5px;
        &:last-of-type {
          width: 100%;
        }
        .mobile-label {
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        .msc-cart-line__quantity {
          .quantity-label {
            display: none;
          }
        }
      }
    }
    .buyAgainButton {
      a {
        min-width: initial;
        margin-left: 0;
        display: inline-block;
        padding: 10px 25px;
        height: initial;
        line-height: initial;
      }
    }
    .ms-order-details__order-information {
      padding-bottom: 20px;
      .amount {
        margin-top: 10px;
        text-align: left;
      }
    }
    .ms-order-details__tracking-info {
      float: none;
      display: block;
    }
  }
}
