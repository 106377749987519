$carousel-padding-left: 20px;
$thumbnail-height: 100px;
$thumbnail-flipper-height: 30px;
$carousel-tooltip-padding: 4px;
$carousel-indicators-border: 1px;
$carousel-thumb-image-margin: 8px;
$msc-carousel-min-height: 600px;
//style presets
:root {
  --msv-media-gallery-bg: #{$msv-black};

  // Flipper
  --msv-media-gallery-thumbnail-flipper-bg: var(--msv-bg-color);
  --msv-media-gallery-thumbnail-flipper-border: var(--msv-border-color);
  --msv-media-gallery-thumbnail-flipper-font-color: #{$msv-gray-500};

  // img
  --msv-media-gallery-active-img-border: #{$msv-nobel};
  --msv-media-gallery-img-border: #{$msv-gray-300};
}

.ms-media-gallery {
  display: block;

  &.vertical {
    display: inline-flex;
    .msc-carousel__item {
      border: 1px solid var(--msv-media-gallery-img-border);
    }
    .msc-ss-carousel-slide {
      display: block;
    }

    .ms-media-gallery__thumbnail-item:not(:last-child) {
      margin-bottom: $carousel-thumb-image-margin;
      margin-right: 0;
    }
    .ms-media-gallery__thumbnail-item.ms-media-gallery__thumbnail-item-active {
      img {
        border: 1px solid var(--msv-media-gallery-active-img-border);
      }
    }
    .ms-media-gallery__thumbnail-item {
      img {
        border: 1px solid var(--msv-media-gallery-img-border);
      }
    }

    .ms-media-gallery__carousel {
      margin-bottom: 0;
    }
  }

  .ms-media-gallery__item {
    @media (max-width: $msv-breakpoint-m) {
      cursor: context-menu;
    }
  }

  .ms-media-gallery__thumbnails-container {
    display: none;
    order: 0;
    flex-grow: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .ms-media-gallery__thumbnail-item:not(:last-child) {
    margin-right: $carousel-thumb-image-margin;
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .ms-media-gallery__carousel {
    margin-bottom: $carousel-padding-left;
    align-items: start;
    order: 1;
    flex-grow: 1;
    min-height: 620px;
    @media (max-width: $britax-container-max-width-xl) {
      min-height: 478px;
    }
    @media (max-width: $britax-container-max-width-l) {
      min-height: 434px;
    }
    @media (max-width: $britax-container-max-width-m) {
      min-height: 400px;
    }
    @media (max-width: $britax-container-max-width-s) {
      min-height: 434px;
    }
    @media (max-width: 710px) {
      min-height: 410px;
    }
    @media (max-width: 670px) {
      min-height: 520px;
    }
    @media (max-width: 630px) {
      min-height: 470px;
    }
    @media (max-width: 580px) {
      min-height: 410px;
    }
    @media (max-width: 400px) {
      min-height: 330px;
    }
    picture {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .msc-ss-carousel-slide {
    display: flex;
    padding: 0 10px;
  }

  .msc-flipper {
    background: var(--msv-media-gallery-thumbnail-flipper-bg);
    border: 1px solid var(--msv-media-gallery-thumbnail-flipper-border);
    border-radius: 0;
    color: var(--msv-media-gallery-thumbnail-flipper-font-color);
    flex: 0 1 $thumbnail-flipper-height;
    height: $thumbnail-flipper-height;
    left: auto;
    position: absolute;
    top: auto;
    transform: none;
    width: 100%;
  }

  .msc-ss-carousel__flipper {
    &.disabled {
      display: none;
    }
  }

  .msc-ss-carousel-vert__flipper {
    &.disabled {
      display: none;
    }
  }

  .msc-carousel__control__prev,
  .msc-carousel__control__next {
    background: var(--msv-media-gallery-thumbnail-flipper-bg);
    border: none;
    color: var(--msv-media-gallery-thumbnail-flipper-font-color);
  }

  .msc-ss-carousel-vert__flipper--next {
    bottom: 0;
  }

  .msc-tooltip-inner {
    background-color: var(--msv-media-gallery-bg);
    color: var(--msv-font-secondary-color);
    padding: $carousel-tooltip-padding $carousel-tooltip-padding;
  }

  .msc-carousel__indicators li {
    &.active {
      background-color: var(--msv-media-gallery-bg);
    }
    border: $carousel-indicators-border solid $msv-black;
    background-color: var(--msv-bg-color);
  }
}

@media (min-width: $msv-breakpoint-l) {
  .ms-media-gallery {
    &.vertical {
      padding-left: 0;

      .ms-media-gallery__carousel {
        margin-left: $carousel-padding-left;
      }
    }

    .msc-carousel__indicators {
      display: none;
    }

    .msc-carousel__control__prev {
      font-size: 0;
      line-height: 0;
      position: absolute;
      margin-left: -25px;
      background: none;
      color: var(--britax-primary);
      &:hover {
        color: var(--britax-grey);
      }
    }

    .msc-carousel__control__next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      margin-right: -20px;
      background: none;
      color: var(--britax-primary);
      &:hover {
        color: var(--britax-grey);
      }
    }

    .ms-media-gallery__thumbnails-container {
      display: flex;
    }

    .ms-media-gallery__thumbnail-item {
      height: $thumbnail-height;
      .msc-empty_image {
        background-size: cover;
      }
    }
  }
}

//mobile vp

@media (max-width: $msv-breakpoint-m) {
  .ms-media-gallery {
    width: 100%;
    height: auto;
    display: inline-flex;
    flex-wrap: nowrap;
    padding-top: 100%;
    max-height: 542px;

    .ms-media-gallery__carousel {
      width: 100%;
      object-position: top;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;

      .msc-carousel__inner {
        .msc-carousel__item {
          max-height: 542px;

          .ms-inline-zoom {
            float: none;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.ms-containerZoom {
  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;

    img {
      cursor: zoom-in;
    }
  }

  &__result {
    overflow: hidden;
    position: fixed;
    background-color: #ffffff;
    background-repeat: no-repeat;
    z-index: 1140;
    box-shadow: rgba(0, 0, 0, 0.25) 0 6px 8px 2px;
    border: 1px solid rgb(148, 148, 148);
    width: 580px;
    height: 772px;
    right: 10px;

    @media (max-width: $msv-breakpoint-m) {
      display: none;
    }

    @media (max-width: $msv-breakpoint-xl) {
      width: 459px;
      height: 400px;
    }
  }

  &__image {
    border: none;
    left: 0;
    max-width: none;
    max-height: none;
    position: absolute;
    top: 0;
  }

  &__zoom-lens {
    position: absolute;
    cursor: zoom-out;
    width: 200px;
    height: 180px;
    background-position: 0 0;
    top: -200px;
    right: -200px;
    float: right;
    overflow: hidden;
    zoom: 1;
    background-color: $msv-white;
    border: 1px solid #000000;
    opacity: 0;

    &__opacity {
      opacity: 0.4;
    }

    @media (max-width: $msv-breakpoint-m) {
      display: none;
    }
  }
}

.ms-inline-zoom {
  float: left;
  overflow: hidden;
  display: block;
  position: relative;

  img {
    cursor: zoom-in;
  }

  &.zoomed {
    img {
      cursor: zoom-out;
      background-color: var(--britax-bg-grey);
    }
  }

  &__zoomedImg {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border: none;
    max-width: none;
    max-height: none;
    width: 0;
    height: 0;

    @media (max-width: $msv-breakpoint-m) {
      display: none;
      opacity: 0;
    }
  }
}

.ms-fullscreen-section {
  position: relative;
  display: inline-block;

  img {
    vertical-align: top;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    /* for dark overlay on top of the image */
    background: transparent;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;

    &:hover {
      opacity: 0.9;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &__magnifying-glass-icon {
    color: $msv-white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    padding: 20px;
    border: 1px solid #ffffff;
    line-height: 1;

    @include add-icon($msv-Add, after);

    &:hover {
      background: rgba(0, 0, 0, 0.8);
      color: $msv-white;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ms-fullscreen-section {
    display: block;
  }
}

.msc-modal {
  .msc-fullview-modal-body {
    display: flex;
    justify-content: center;

    @media (min-width: $msv-breakpoint-m) {
      min-height: $msc-carousel-min-height;
    }

    .msc-carousel {
      align-items: unset;
      position: unset;
      width: 100%;

      &__inner {
        display: flex;
        justify-content: space-around;
      }

      &__item {
        display: flex;
        justify-content: center;
        margin-right: 0;

        &:not(.active) {
          display: none;
        }

        img {
          display: inline;
        }

        .ms-inline-zoom {
          float: unset;
          position: unset;

          .ms-inline-zoom__zoomedImg {
            max-height: unset;
          }

          &.zoomed img {
            &:not(.ms-inline-zoom__zoomedImg) {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}
