$msc-account-welcome-title-margin-bottom: 40px;
$msc-account-welcome-title-margin-top: 46px;
$msc-account-welcome-title-border-bottom-color: $msv-gray-500;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-welcome-title-links-padding-top: 12px;
$msc-account-tile-heading-icon-padding: 5px;
$msc-generic-tile-heading-margin-bottom: 0.5rem;
$msc-link-padding-right: 12px;

$britax-image-path: "../../../";

// style presets
:root {
  --msv-account-landing-title-font-color: var(--msv-font-primary-color);

  // title
  --msv-account-landing-title-font-size: var(--msv-body-font-size-xl);
  --msv-account-landing-title-desktop-font-size: var(--msv-body-font-size-xxl);
  --britax-landing-title-font-size: var(--britax-body-font-size-xl);
  --britax-landing-title-desktop-font-size: var(--britax-body-font-size-xxl);

  // tiles
  --msv-account-landing-tile-heading-size: var(--msv-body-font-size-l);
  --msv-account-landing-tile-heading-color: var(--msv-font-primary-color);
  --msv-account-landing-tile-text-size: var(--msv-body-font-size-m);
  --msv-account-landing-tile-text-color: var(--msv-font-primary-color);

  // link
  --msv-account-landing-link-color: #{$msv-link-color};
  --msv-account-landing-link-size: var(--msv-body-font-size-m);
}

.ms-account {
  &-welcome-tile {
    border: solid 1px var(--britax-silver-grey);
    border-radius: 15px;
    padding: 25px 50px;
    background: rgb(241, 241, 241);
    background: linear-gradient(
      90deg,
      rgba(241, 241, 241, 1) 0%,
      rgba(255, 255, 255, 1) 52%
    );
    align-items: center;
    color: var(--britax-primary);
    justify-content: space-between;
    display: flex;
    margin-top: $msc-account-welcome-title-margin-top;
    @media screen and (max-width: $britax-container-max-width-l) {
      padding: 25px 30px;
    }
    @media (max-width: $britax-container-min-width-m) {
      display: block;
    }

    &__heading {
      @include britax-font-content(
        var(--britax-font-weight-normal),
        var(--britax-landing-title-font-size),
        $britax-line-height-xl
      );
      @media (max-width: $britax-breakpoint-mbl-sm) {
        font-size: var(--britax-landing-title-font-size);
      }
    }

    @media (min-width: $msv-breakpoint-m) {
      &__heading {
        @include britax-font-content(
          var(--britax-font-weight-normal),
          var(--msv-account-landing-title-desktop-font-size),
          $msv-line-height-xxl
        );
      }
    }
    .donut-chart {
      position: relative;
      max-width: 200px;
      @media screen and (max-width: $britax-container-max-width-l) {
        max-width: 180px;
      }
      @media screen and (max-width: $britax-container-max-width-m) {
        max-width: 200px;
      }
      @media (max-width: $britax-breakpoint-mbl-sm) {
        max-width: 160px;
      }
      .balance {
        position: absolute;
        top: calc(100% - 59%);
        left: 50%;
        width: 100px;
        margin-left: -50px;
        display: block;
        text-align: center;
        h2 {
          font-size: var(--britax-font-size-xxml);
          font-family: $britax-primary-font-family;
          font-weight: var(--britax-font-weight-bold-full);
          color: var(--britax-primary);
          line-height: var(--britax-line-height-sml);
          margin-bottom: 5px;
          @media (max-width: $britax-breakpoint-mbl-sm) {
            font-size: var(--britax-font-size-sm);
            line-height: $britax-line-height-s;
          }
        }
        h6 {
          font-size: var(--britax-body-font-size-xs);
          font-family: $britax-primary-font-family;
          color: var(--britax-primary);
          font-weight: var(--britax-font-weight-normal);
          line-height: var(--britax-line-height-xxxs);
          @media (max-width: $britax-breakpoint-mbl-sm) {
            font-size: var(--britax-body-font-size-xs);
            line-height: $britax-line-height-s;
          }
        }
        &.red {
          h2,
          h6 {
            color: var(--britax-primary);
          }
        }
      }
    }

    .spending-limit {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 30px;
      h4 {
        @include britax-font-content(
          var(--britax-font-weight-above-normal),
          var(--britax-font-size-xxml),
          $britax-line-height-sml
        );
        color: var(--britax-primary);
        @media (max-width: $britax-breakpoint-mbl-sm) {
          font-size: var(--britax-font-size-xm);
        }
      }
      h2 {
        @include britax-font-content(
          var(--britax-font-weight-700),
          var(--britax-body-font-size-ml),
          $britax-line-height-xml
        );
        color: var(--britax-primary);
        @media (max-width: $britax-breakpoint-mbl-sm) {
          font-size: var(--britax-font-size-xxml);
          line-height: $britax-line-height-sml;
        }
      }
    }

    .d-flex {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--britax-border-tile);
        top: 25px;
        left: -72px;
        max-height: 80%;
        @media screen and (max-width: $britax-container-max-width-l) {
          left: -32px;
        }
        @media (max-width: $britax-container-min-width-m) {
          display: none;
        }
      }
    }
  }

  &-generic-tile {
    &__link {
      @include vfi();
      color: var(--msv-account-landing-link-color);
    }

    &.order-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-DeliveryTruck, before);

        &::before {
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }

    &.profile-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-Contact, before);

        &::before {
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }
  }

  &-wishlist-tile__heading {
    @include add-icon($msv-HeartFill, before);

    &::before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-order-templates-tile__heading {
    @include add-icon($msv-file, before);

    &::before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-address-tile__heading {
    @include add-icon($msv-MapPin, before);

    &::before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-organization-users-tile__heading {
    @include add-icon($msv-OrganizationUsers, before);

    &::before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-customer-balance-tile__heading {
    @include add-icon($britax-icon-account, before);

    &::before {
      padding-right: $msc-account-tile-heading-icon-padding;
      display: block;
      font-family: "britax";
      color: var(--britax-purple);
      font-weight: normal;
      text-rendering: auto;
      border-left: 5px solid var(--britax-purple);
      padding-left: 5px;
      margin-bottom: 30px;
      font-size: var(--britax-font-size-xml);
      @media (max-width: $britax-breakpoint-mbl-sm) {
        font-size: var(--britax-body-font-size-ml);
      }
    }
  }

  &-invoice-tile__heading {
    @include add-icon($britax-icon-invoices, before);
    font-size: var(--britax-font-size-xm);
    font-weight: var(--britax-font-weight-above-normal);
    color: var(--britax-grey-600);
    font-family: $britax-primary-font-family;
    margin-bottom: 15px;
    @media (max-width: $britax-breakpoint-mbl-sm) {
      font-size: var(--britax-body-font-size-xs);
    }

    &::before {
      padding-right: $msc-account-tile-heading-icon-padding;
      display: block;
      font-family: "britax";
      color: var(--britax-primary);
      font-weight: normal;
      text-rendering: auto;
      border-left: 5px solid var(--britax-primary);
      padding-left: 5px;
      margin-bottom: 30px;
      font-size: var(--britax-font-size-xml);
      @media (max-width: $britax-breakpoint-mbl-sm) {
        font-size: var(--britax-body-font-size-ml);
      }
    }
  }

  &-generic-tile,
  &-wishlist-tile,
  &-order-templates-tile,
  &-address-tile,
  &-loyalty-tile,
  &-organization-users-tile {
    margin-bottom: $msc-account-welcome-title-margin-bottom;

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &__heading {
      @include font-content(
        var(--msv-font-weight-bold),
        var(--msv-account-landing-tile-heading-size),
        $msv-line-height-l
      );
      color: var(--msv-account-landing-tile-heading-color);
      margin-bottom: $msc-generic-tile-heading-margin-bottom;
    }

    &__links {
      @include font-content-underline(
        var(--msv-font-weight-normal),
        var(--msv-account-landing-link-size),
        $msv-line-height-m
      );
      padding-top: $msc-account-welcome-title-links-padding-top;
    }

    &__link {
      @include vfi();
      color: var(--msv-account-landing-link-color);
      padding-right: $msc-link-padding-right;
    }

    &__link-button {
      @include vfi();
      color: var(--msv-account-landing-link-color);
      cursor: pointer;
    }

    &__description {
      @include font-content(
        var(--msv-font-weight-normal),
        var(--msv-account-landing-tile-text-size),
        $msv-line-height-m
      );
      color: var(--msv-account-landing-tile-text-color);
    }

    &__value {
      @include font-content(
        var(--msv-font-weight-bold),
        var(--msv-account-landing-tile-text-size),
        $msv-line-height-m
      );
      color: var(--msv-account-landing-tile-text-color);

      &::before,
      &::after {
        content: " ";
      }
    }
  }

  &-Welcome-wrapper {
    @media screen and (max-width: $britax-container-max-width-m) {
      margin-bottom: 15px;
    }
    h2 {
      color: var(--britax-primary);
      font-weight: var(--britax-font-weight-above-normal);
      font-size: var(--britax-body-font-size-xxl);
      text-transform: capitalize;
      @media screen and (max-width: $britax-container-max-width-l) {
        font-size: var(--britax-font-size-xml);
      }
    }
  }
}

.graph-section {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  @media (max-width: $britax-container-min-width-s) {
    min-height: 550px;
  }

  @media (max-width: $britax-breakpoint-mbl-sm) {
    min-height: auto;
  }

  > .row {
    display: flex;
    flex-flow: row;
    @media (max-width: $britax-container-max-width-m) {
      display: block;
      overflow: hidden;
    }
    > .col-12 {
      flex: 0 0 calc(20% - 15px);
      max-width: calc(20% - 15px);
      box-shadow: 0px 2px 10px var(--britax-silver-grey);
      padding: 15px;
      border-radius: 5px;
      margin: 30px 10px 20px 10px;
      @media (max-width: $britax-container-min-width-m) {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        float: left;
      }
      &:first-child {
        flex: 0 0 60%;
        max-width: 60%;
        box-shadow: 0 0;
        padding: 0;
        border-radius: 0;
        margin: 0;
        @media (max-width: $britax-container-min-width-m) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .ms-account-customer-balance-tile,
      .ms-account-invoice-tile {
        min-height: 200px;
        h4 {
          font-weight: var(--britax-font-weight-bold-full);
          font-size: var(--britax-font-size-sml);
          color: var(--britax-primary);
          font-family: $britax-primary-font-family;
          margin-bottom: 45px;
          padding-top: 5px;
          @media (max-width: $britax-container-min-width-m) {
            font-size: var(--britax-font-size-xsml);
          }
          @media (max-width: $britax-breakpoint-mbl-sm) {
            font-size: var(--britax-font-size-xsml);
            margin-bottom: 30px;
          }
        }
        &__heading {
          font-size: var(--britax-font-size-xm);
          font-weight: var(--britax-font-weight-above-normal);
          color: var(--britax-primary);
          font-family: $britax-primary-font-family;
          margin-bottom: 15px;
          height: 95px;
          @media (max-width: $britax-container-min-width-m) {
            font-size: var(--britax-font-size-m);
          }
          @media (max-width: $britax-breakpoint-mbl-sm) {
            font-size: var(--britax-body-font-size-xs);
          }
        }
        &__links {
          border-top: 1px solid var(--britax-border);
          padding-top: 15px;
          position: absolute;
          width: calc(100% - 32px);
          bottom: 15px;
          .ms-account-customer-balance-tile__link {
            @include add-icon($britax-icon-arrow, after);
            color: var(--britax-primary);
            display: block;
            text-align: right;
            border: none;
            line-height: var(--britax-line-height-sml);
            font-family: $britax-primary-font-family;
            font-weight: var(--britax-font-weight-above-normal);
            div {
              display: inline-block;
              vertical-align: middle;
            }
            &::after {
              font-family: "britax";
              color: var(--britax-primary);
              font-weight: normal;
              text-rendering: auto;
              transform: rotate(270deg);
              font-size: 7px;
              margin-left: 5px;
              display: inline-block;
              vertical-align: middle;
            }
          }
          .ms-account-invoice-tile__link {
            @include add-icon($britax-icon-arrow, after);
            color: var(--britax-primary);
            display: block;
            text-align: right;
            border: none;
            line-height: var(--britax-line-height-sml);
            font-family: $britax-primary-font-family;
            font-weight: var(--britax-font-weight-above-normal);
            div {
              display: inline-block;
              vertical-align: middle;
            }
            &::after {
              font-family: "britax";
              color: var(--britax-primary);
              font-weight: normal;
              text-rendering: auto;
              transform: rotate(270deg);
              font-size: 7px;
              margin-left: 5px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        :hover * {
          color: var(--britax-grey);
          &::after {
            color: var(--britax-grey);
          }
        }
      }
    }
  }
  .linechart-section {
    position: relative;
    .graph-label {
      color: var(--britax-primary);
      position: absolute;
      top: 60px;
      left: 70px;
      padding: 5px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  &.non-admin-user {
    .col-12 {
      &:first-child {
        flex: 0 0 80%;
        max-width: 80%;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
}
.non-admin-user {
  > .row {
    > .col-12 {
      &:first-child {
        flex: 0 0 70%;
        max-width: 70%;
      }
      &:last-child {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }
  .ms-account-welcome-tile {
    min-height: 227px;
    .d-flex {
      display: none !important;
    }
  }
  .graph-section {
    .row {
      display: block;
      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
        display: none;
        &:first-child,
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
