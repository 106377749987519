$popover-menu-item-height: 50px;

//style presets
:root {
	// text
	--msv-account-balance-text-font-size: var(--msv-body-font-size-m);
	--msv-account-balance-text-font-weight: var(--msv-font-weight-light);

	// bolder text
	--msv-account-balance-bolder-text-font-weight: var(--msv-font-weight-bold);

	// title
	--msv-account-balance-title-font-size: var(--msv-body-font-size-xl);
	--msv-account-balance-title-font-weight: var(--msv-font-weight-light);

	// account details section title
	--msv-account-balance-details-section-title-font-size: var(
		--msv-body-font-size-xl
	);
	--msv-account-balance-details-section-title-font-weight: var(
		--msv-font-weight-light
	);

	// customer account number text
	--msv-account-balance-customer-account-number-text-font-size: #{$msv-heading-text-size};

	// credit amount text
	--msv-account-balance-credit-amount-text-font-size: var(
		--msv-body-font-size-xxl
	);
}

.ms-account {
	&-customer-balance {
		font-weight: var(--msv-account-balance-text-font-weight);
		font-size: var(--msv-account-balance-text-font-size);

		h2 {
			padding: 25px 0 10px;
			font-weight: var(--britax-font-weight-bold-full);
			font-size: var(--britax-font-size-sml-32);
			line-height: normal;
		}
		@media (max-width: $britax-container-max-width-s) {
			h2 {
				font-size: var(--britax-font-size-xsml);
				text-align: center;
			}
		}

		h3 {
			padding-top: 25px;
		}

		.ms-account-customer-balance__credit-summary-heading {
			@include add-icon($msv-ListAlt, before);

			&::before {
				padding-right: 5px;
			}
		}

		&__account {
			display: flex;
			padding: 1rem 0;
			justify-content: space-between;

			div {
				width: 100%;
			}
		}

		&__credit-summary {
			display: block;
			padding: 1rem 0;
			overflow: hidden;
			margin-bottom: 30px;
			> div {
				width: 33.33%;
				text-align: center;
				float: left;
				&.credit-box {
					background-color: var(--britax-grey-2);
					p {
						text-transform: capitalize;
					}
				}
				&.balance-box {
					background-color: var(--britax-light-grey);
					p {
						text-transform: capitalize;
					}
				}
				@media (max-width: $britax-container-max-width-s) {
					height: 115px;
					width: 50%;
				}
				&.account-summary-blk {
					text-align: left;
					padding: 30px;
					background: var(--britax-light-grey);
					&:first-child {
						display: none;
					}
				}
			}
		}

		&__account {
			background-color: var(--britax-light-grey);
			padding: 30px;
			overflow: hidden;
			width: 100%;
			margin-top: 15px;

			&__details {
				h4 {
					font-weight: var(--britax-font-weight-bold-full);
					font-size: var(--britax-font-size-sml);
					float: left;
					text-transform: capitalize;
					&::after {
						font-family: "Font Awesome 5 Free";
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						font-weight: 900;
						text-rendering: auto;
						line-height: 1;
						content: ":";
						margin-left: 5px;
					}
				}

				.ms-account-customer-balance__account-number {
					font-weight: var(--britax-font-weight-bold-full);
					font-size: var(--britax-font-size-sml);
					float: left;
					width: auto;
					margin: 8px 0 0 7px;
				}
				.ms-account-customer-balance__created-on {
					overflow: hidden;
					padding: 5px 0;
					font-weight: var(--britax-font-weight-normal);
					text-transform: capitalize;
				}
			}
			&__limit {
				text-align: right;
				h4 {
					font-weight: var(--britax-font-weight-bold-full);
					font-size: var(--britax-font-size-sml);
				}
				p {
					text-transform: capitalize;
				}
			}
			@media (max-width: $britax-container-max-width-s) {
				&__details {
					h4 {
						font-size: var(--britax-font-size-xsml);
					}
				}
				.ms-account-customer-balance__account-number {
					font-size: var(--britax-font-size-xsml);
					margin-left: 0px;
				}
				&__limit {
					h4 {
						font-size: var(--britax-font-size-xsml);
					}
					p {
						font-size: var(--britax-font-size-xm);
					}
				}
			}
		}

		&__account__credit,
		&__credit-summary__values {
			> div {
				font-weight: var(--msv-account-balance-bolder-text-font-weight);
				text-align: right;
			}
		}

		&__credit-summary__values {
			.ms-account-customer-balance__excess-credit-label {
				&::before {
					content: "(";
				}

				&::after {
					content: ")";
				}
			}
		}

		&__account__credit {
			.ms-account-customer-balance__available-credit-label {
				font-weight: var(--britax-font-weight-normal);
				font-size: var(--britax-font-size-sm);
			}

			.ms-account-customer-balance__available-credit-amount {
				font-weight: var(--britax-font-weight-bold-full);
				font-size: var(--britax-font-size-sml);
				line-height: 3rem;
			}
		}

		&__action {
			&__bar {
				margin-top: 10px;
			}

			&__requestStatement {
				border: 1px solid var(--britax-grey);
				background-color: var(--msv-bg-color);
				padding: 10px;
				width: 100%;
				text-align: center;

				&__toggleDown {
					@include add-icon($msv-ChevronDown, after);
					border: 1px solid var(--britax-primary);
					background-color: var(--msv-bg-color);
					font-size: var(--britax-font-size-sm);
					text-align: center;
					display: inline;
					min-width: 160px;
					padding: 10px 20px;
					height: fit-content;
					float: right;
					&.msc-btn:focus {
						border: 1px solid var(--britax-grey);
					}
					&:hover {
						border: 1px solid var(--britax-grey);
					}

					&:hover,
					&:hover > span,
					&:hover > div,
					&:hover::after {
						color: var(--britax-grey);
					}
				}

				&__toggleUp {
					@include add-icon($msv-ChevronUp, after);
					border: 1px solid var(--britax-grey);
					background-color: var(--msv-bg-color);
					font-size: var(--britax-font-size-sm);
					text-align: center;
					display: inline;
					min-width: 160px;
					padding: 10px 20px;
					height: fit-content;
					float: right;
					&.msc-btn:focus {
						border: 1px solid var(--britax-grey);
					}
				}

				&__dropdownHidden {
					visibility: hidden;
					height: 0;
					display: none;
				}

				&__dropdownVisible {
					background-color: var(--britax-white);
					background-clip: padding-box;
					border: 0;
					box-shadow: 0px 0px 3px var(--britax-cold-grey);
					border-radius: 3px;
					padding: 0 10px;
					margin-top: 5px;
					display: block;
					left: 0;
					max-width: 276px;
					min-width: 200px;
					position: absolute;
					top: 0;
					word-wrap: break-word;
					z-index: 1060;
					text-transform: capitalize;

					.msc-btn {
						color: var(--britax-primary);
						height: $popover-menu-item-height;
						border-bottom: solid 1px var(--britax-grey-2);
						text-align: left;
						padding: 0;
						&:hover {
							color: var(--britax-grey);
						}
					}

					.msc-arrow {
						display: block;
						height: 0.5rem;
						margin: 0 0.3rem;
						position: absolute;
						top: calc((0.5rem + 1px) * -1);
						width: 1rem;
						display: none;

						&::before,
						&::after {
							border-color: transparent;
							border-style: solid;
							border-width: 0 0.5rem 0.5rem 0.5rem;
							content: "";
							display: block;
							position: absolute;
						}

						&::before {
							border-bottom-color: $msv-gray-500;
							top: 0;
						}

						&::after {
							border-bottom-color: var(--britax-silver-grey);
							top: 1px;
						}
					}
				}

				&__dropdownOption {
					background-color: var(--msv-bg-color);
					font-size: var(--britax-font-size-sm);
					display: block;
					width: 100%;
				}

				&__buttonLabel {
					padding-right: 20px;
					color: var(--britax-primary);
				}
			}

			&__submitRequest {
				@include primary-button();
				text-transform: capitalize;
				margin-right: 10px;
				&:disabled {
					cursor: default;
					border-color: var(--britax-grey-550);
					background-color: var(--britax-grey-550);
					color: var(--britax-white);
				}
			}

			&__cancelRequest {
				@include grey-button();
			}
		}

		&__requestStatement__modal {
			position: absolute;
		}

		&__requestStatement__fromDate {
			padding-top: 20px;
			width: 300px;
			float: left;
			background-clip: padding-box;
			font-size: var(--britax-body-font-size-s);
			line-height: var(--britax-line-height-sml);
		}

		&__requestStatement__toDate {
			padding-top: 20px;
			width: 300px;
			background-clip: padding-box;
			font-size: var(--britax-body-font-size-s);
			line-height: var(--britax-line-height-sml);
		}

		@media (min-width: 620px) {
			&__requestStatement__toDate {
				float: right;
			}
		}

		&__dateSelector {
			margin-top: 5px;
			display: block;
			width: 95%;
		}

		&__sendToEmail {
			font-weight: $msv-font-weight-bold;
			padding-top: 5px;
			padding-bottom: 20px;
		}

		&__dropdown {
			overflow: hidden;
			margin-top: -52px;
			@media (max-width: $britax-container-max-width-s) {
				margin-top: 0px;
				display: flex;
				justify-content: center;
			}
		}

		&__buttonBar {
			overflow: hidden;
		}
	}
}

.ms-business-organization-list {
	&__action {
		&__bar {
			margin-top: 10px;
		}

		&__requestStatement {
			border: 1px solid var(--msv-accent-brand-color);
			background-color: var(--msv-bg-color);
			padding: 10px;
			width: 100%;
			text-align: center;

			&__toggleDown {
				@include add-icon($msv-ChevronDown, after);
				border: 1px solid var(--britax-primary);
				background-color: var(--britax-white);
				color: var(--britax-grey);
				text-align: center;
				display: inline;
				margin-left: 20px;
				height: fit-content;
				padding: 5px 15px;
				min-width: 210px;
				outline: none;
				height: 40px;
				border-radius: 25px;
				font-size: var(--britax-body-font-size-s);
				@media (max-width: $britax-container-min-width-s) {
					min-width: 215px;
					font-size: var(--britax-body-font-size-xs);
				}
			}

			&__toggleUp {
				@include add-icon($msv-ChevronUp, after);
				border: 1px solid var(--britax-primary);
				background-color: var(--britax-white);
				color: var(--britax-grey);
				text-align: center;
				display: inline;
				margin-left: 20px;
				height: fit-content;
				padding: 5px 25px;
				min-width: auto;
				outline: none;
				height: 40px;
				border-radius: 25px;
				font-size: var(--britax-body-font-size-s);

				&.msc-btn {
					&:focus {
						border: 1px solid var(--britax-primary);
					}
				}
			}

			&__dropdownHidden {
				visibility: hidden;
				height: 0;
				display: none;
			}

			&__dropdownVisible {
				background-color: var(--britax-white);
				background-clip: padding-box;
				border: 1px solid var(--britax-silver-grey);
				display: block;
				left: 0;
				position: absolute;
				top: 0;
				word-wrap: break-word;
				z-index: 1060;

				.msc-btn {
					color: var(--britax-grey);
					font-weight: var(--britax-font-weight-normal);
					height: $popover-menu-item-height;
				}

				.msc-arrow {
					display: block;
					height: 0.5rem;
					margin: 0 0.3rem;
					position: absolute;
					top: calc((0.5rem + 1px) * -1);
					width: 1rem;

					&::before,
					&::after {
						border-color: transparent;
						border-style: solid;
						border-width: 0 0.5rem 0.5rem 0.5rem;
						content: "";
						display: block;
						position: absolute;
					}

					&::before {
						border-bottom-color: $msv-gray-500;
						top: 0;
					}

					&::after {
						border-bottom-color: var(--msv-border-color);
						top: 1px;
					}
				}
			}

			&__dropdownOption {
				background-color: var(--msv-bg-color);
				font-size: var(--britax-font-size-sm);
				display: block;
				width: 100%;
			}

			&__buttonLabel {
				padding-right: 20px;
			}
		}

		&__submitRequest {
			@include primary-button();
			text-transform: capitalize;
			margin-right: 10px;
			&:disabled {
				cursor: default;
				border-color: var(--britax-grey-550);
				background-color: var(--britax-grey-550);
				color: var(--britax-white);
			}
		}

		&__cancelRequest {
			@include grey-button();
		}
	}

	&__requestStatement__modal {
		position: absolute;
	}

	&__requestStatement__fromDate {
		padding-top: 20px;
		width: 300px;
		float: left;
		background-clip: padding-box;
		font-size: var(--britax-body-font-size-s);
		line-height: var(--britax-line-height-sml);
	}

	&__requestStatement__toDate {
		padding-top: 20px;
		width: 300px;
		background-clip: padding-box;
		font-size: var(--britax-body-font-size-s);
		line-height: var(--britax-line-height-sml);
	}

	@media (min-width: 620px) {
		&__requestStatement__toDate {
			float: right;
		}
	}

	&__dateSelector {
		margin-top: 5px;
		display: block;
		width: 95%;
	}

	&__sendToEmail {
		font-weight: bold;
		padding-top: 5px;
		padding-bottom: 20px;
	}

	&__dropdown {
		width: fit-content;
		display: none;
	}

	&__buttonBar {
		display: flex;
		flex-direction: row-reverse;
	}

	&__selectedUser {
		font-weight: $msv-font-weight-heavy; // semi-bold
		display: block;
	}

	&__table {
		.ms-table {
			&__heading-row {
				.checkbox {
					display: none;
				}
			}
			&__row {
				td:first-child {
					display: none;
				}
			}
		}
	}
}
