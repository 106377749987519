@import "../00-settings/colors";
@import "../00-settings/font";
@import "../00-settings/responsive-breakpoints";

$msv-btn-min-width: 160px;
$msv-btn-side-padding: 20px;
$msv-btn-vertical-padding: 12px;

:root {
  // Background
  --msv-secondary-button-background-color: #{$msv-white};
}

@mixin button-link($color: $msv-link-color) {
  border-color: transparent;
  background-color: transparent;
  color: $color;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
@mixin button-link-cart($color: $msv-link-color) {
  border-color: transparent;
  background-color: transparent;
  color: var(--britax-primary);

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--britax-grey);
    &:before {
      color: var(--britax-grey);
    }
  }
}

@mixin primary-button(
  $bg-color: var(--britax-primary),
  $color: var(--britax-white),
  $border-color: var(--britax-primary)
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  outline: none;
  border-radius: 25px;
  &:hover,
  &:focus {
    background-color: var(--britax-grey);
    border-color: var(--britax-grey);
    color: var(--britax-white);
    outline: none;
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: var(--britax-silver-grey);
    background-color: var(--britax-silver-grey);
    color: var(--britax-white);
  }
}

@mixin primary-button-others(
  $bg-color: var(--britax-primary),
  $color: var(--britax-white),
  $border-color: var(--britax-primary)
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  outline: none;
  border-radius: 25px;
  &:hover {
    background-color: var(--britax-grey);
    border-color: var(--britax-grey);
    color: var(--britax-white);
    outline: none;
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: var(--britax-silver-grey);
    background-color: var(--britax-silver-grey);
    color: var(--britax-white);
  }
}

@mixin secondary-button(
  $bg-color: var(--britax-grey),
  $color: var(--britax-primary),
  $border-color: var(--britax-grey),
  $disableBtn: true
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  outline: none;
  border-radius: 25px;
  &:hover,
  &:focus {
    background-color: var(--britax-silver-grey);
    border-color: var(--britax-silver-grey);
    color: var(--britax-grey);
    outline: none;
    text-decoration: none;
  }

  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: var(--britax-silver-grey);
      background-color: var(--britax-silver-grey);
      color: var(--britax-grey);
    }
  }
}

@mixin secondary-button-checkout-keep-shopping(
  $bg-color: var(--britax-silver-grey),
  $color: var(--britax-primary),
  $border-color: var(--britax--silver-grey),
  $disableBtn: true
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  outline: none;
  border-radius: 25px;
  &:hover,
  &:focus {
    background-color: var(--britax-grey);
    border-color: var(--britax-grey);
    color: var(--britax-white);
    outline: none;
    text-decoration: none;
  }

  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: var(--britax-silver-grey);
      background-color: var(--britax-silver-grey);
      color: var(--britax-grey);
    }
  }
}

@mixin secondary-button-business-signup-cancel(
  $bg-color: var(--britax-silver-grey),
  $color: var(--britax-primary),
  $border-color: var(--britax-silver-grey),
  $disableBtn: true
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  outline: none;
  border-radius: 25px;
  &:hover,
  &:focus {
    background-color: var(--britax-grey);
    border-color: var(--britax-grey);
    color: var(--britax-white);
    outline: none;
    text-decoration: none;
  }

  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: var(--britax-silver-grey);
      background-color: var(--britax-silver-grey);
      color: var(--britax-grey);
    }
  }
}

@mixin grey-button(
  $bg-color: var(--britax-silver-grey),
  $color: var(--britax-primary),
  $border-color: var(--britax-silver-grey),
  $disableBtn: true
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;
  outline: none;
  border-radius: 25px;
  &:hover,
  &:focus {
    background-color: var(--britax-grey);
    border-color: var(--britax-grey);
    color: var(--britax-white);
    outline: none;
    text-decoration: none;
  }

  @if $disableBtn {
    &:disabled,
    &[disabled] {
      cursor: default;
      border-color: var(--britax-silver-grey);
      background-color: var(--britax-silver-grey);
      color: var(--britax-grey);
    }
  }
}

@mixin button-default() {
  @include vfi();
  @include font-content-l();
  cursor: pointer;
  padding: 12px 30px;
  height: 48px;
  min-width: 160px;
  text-align: center;
}

@mixin button-icon {
  width: 48px;
  height: 48px;
}
