// style-presets
:root {
  // primary button
  --msc-pay-invoice-error-primary-btn-bg: var(--msv-accent-brand-color);
  --msc-pay-invoice-error-primary-btn-font-color: var(
    --msv-font-secondary-color
  );
  --msc-pay-invoice-error-primary-btn-border: var(--msv-accent-brand-color);

  // secondary button
  --msc-pay-invoice-error-secondary-btn-bg: var(
    --msv-secondary-button-background-color
  );
  --msc-pay-invoice-error-secondary-btn-font-color: var(
    --msv-font-primary-color
  );
  --msc-pay-invoice-error-secondary-btn-border: var(--msv-accent-brand-color);
}

.msc-invoices-list {
  @include font-content(
    var(--msv-font-weight-normal),
    var(--msv-body-font-size-m),
    $msv-line-height-m
  );
  margin-top: 30px;
  &__header {
    margin-bottom: 10px;
    overflow: hidden;
    &-heading {
      float: left;
      @media screen and (max-width: $britax-breakpoint-mbl) {
        float: none;
      }
    }
    &-description {
      float: right;
      margin: 5px 0;
      text-align: right;
      &-amount {
        font-size: var(--britax-font-size-sml);
        font-weight: var(--britax-font-weight-bold-full);
      }
      @media screen and (max-width: $britax-breakpoint-mbl) {
        float: none;
        text-align: left;
      }
    }
  }

  &__filter {
    text-align: right;

    &__expand-filter-button {
      background-color: unset;
      min-width: 170px;
      border: solid 1px var(--britax-silver-grey);
      font-size: var(--britax-font-size-sm);
      color: var(--britax-grey);
      padding: 10px 15px;
      text-align: left;
      height: auto;
      &:focus {
        border: solid 1px var(--britax-silver-grey);
      }
      &::after {
        @include msv-icon();
        content: $msv-ChevronDown;
        font-size: var(--msv-header-font-size);
        text-align: center;
        vertical-align: text-bottom;
        line-height: 1.2;
        margin: 3px 0 0;
        float: right;
      }
      @media screen and (max-width: $britax-breakpoint-mbl) {
        width: 100%;
      }
    }

    &__expand-filter-button[aria-expanded="true"] {
      &::after {
        content: $msv-ChevronUp;
      }
    }

    .msc-popover {
      background-color: var(--britax-white);
      background-clip: padding-box;
      box-shadow: 0px 0px 3px var(--britax-cold-grey);
      border-radius: 4px;
      display: block;
      left: 0;
      max-width: 170px;
      position: absolute;
      top: 0;
      word-wrap: break-word;
      z-index: 1060;
      width: 100%;

      a,
      button {
        align-items: center;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        padding: 0 20px;
        height: 42px;
        cursor: pointer;
        border: 0;
        width: 100%;
        color: var(--britax-grey);
        background: var(--britax-white);
        border-bottom: solid 1px var(--britax-grey-2);

        &:hover {
          color: var(--britax-primary);
          text-decoration: none;
        }
      }

      .msc-btn {
        color: var(--msv-header-font-color);
        font-weight: var(--msv-font-weight-normal);
        height: $popover-menu-item-height;
      }

      .msc-arrow {
        display: block;
        height: 0.5rem;
        margin: 0 0.3rem;
        position: absolute;
        top: calc((0.5rem + 1px) * -1);
        width: 1rem;

        &::before,
        &::after {
          border-color: transparent;
          border-style: solid;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          content: "";
          display: block;
          position: absolute;
        }

        &::before {
          border-bottom-color: var(--britax-silver-grey);
          top: 0;
        }

        &::after {
          border-bottom-color: var(--britax-silver-grey);
          top: 1px;
        }
      }
    }
  }

  &__empty-lines__container {
    text-align: center;
    padding-top: 80px;

    &__title {
      font-size: 24px;
      line-height: 31px;
    }

    &__description {
      font-size: 16px;
      line-height: 21px;
      margin-top: 20px;
    }

    &__actions__continue-shopping {
      @include primary-button();
      margin: 40px auto;
      display: block;
      width: fit-content;

      @media only screen and (max-width: $msv-breakpoint-l) {
        width: 100%;
      }
    }
  }

  &__container {
    margin-bottom: 30px;
    &__header {
      &__make-payment {
        display: none;
        @include primary-button();
        border-radius: 25px;
        @media only screen and (max-width: $msv-breakpoint-l) {
          width: 100%;
        }
      }
    }

    &__content {
      margin-top: 20px;
      color: var(--britax-grey);
      a {
        color: var(--britax-primary);
        &:hover {
          color: var(--britax-grey);
        }
      }
      &__table {
        width: 100%;
        border-collapse: collapse;

        .checkbox-container {
          display: block;
          position: relative;
          margin: 3px 0 0 18px;
          padding-left: 35px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          height: 19px;

          .checkbox-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 19px;
            width: 19px;
            background-color: transparent;
            border: 1px solid var(--britax-grey);
            border-radius: 2px;
          }

          .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2.25px;
            width: 5px;
            height: 10px;
            border: solid var(--britax-white);
            background-color: var(--britax-primary);
            border-width: 0 1.75px 1.75px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          input:checked ~ .checkmark {
            background-color: var(--britax-primary);
            border-color: var(--britax-primary);
          }

          input:checked ~ .checkmark::after {
            display: block;
          }
        }

        &__header {
          text-align: left;

          &__row {
            height: 50px;

            th {
              font-style: normal;
              font-weight: var(--britax-font-weight-bold);
              font-size: 16px;
              line-height: 21px;
              color: var(--britax-primary);
              &:first-child {
                padding-left: 15px;
              }
            }
          }
        }

        &__row {
          height: 81px;

          > td {
            color: var(--britax-primary);
            > b {
              font-weight: var(--britax-font-weight-normal);
              color: var(--britax-primary);
            }
            &:first-child {
              padding-left: 15px;
            }
          }

          &:nth-child(even) {
            background-color: var(--britax-silver-grey);
          }
          &:first-child {
            background-color: var(--britax-white);
          }

          &__open-invoice {
            text-decoration: underline;
            cursor: pointer;
          }

          &__pay-invoice {
            display: none;
            @include secondary-button();
            @include add-icon(
              $msv-Arrow-Right-In-Circle,
              before,
              $msv-outline-icon-weight
            );
            background-color: transparent;
            font-size: 0;
            padding: 3px;
            border: 0;
            min-width: auto;
            height: auto;
            &:before {
              font-size: var(--britax-font-size-sm);
              color: var(--britax-primary);
              margin-top: 9px;
            }
            &:hover {
              background-color: transparent;
              &:before {
                color: var(--britax-grey);
              }
            }
            &:focus {
              background-color: transparent;
            }
          }

          &__extra-actions-cell {
            &__toggle {
              @include add-icon($msv-Ellipses-V);
              background: none;
              height: 82px;
            }

            &__actions-container {
              align-items: flex-start;
              background: $msv-white;
              border-radius: 2px;
              box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132),
                0 0.6px 1.8px rgba(0, 0, 0, 0.108);
              flex-direction: column;
              height: auto;
              padding-left: 0;
              position: absolute;
              left: 0;
              width: 100%;
              z-index: 10;

              &__view-details {
                @include add-icon($msv-List);
              }

              &__pay-invoice {
                @include add-icon(
                  $msv-Arrow-Right-In-Circle,
                  before,
                  $msv-outline-icon-weight
                );
              }

              &__requestInvoiceButton,
              &__send-accounting {
                @include add-icon($msv-envelope);
                margin-left: 0;
                margin-right: 0;
              }

              button {
                background: none;
                height: 60px;
                padding: 0 8px;
                text-align: left;
                width: 100%;

                &::before {
                  margin-right: 12px;
                  width: 16px;
                  color: var(--msv-accent-brand-color);
                }
              }
            }
          }

          &__requestInvoiceButton {
            font-weight: var(--britax-font-weight-bold);
            font-size: var(--msv-body-font-size-m);
            color: var(--britax-primary);
            text-decoration: underline;
            background-color: transparent;
            padding: 0;
            height: auto;
            display: block;
            &:disabled {
              opacity: 0.3;
              cursor: auto;
            }
            &:hover {
              color: var(--britax-grey);
            }
          }
        }
      }
    }
  }

  &__modal-container {
    margin-bottom: 30px;
    padding: 0px 10px 0px 10px;
    max-height: 500px;
    overflow-y: auto;
    &__content-modal {
      margin-top: 20px;
      color: var(--britax-primary);
      &__table-modal {
        width: 100%;
        border-collapse: collapse;

        &__modal-header {
          text-align: left;
          background-color: var(--britax-grey-2);

          &__modal-row {
            height: 50px;
            width: 100%;
            padding: 10px 10px 10px 0px;
            font-weight: var(--britax-font-weight-bold);
            margin: 10px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: var(--britax-silver-grey);

            .linenumber {
              width: 8.5%;
              text-align: center;
            }

            .itemnumber {
              width: 8.5%;
              text-align: center;
            }

            .itemname {
              width: 40%;
              text-align: center;
            }

            .priceperunit {
              width: 8.5%;
              text-align: center;
            }

            .currency {
              width: 8.5%;
              text-align: center;
            }

            .quantity {
              width: 8.5%;
              text-align: center;
            }

            .discountpercentage {
              width: 8.5%;
              text-align: center;
            }
            .product {
              width: 49%;
              text-align: center;
              margin-left: 6%;
            }
            .sku {
              width: 8.5%;
              text-align: center;
            }
            .price {
              width: 8.5%;
              text-align: center;
            }
            .qty {
              width: 8.5%;
              text-align: center;
            }
            .totalamount {
              width: 8.5%;
              text-align: center;
            }
          }
        }

        &__modal-row {
          height: 81px;

          > td {
            color: var(--britax-primary);
            > b {
              font-weight: var(--britax-font-weight-normal);
              color: var(--britax-primary);
            }
            &:first-child {
              padding-left: 15px;
            }
          }

          &:nth-child(even) {
            background-color: var(--britax-silver-grey);
          }
          &:first-child {
            background-color: var(--britax-white);
          }

          &__open-invoice {
            text-decoration: underline;
            cursor: pointer;
          }

          &__pay-invoice {
            display: none;
            @include secondary-button();
            @include add-icon(
              $msv-Arrow-Right-In-Circle,
              before,
              $msv-outline-icon-weight
            );
            background-color: transparent;
            font-size: 0;
            padding: 3px;
            border: 0;
            min-width: auto;
            height: auto;
            &:before {
              font-size: var(--britax-font-size-sm);
              color: var(--britax-primary);
              margin-top: 9px;
            }
            &:hover {
              background-color: transparent;
              &:before {
                color: var(--britax-grey);
              }
            }
            &:focus {
              background-color: transparent;
            }
          }

          &__extra-actions-cell {
            &__toggle {
              @include add-icon($msv-Ellipses-V);
              background: none;
              height: 82px;
            }

            &__actions-container {
              align-items: flex-start;
              background: $msv-white;
              border-radius: 2px;
              box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132),
                0 0.6px 1.8px rgba(0, 0, 0, 0.108);
              flex-direction: column;
              height: auto;
              padding-left: 0;
              position: absolute;
              left: 0;
              width: 100%;
              z-index: 10;

              &__view-details {
                @include add-icon($msv-List);
              }

              &__pay-invoice {
                @include add-icon(
                  $msv-Arrow-Right-In-Circle,
                  before,
                  $msv-outline-icon-weight
                );
              }

              &__requestInvoiceButton,
              &__send-accounting {
                @include add-icon($msv-envelope);
                margin-left: 0;
                margin-right: 0;
              }

              button {
                background: none;
                height: 60px;
                padding: 0 8px;
                text-align: left;
                width: 100%;

                &::before {
                  margin-right: 12px;
                  width: 16px;
                  color: var(--msv-accent-brand-color);
                }
              }
            }
          }

          &__requestInvoiceButton {
            font-weight: var(--britax-font-weight-bold);
            font-size: var(--msv-body-font-size-m);
            color: var(--britax-primary);
            text-decoration: underline;
            background-color: transparent;
            padding: 0;
            height: auto;
            display: block;
            &:disabled {
              opacity: 0.3;
              cursor: auto;
            }
          }
        }
      }
    }
  }

  &__load-more {
    @include primary-button();
    text-transform: capitalize;

    margin-top: 20px;

    &.is-busy {
      @include add-spinner(before);
    }
    &:focus {
      background-color: var(--britax-primary);
      border: 1px solid var(--britax-primary);
    }
  }

  &__requestInvoice {
    &__submitRequest {
      @include primary-button();
      width: auto;
      font-size: $msv-font-size-l;
      line-height: $msv-line-height-l;
    }

    &__cancelRequest {
      @include grey-button();
      padding: 10px;
      width: 150px;
      margin-left: 20px;
      margin-right: 20px;
      font-size: $msv-font-size-l;
      line-height: $msv-line-height-l;
    }

    &__sendToEmail {
      font-weight: var(--msv-font-weight-bold);
      padding-top: 10px;
    }

    &__modalBody {
      font-size: $msv-font-size-l;
      min-height: 300px;
      &.accounting {
        .column {
          margin-bottom: 25px;
          input,
          select {
            height: 50px;
            width: 100%;
            display: block;
            padding: 5px 15px;
            color: var(--britax-grey);
            font-size: var(--britax-body-font-size-s);
            &:disabled {
              background-color: var(--britax-cold-grey) !important;
            }
          }
          textarea {
            height: 120px;
            width: 100%;
            display: block;
            padding: 10px 15px;
            color: var(--britax-grey);
            font-size: var(--britax-body-font-size-s);
            &:disabled {
              background-color: var(--britax-cold-grey) !important;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .success {
          background: var(--britax-success-bg-color);
          color: var(--britax-success-font-color);
          font-size: var(--britax-body-font-size-s);
          padding: 10px;
        }
        .failed {
          background: var(--britax-danger-bg-color);
          color: var(--britax-danger-font-color);
          font-size: var(--britax-body-font-size-s);
          padding: 10px;
        }
      }
    }

    &__selectedInvoiceButton {
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 5px;
    }

    &__search {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }

    &__searchBar {
      height: 48px;
      padding: 0 10px;
      @media screen and (max-width: 450px) {
        width: 250px;
      }

      @media screen and (min-width: 450px) {
        width: 400px;
      }
    }

    &__searchButton {
      @include add-icon($msv-Search);

      background-color: var(--britax-primary);
      border: 1px solid var(--britax-primary);
      color: var(--britax-white);
      padding: 10px 10px;
      width: 48px;
      height: 48px;
    }

    &__searchResultDisplay {
      margin-top: 30px;
      margin-bottom: 10px;
      border-top: 1px solid;
      padding-top: 10px;
      display: block;
      font-size: $msv-font-size-m;
    }

    &__searchResultNumber {
      font-weight: var(--msv-font-weight-bold);
    }

    &__filteredInvoiceResult {
      margin-bottom: 10px;
    }
  }

  &__requestInvoiceButton {
    font-weight: var(--britax-font-weight-bold);
    font-size: var(--msv-body-font-size-m);
    color: var(--britax-grey);
    text-decoration: underline;
    background-color: transparent;
    padding: 0;
    height: auto;
    display: block;
  }
}

.msc-pay-invoice-error-dialog {
  &__header.msc-modal__header {
    margin-bottom: 40px;
  }

  &__body.msc-modal__body {
    margin-bottom: 30px;
  }

  &__error {
    &-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 4px;
    }

    &-description {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &__dialog__footer {
    margin-top: 10px;
  }

  &__review-shopping-bag-button {
    @include primary-button();
  }

  &__cancel-button {
    @include secondary-button();
    border-radius: 25px;
    margin-left: 9px;
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    .msc-pay-invoice-error-dialog {
      &__review-shopping-bag-button {
        margin-bottom: 12px;
        width: 100%;
      }

      &__cancel-button {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}
