$search-result-container-nav-height: 20px;
$search-result-container-nav-margin-bottom: 10px;
$search-result-container-nav-margin-top: 12px;
$search-result-container-nav-title-margin: 0;
$search-result-container-refine-product-margin-bottom: 80px;
$search-result-container-refiner-section-padding-right: 40px;
$search-result-container-refiner-section-width: 25%;
$search-result-container-refiner-button-submenu-height: 64px;
$search-result-container-refiner-button-submenu-padding-left: 0;
$search-result-container-refiner-button-submenu-padding-right: 0;
$search-result-container-refiner-button-submenu-padding-top: 6px;
$search-result-container-refiner-button-submenu-padding-bottom: 6px;
$search-result-container-refiner-submenu-list-group-border-color: $msv-gray-900;
$search-result-container-refiner-submenu-list-group-margin-bottom: 0;
$search-result-container-refiner-submenu-list-group-margin-left: 0;
$search-result-container-refiner-submenu-item-label-padding-left: 8px;
$search-result-container-refiner-submenu-list-group-item-padding-left: 0;
$search-result-container-refiner-list-group-item-border: 1px solid
  rgba(0, 0, 0, 0);
$search-result-container-refiner-list-group-item-outline-offset: 2px;
$search-result-container-refiner-list-group-item-padding: 5px 0 0;
$search-result-container-refiner-slider-horizontal-background-color: $msv-gray-300;
$search-result-container-refiner-slider-horizontal-height: 4px;
$search-result-container-refiner-slider-padding: 0;
$search-result-container-refiner-slider--track-top: 0;
$search-result-container-refiner-slider-thumb-background-size: 12px 12px;
$search-result-container-refiner-slider-thumb-border-radius: 6px;
$search-result-container-refiner-slider-thumb-height: 12px;
$search-result-container-refiner-slider-thumb-outline-offset: 2px;
$search-result-container-refiner-slider-thumb-top: 50%;
$search-result-container-refiner-slider-thumb-width: 12px;
$search-result-container-refiner-slider-thumb-before-bottom: 0;
$search-result-container-refiner-slider-thumb-before-left: 0;
$search-result-container-refiner-slider-thumb-before-right: 0;
$search-result-container-refiner-slider-thumb-before-top: 0;
$search-result-container-refiner-slider-labels-item-margin-top: 25px;
$search-result-container-refiner-slider-labels-item-padding-bottom: 20px;
$search-result-container-pagination-padding-left: 0;
$search-result-container-product-section-width: 75%;
$search-result-container-sort-by-category-padding-left: 8px;
$search-result-container-sort-by-category-right: 0;
$search-result-container-sort-by-category-dropdown-label-margin-right: 8px;
$search-result-container-products-margin-bottom: 30px;
$search-result-container-products-margin-top: 10px;
$search-result-container-product-search-result-item-grid-column-gap: 20px;
$search-result-container-product-search-result-item-width: 249px;
$search-result-container-product-search-result-item-padding: 5px;
$search-result-container-product-search-item-title-margin-bottom: 8px;
$search-result-container-product-search-item-title-margin-top: 20px;
$search-result-container-product-search-item-rating-margin-top: 4px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-page-link-padding: 8px 4px;
$search-result-container-page-item-page-link-line-height: 1.25;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-margin-top-margin-top-mobile: 24px;
$search-result-container-pagination-margin-bottom-mobile: 16px;
$search-result-container-products-margin-bottom-mobile: 40px;
$search-result-container-choice-summary-padding-inline-start: 0;
$search-result-container-choice-summary-margin-left: 8px;
$search-result-container-choice-summary-margin-top: 2px;
$search-result-container-choice-summary-margin-bottom: 5px;
$search-result-container-choice-summary-background-color: $msv-gray-200;
$search-result-container-choice-summary-border: 1px solid $msv-gray-200;
$search-result-container-choice-summary-margin-right: 5px;
$search-result-container-choice-summary-border-radius: 3px;
$search-result-container-choice-summary-padding-left: 5px;
$search-result-container-choice-summary-outline-offset: 2px;
$search-result-container-choice-summary-clear-all-background-color: $msv-gray-100;
$search-result-container-choice-summary-clear-all-border: 0;
$search-result-container-choice-summary-clear-all-outline-offset: 2px;
$search-result-container-choice-summary-glypg-margin-left: 5px;
$search-result-container-choice-summary-glypg-margin-right: 5px;
$search-result-modal-header-close-button-padding: 20px;
$search-result-modal-header-close-button-margin: -20px -20px -20px auto;
$search-result-modal-body-height: 450px;
$search-result-modal-content-padding: 20px 25px;
$search-result-modal-footer-button-background-color: $msv-black;
$search-result-modal-footer-button-border-color: $msv-black;
$search-result-modal-footer-button-padding: 12px 20px;
$search-result-modal-footer-button-min-width: 160px;
$search-result-modal-refiner-section-padding-right: 0;
$search-result-container-padding-left-mobile: 16px;
$search-result-container-padding-right-mobile: 16px;
$search-result-container-sort-by-category-top-mobile: 0;
$product-search-result_link__item-outline-width: 1px;
$product-search-result_link__item-outline-style: dashed;
$product-search-result_link__item-outline-offset: 2px;
$search-result-prev-next-padding: 0 4px;

//style presets
:root {
  --msv-search-result-font-color: var(--msv-font-primary-color);
  --msv-search-result-font-size: var(--msv-body-font-size-m);
  --msv-search-result-heading-font-color: var(--msv-font-primary-color);
  --msv-search-result-heading-font-size: var(--msv-body-font-size-xl);
  --msv-search-result-sort-bg: transparent;
  --msv-search-result-sort-font-color: var(--msv-font-primary-color);
  --msv-search-result-sort-font-size: var(--msv-body-font-size-m);
  --msv-search-result-product-color: var(--msv-font-primary-color);
  --msv-search-result-product-size: var(--msv-body-font-size-m);
  --msv-search-result-product-price-color: var(--msv-font-primary-color);
  --msv-search-result-product-price-size: var(--msv-body-font-size-l);
  --msv-search-result-pagination-color: var(--msv-font-primary-color);
  --msv-search-result-pagination-size: var(--msv-body-font-size-m);

  //Refiner heading
  --msv-refiner-heading-font-color: var(--msv-font-primary-color);
  --msv-refiner-heading-font-size: var(--msv-body-font-size-l);

  //Refiner values
  --msv-refiner-values-font-color: var(--msv-font-primary-color);
  --msv-refiner-values-font-size: var(--msv-body-font-size-m);

  //border
  --msv-refiner-border: #{$msv-gray-300};

  // slider
  --msv-refiner-slider: var(--msv-font-primary-color);

  // rating star
  --msv-refiner-rating-star: var(--msv-font-primary-color);
  --msv-refiner-rating-star-size: var(--msv-body-font-size-m);
  --msv-feature-title-color: var(--msv-font-primary-color);
  --msv-feature-title-desktop-size: var(--msv-body-font-size-xxl);
  --msv-feature-title-mobile-size: var(--msv-body-font-size-xl);
  --msv-feature-heading-color: var(--msv-font-primary-color);
  --msv-feature-heading-font-size: var(--msv-body-font-size-l);
}

.ms-search-result-container__category-nav-section {
  display: flex;
  height: auto;
  justify-content: space-between;
  justify-content: right;

  .ms-search-result-container__category-hierarchy {
    @include font-content-s();
    a {
      color: var(--britax-grey);
    }
    display: none;
  }

  .ms-search-result-container__title h5 {
    margin: $search-result-container-nav-title-margin;

    .ms-search-result__collection-title-count {
      color: var(--britax-primary);
      font-weight: var(--font-weight-bold);
    }
  }
}

.ms-search-result-container__refine-product-section {
  display: inline-flex;
  margin-bottom: $search-result-container-refine-product-margin-bottom;
  width: 100%;
}

// refiner section
.ms-search-result-container__refiner-section {
  display: block;
  padding-right: $search-result-container-refiner-section-padding-right;
  width: $search-result-container-refiner-section-width;

  &__heading {
    margin-bottom: 10px;
    color: var(--britax-primary);
    font-size: var(--britax-font-size-xsml);
    font-weight: var(--britax-font-weight-bold-full);
    border-bottom: 1px solid var(--msv-refiner-border);
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .collapse:not(.show) {
    display: none;
  }

  button.ms-refine-submenu__toggle_collapsed,
  .ms-refine-submenu__toggle_expanded {
    background: transparent;
    color: var(--britax-primary);
    font-size: var(--britax-body-font-size-m);
    display: flex;
    justify-content: space-between;
    padding-left: $search-result-container-refiner-button-submenu-padding-left;
    padding-right: $search-result-container-refiner-button-submenu-padding-right;
    width: 100%;
    font-weight: var(--britax-font-weight-bold);
    height: auto;
    text-align: left;
  }

  .ms-refine-submenu.list-group {
    border-bottom: 1px solid var(--msv-refiner-border);
    display: flex;
    flex-direction: column;
    margin-bottom: $search-result-container-refiner-submenu-list-group-margin-bottom;
    padding-left: $search-result-container-refiner-submenu-list-group-margin-left;
    border-radius: 0;

    &:first-child {
      border-top: 1px solid var(--msv-refiner-border);
    }

    .ms-refine-submenu-item {
      padding-left: $search-result-container-refiner-submenu-list-group-item-padding-left;
      margin-bottom: 2px;

      > a {
        @include vfi();
        &:focus {
          border: 0;
          outline: none;
        }
      }

      .msc-rating {
        display: inline-block;

        &__count {
          color: var(--msv-refiner-values-font-color);
        }

        &__empty-star,
        &__star,
        &__count {
          font-size: var(--msv-refiner-rating-star-size);
        }
      }

      .ms-rating-component {
        &__up .msc-rating__star {
          color: $msv-gray-500;
        }

        &__current .msc-rating__star {
          color: $msv-gray-900;
        }

        &__down .msc-rating__star {
          color: $msv-gray-800;
        }
      }
    }

    .refine-submenu-item__rating {
      color: var(--msv-refiner-values-font-color);
      font-size: var(--msv-refiner-rating-star-size);
    }

    .multi-select,
    .multi-select-checked,
    .single-select,
    .single-select-checked {
      @include vfi();
      display: block;
      border: 0;
      text-decoration: none;
      span.ms-refine-submenu-item__label {
        color: var(--britax-primary);
        font-size: var(--msv-refiner-values-font-size);
        padding-left: $search-result-container-refiner-submenu-item-label-padding-left;
        vertical-align: middle;
      }
    }
    &.Category {
      display: none;
    }
  }

  .list-group-item {
    border: $search-result-container-refiner-list-group-item-border;
    display: block;
    outline-offset: $search-result-container-refiner-list-group-item-outline-offset;
    padding: $search-result-container-refiner-list-group-item-padding;
    position: relative;
    // overflow: hidden;
    background: transparent;
  }

  .slider-horizontal {
    background-color: $search-result-container-refiner-slider-horizontal-background-color;
    height: $search-result-container-refiner-slider-horizontal-height;
  }

  .slider {
    box-shadow: inset 0 0 2px;
    cursor: pointer;
    display: block;
    padding: $search-result-container-refiner-slider-padding;
    position: relative;
    touch-action: none;

    .slider__container {
      height: 100%;
      width: 100%;
    }

    .slider__track {
      background-color: var(--msv-refiner-slider);
      height: 100%;
      position: absolute;
      top: $search-result-container-refiner-slider--track-top;
    }

    .slider__thumb {
      @include vfi();
      background: var(--msv-refiner-slider);
      background-size: $search-result-container-refiner-slider-thumb-background-size;
      border-radius: $search-result-container-refiner-slider-thumb-border-radius;
      display: inline-block;
      height: $search-result-container-refiner-slider-thumb-height;
      outline-offset: $search-result-container-refiner-slider-thumb-outline-offset;
      position: absolute;
      top: $search-result-container-refiner-slider-thumb-top;
      transform: translate3d(-50%, -50%, 0);
      width: $search-result-container-refiner-slider-thumb-width;
    }

    .slider__thumb::before {
      bottom: $search-result-container-refiner-slider-thumb-before-bottom;
      content: "";
      display: block;
      left: $search-result-container-refiner-slider-thumb-before-left;
      outline-offset: calc(0px + 0.0625rem);
      position: absolute;
      right: $search-result-container-refiner-slider-thumb-before-right;
      top: $search-result-container-refiner-slider-thumb-before-top;
    }

    .slider__thumb:focus {
      outline: 1px dashed $black;
      border: 1px dashed $white;
      outline-offset: 2px;
      box-shadow: none;
    }

    &__thumb__range:focus {
      + .slider__thumb {
        outline: 1px dashed $black;
        border: 1px dashed $white;
        outline-offset: 2px;
        box-shadow: none;
      }
    }

    .slider__labels-item {
      color: var(--msv-refiner-values-font-color);
      font-size: var(--msv-refiner-values-font-size);
      cursor: pointer;
      display: inline-block;
      margin-top: $search-result-container-refiner-slider-labels-item-margin-top;
      padding-bottom: $search-result-container-refiner-slider-labels-item-padding-bottom;
    }

    .slider__labels-item.start {
      float: left;
    }

    .slider__labels-item.end {
      float: right;
    }
  }
  .parent-submenu {
    .ms-refine-submenu.list-group {
      padding-bottom: 10px;
    }
    button {
      pointer-events: none;
      padding-bottom: 0;
      font-weight: var(--britax-font-weight-normal);
      &:after {
        display: none;
      }
    }
  }
}

// product section
.ms-search-result-container__product-section {
  width: $search-result-container-product-section-width;

  .ms-search-result-wrapper-title-choice-summary {
    flex: 0 0 75%;
    max-width: 75%;
    display: inline-block;
    position: relative;
    width: 100%;

    .ms-search-result-container__title h2 {
      color: var(--msv-search-result-font-color);
      font-size: var(--msv-search-result-font-size);

      .ms-search-result__collection-title-prefix,
      .ms-search-result__collection-title-text {
        @include font-content(
          var(--msv-font-weight-normal),
          var(--msv-search-result-heading-font-size),
          $msv-line-height-xl
        );
        color: var(--msv-search-result-heading-font-color);
      }

      .ms-search-result__collection-title-count {
        @include font-content(
          var(--msv-font-weight-normal),
          var(--msv-search-result-font-size),
          $msv-line-height-m
        );
        color: var(--msv-search-result-font-color);
        display: inline-block;
      }
    }
  }

  .ms-search-result-wrapper-sort-by-category {
    flex: 0 0 25%;
    max-width: 25%;
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .ms-search-result-container__title {
    display: none;
  }
  .msc-waiting-circular {
    opacity: 0;
    &:nth-child(5) {
      opacity: 1;
      margin: auto;
      left: 50%;
      top: 10%;
    }
  }
}

.msc-choice-summary {
  list-style-type: none;
  padding-inline-start: $search-result-container-choice-summary-padding-inline-start;
  margin-top: $search-result-container-choice-summary-margin-top;
  width: calc(100% - 240px);
  min-height: 30px;
  float: left;
  margin-top: -18px;

  .msc-choice-summary__list {
    display: inline-block;
    margin-bottom: $search-result-container-choice-summary-margin-bottom;
  }

  .msc-choice-summary__list-item {
    display: inline-block;
  }

  .msc-choice-summary__item {
    @include vfi();
    @include britax-font-content(
      var(--britax-font-weight-normal),
      var(--britax-body-font-size-s)
    );
    background-color: $search-result-container-choice-summary-background-color;
    color: var(--britax-grey);
    border: $search-result-container-choice-summary-border;
    margin-right: $search-result-container-choice-summary-margin-right;
    margin-bottom: $search-result-container-choice-summary-margin-bottom;
    border-radius: $search-result-container-choice-summary-border-radius;
    padding-left: $search-result-container-choice-summary-padding-left;
    outline-offset: $search-result-container-choice-summary-outline-offset;
    position: relative;
  }

  .msc-choice-summary__clear-all {
    @include vfi();
    @include britax-font-content(
      var(--britax-font-weight-normal),
      var(--britax-body-font-size-s)
    );
    display: inline-block;
    color: var(--britax-grey);
    border: $search-result-container-choice-summary-clear-all-border;
    text-decoration: underline;
    outline-offset: $search-result-container-choice-summary-clear-all-outline-offset;
    position: relative;
  }

  .msc-choice-summary__glyph {
    margin-left: $search-result-container-choice-summary-glypg-margin-left;
    margin-right: $search-result-container-choice-summary-glypg-margin-right;
    vertical-align: text-top;
    font-size: var(--britax-body-font-size-xs);

    &::after {
      @include msv-icon();
      content: $msv-Cancel;
    }
  }

  .msc-choice-summary__clear-all::after {
    display: none;
  }
}

.ms-search-result-container__Sort-by-category {
  @include britax-font-content(
    var(--britax-font-weight-normal),
    var(--britax-body-font-size-s)
  );
  position: absolute;
  right: $search-result-container-sort-by-category-right;
  bottom: inherit;
  display: none;

  .msc-l-dropdown__label {
    color: var(--britax-grey);
    margin-right: $search-result-container-sort-by-category-dropdown-label-margin-right;
    vertical-align: middle;
    font-weight: var(--britax-font-weight-bold);
  }

  .msc-l-dropdown {
    @include britax-font-content(
      var(--britax-font-weight-normal),
      var(--britax-body-font-size-s)
    );
    background: var(--msv-search-result-sort-bg);
    color: var(--britax-grey);
    border: solid 1px var(--britax-grey);
    padding: 3px;
    margin-top: -5px;
  }
}

.msc-product__text {
  display: none;
}

.ms-search-result-container__Products {
  margin-bottom: $search-result-container-products-margin-bottom;
  margin-top: $search-result-container-products-margin-top;
  overflow: hidden;
  clear: both;

  .ms-search-result-container__no-results-message {
    display: block;
    text-align: center;
    @media screen and (min-width: $britax-container-min-width-m) {
      margin-left: -36%;
    }
    h5.error-text {
      @include font-content(
        var(--msv-font-weight-normal),
        var(--msv-refiner-values-font-size),
        $msv-line-height-m
      );
      margin-bottom: 10px;
    }
    .go-back {
      @include grey-button();
    }
  }

  .list-unstyled {
    display: grid;
    grid-column-gap: $search-result-container-product-search-result-item-grid-column-gap;
    grid-template-columns: repeat(
      auto-fill,
      minmax($search-result-container-product-search-result-item-width, 1fr)
    );
  }
  .list-view-style {
    background-color: var(--britax-silver-grey);
    padding: 15px;
    .product-info {
      font-size: var(--britax-font-size-sm);
      padding-left: 15px;
      min-height: 250px;
      h3 {
        margin-bottom: 15px;
      }
      &__cart-btn {
        @include primary-button();
        font-size: var(--britax-font-size-xm);
        border-radius: 25px;
        text-align: center;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
        max-width: 150px;
        height: auto;
        padding: 8px 15px;
        font-size: var(--britax-body-font-size-xs);
      }
      &__detail-btn {
        @include primary-button();
        text-align: center;
        display: block;
        text-transform: uppercase;
        text-decoration: none;
        max-width: 150px;
        margin: 0 15px;
        height: auto;
        line-height: 22px;
        padding: 10px 15px;
        font-size: var(--britax-body-font-size-xs);
        float: left;
      }
      &__order-template-btn {
        color: var(--britax-grey);
        padding: 15px;
      }
      .product-description {
        padding-bottom: 10px;
        margin-bottom: 10px;
        .load-text {
          color: var(--britax-primary);
          font-weight: var(--britax-font-weight-bold);
          &:hover {
            color: var(--britax-grey);
          }
        }
      }
      .size-guide {
        border-top: solid 1px var(--britax-cold-grey);
        border-bottom: solid 1px var(--britax-cold-grey);
        padding: 15px 0;
        margin: 20px 0 40px;
        &:empty {
          border: 0;
        }
      }
      > ul {
        padding-left: 0;
        margin: 15px 0 0;
        > li {
          list-style: none;
          margin: 5px 0;
          font-size: var(--britax-body-font-size-xs);
          @include add-icon($msv-Selected, before);
          &:before {
            color: var(--britax-check-color);
            margin-right: 10px;
          }
        }
      }
      .msc-add-to-order-template-icon {
        display: block;
        margin: 15px;
      }
      .msc-add-to-order-template-icon {
        background: transparent;
        border: 0;
        margin: 15px;
        display: block;
        &:after {
          display: none;
        }
        &:before {
          content: "Add to Order Template";
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .ms-quickView {
        position: relative;
        top: initial;
        left: initial;
        margin: 0;
        display: inline-block;
        &__button {
          text-transform: uppercase;
          &:before {
            display: none;
          }
        }
      }
    }
    .ms-quickView {
      position: relative;
      top: initial;
      left: initial;
      margin: 0;
      display: inline-block;
      &__button {
        text-transform: uppercase;
        &:before {
          display: none;
        }
      }
    }
    .isretired {
      background: var(--britax-pending-color);
      color: var(--britax-white);
      padding: 5px 10px 6px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 5px;
      &:before {
        display: inline-block;
        content: "!";
        margin-right: 7px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background: var(--britax-white);
        color: var(--britax-pending-color);
        font-weight: var(--britax-font-weight-bold-full);
      }
    }
    .msc-empty_image {
      width: 100%;
      height: 300px;
      max-width: 300px;
      background-size: contain;
      background-position: center;
    }
  }
  .msc-waiting-circular {
    opacity: 0;
  }
}

.ms-product-search-result__item {
  padding: $search-result-container-product-search-result-item-padding;
  background-color: var(--britax-silver-grey);
  position: relative;
  margin: 0 0 15px;
  overflow: hidden;
  @media screen and (max-width: $britax-container-max-width-s) {
    margin-left: 0;
    margin-right: 0;
  }

  .msc-product {
    display: block;

    &__title,
    &__details {
      color: var(--britax-grey);
      text-align: center;
    }
    &__title {
      font-weight: var(--britax-font-weight-bold);
      font-size: var(--britax-font-size-xsml);
      margin-bottom: 15px;
      line-height: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      height: 58px;
    }
    &__btn {
      @include primary-button();
      font-size: var(--britax-font-size-xm);
      color: var(--britax-white);
      border-radius: 25px;
      text-transform: uppercase;
      max-width: 150px;
      margin: 15px auto 15px;
      height: auto;
      padding: 8px 15px;
      font-size: var(--britax-body-font-size-xs);
      &:hover {
        background-color: var(--britax-grey);
        border-color: var(--britax-grey);
      }
    }
    &__add-order-template {
      color: var(--britax-grey);
      font-size: var(--britax-font-size-xxs);
      margin-bottom: 15px;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-rating {
      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      &__star {
        color: var(--msv-refiner-rating-star);
      }

      &__half-star {
        &::after {
          color: var(--msv-refiner-rating-star);
        }
      }
    }

    .msc-empty_image {
      @include image-placeholder(268px);
      max-width: 100%;
      margin: auto;
      @media screen and (max-width: $britax-container-max-width-ipad) {
        @include image-placeholder(297px);
      }
      @media screen and (max-width: $britax-container-min-width-s) {
        @include image-placeholder(276px);
      }
    }
    &__image {
      padding: 20px;
    }
  }
  .msc-add-to-order-template-icon {
    color: var(--britax-grey);
    font-size: var(--britax-font-size-xxs);
    background: transparent;
    border: 0;
    margin: 10px 0 15px;
    &:after {
      display: none;
    }
    &:before {
      content: "Add to Order Template";
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .msc-price {
    font-weight: var(--britax-font-weight-bold);
    font-size: var(--britax-font-size-xm);
  }
  .msc-product__image img {
    width: 100%;
  }
  &:hover,
  &:focus {
    .ms-quickView {
      display: block;
      @media screen and (max-width: $britax-container-max-width-ipad) {
        display: none;
      }
    }
  }
  .isretired {
    background: var(--britax-pending-color);
    color: var(--britax-white);
    border-radius: 4px;
    display: inline-block;
    padding: 5px 15px;
    position: absolute;
    top: 23px;
    right: -36px;
    width: 170px;
    text-align: center;
    transform: rotate(35deg);
    &:before {
      display: inline-block;
      content: "!";
      margin-right: 7px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 50%;
      background: var(--britax-white);
      color: var(--britax-pending-color);
      font-weight: var(--britax-font-weight-bold-full);
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.ms-product-search-result__item a {
  .msc-product__title {
    @include font-content(var(--msv-font-weight-normal), $msv-line-height-m);
    color: var(--britax-primary);
    font-weight: var(--britax-font-weight-bold);
    font-size: var(--britax-font-size-xm);
    line-height: 23px;
    margin-bottom: $search-result-container-product-search-item-title-margin-bottom;
    margin-top: $search-result-container-product-search-item-title-margin-top;
    &:hover {
      color: var(--britax-grey);
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .msc-rating {
    display: block;
    margin-top: $search-result-container-product-search-item-rating-margin-top;
  }

  .msc-price {
    .msc-price__actual {
      @include font-content(
        var(--msv-font-weight-heavy),
        var(--msv-search-result-product-price-size),
        $msv-line-height-l
      );
      color: var(--msv-search-result-product-price-color);
      display: inline-block;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  &:focus {
    @include basic-outline-offset(
      $product-search-result_link__item-outline-width,
      $product-search-result_link__item-outline-style,
      $msv-black,
      $product-search-result_link__item-outline-offset
    );

    .msc-product__title {
      text-decoration: underline;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  &:hover {
    text-decoration: none;

    .msc-product__details {
      .msc-product__title {
        text-decoration: underline;
      }
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.ms-search-result-container__category-hierarchy {
  @include vfi();
}

nav.ms-search-result-container__pagination {
  width: 100%;
}

.msc-pagination {
  @include font-content(
    var(--msv-font-weight-normal),
    var(--msv-refiner-values-font-size),
    $msv-line-height-m
  );
  display: flex;
  list-style: none;
  padding-left: $search-result-container-pagination-padding-left;
  width: 100%;
}

ul.msc-pagination {
  display: flex;
  justify-content: center;
}

.previous.msc-page-item {
  margin-right: auto;

  .msc-pagination__prev {
    padding-left: 4px;
    display: inline-block;
  }

  .msc-page-link {
    color: var(--msv-search-result-pagination-color);
    font-size: var(--msv-search-result-pagination-size);
    display: block;
    padding: $search-result-container-page-item-page-link-padding;
    line-height: $search-result-container-page-item-page-link-line-height;

    &::before {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-left {
      @include add-icon($msv-ChevronLeft, before);
    }
  }
}

.msc-page-item {
  padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
  margin-left: auto;

  .msc-pagination__next {
    padding-right: 4px;
    display: inline-block;
  }

  .msc-page-link {
    color: var(--msv-search-result-pagination-color);
    font-size: var(--msv-search-result-pagination-size);
    display: block;
    padding: $search-result-container-page-item-page-link-padding;
    line-height: $search-result-container-page-item-page-link-line-height;
    padding-right: 4px;

    &::after {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-right {
      @include add-icon($msv-ChevronRight, after);
    }
  }
}

.msc-page-item.disabled {
  .msc-page-link {
    color: $msv-secondary;
    cursor: auto;
    pointer-events: none;
  }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.msc-page-link {
  color: var(--msv-search-result-pagination-color);
  font-size: var(--msv-search-result-pagination-size);
  display: block;
  padding: $search-result-container-page-item-page-link-padding;
  line-height: $search-result-container-page-item-page-link-line-height;

  @include vfi();
}

.active {
  text-decoration: underline;
}

.prev-text,
.next-text {
  padding: $search-result-prev-next-padding;
}

@media (max-width: $msv-breakpoint-l) {
  .ms-search-result-container {
    margin-top: $search-result-container-margin-top-margin-top-mobile;

    .ms-search-result-container__category-hierarchy {
      display: none;
    }

    .msc-button {
      background: none;
      border-style: none;
      float: right;
      font-weight: bold;
    }

    .msc-button:hover {
      text-decoration: underline;
    }

    .ms-product-search-result__item {
      display: inline-block;
      vertical-align: top;
    }
  }

  .ms-search-result-container__pagination {
    margin-bottom: $search-result-container-pagination-margin-bottom-mobile;
  }

  .ms-search-result-container__Products {
    margin-bottom: $search-result-container-products-margin-bottom-mobile;
  }

  .prev-text,
  .next-text {
    display: none;
  }
}

// modal
.msc-search-result-modal {
  .msc-modal__header {
    justify-content: space-around;
  }

  .msc-review-modal-body {
    height: $search-result-modal-body-height;
    overflow-y: auto;
  }

  .ms-sort-and-filter-modal-close.msc-btn {
    @include primary-button();
  }

  .ms-search-result-container__refiner-section {
    display: block;
    padding-right: $search-result-modal-refiner-section-padding-right;
    width: 100%;
  }
}

@media (max-width: $msv-breakpoint-l) {
  .ms-search-result-container__Sort-by-category {
    position: relative;
    top: $search-result-container-sort-by-category-top-mobile;
    right: auto;
    border-top: 1px solid var(--msv-refiner-border);
    border-bottom: 1px solid var(--msv-refiner-border);
    z-index: 1;
    background-color: var(--msv-bg-color);
  }

  .ms-sort-and-filter-modal-close.msc-btn {
    width: 100%;
  }
}

.ms-search-result-container {
  margin-top: 15px;
  &__title {
    color: var(--msv-search-result-font-color);
    font-size: var(--msv-search-result-font-size);
    padding-right: 5px;
    @media screen and (max-width: $britax-container-max-width-m) {
      display: none;
    }

    /* stylelint-disable no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-search-result__collection-title-prefix,
    .ms-search-result__collection-title-text {
      /* stylelint-enable no-descending-specificity -- Auto-suppressed. Please fix if possible. */

      @include font-content(
        var(--msv-font-weight-normal),
        var(--msv-search-result-heading-font-size),
        $msv-line-height-xl
      );
      color: var(--msv-search-result-heading-font-color);
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-search-result__collection-title-count {
      @include font-content(
        var(--msv-font-weight-normal),
        var(--msv-search-result-font-size),
        $msv-line-height-m
      );
      color: var(--msv-search-result-font-color);
    }
  }

  .msc-button {
    @include font-content(
      var(--msv-font-weight-bold),
      var(--msv-search-result-sort-font-size),
      $msv-line-height-m
    );
    color: var(--britax-grey);
    background-color: var(--britax-grey-2);
    border: solid 1px var(--britax-silver-grey);
    padding: 5px 15px;
  }

  .ms-feature-search-result {
    overflow: hidden;
    clear: both;
    .ms-feature-search-result__item {
      display: flex;
      height: auto;
      padding-bottom: 10px;

      .msc-feature-search-result__item__image {
        flex-grow: 1;
        height: auto;
        width: 315px;
      }

      .msc-feature-search-result__item__detail {
        flex-grow: 1;
        height: auto;
        position: relative;
        padding-left: 8%;
        text-align: left;
        width: 100%;
      }

      .msc-feature-search-result__item__detail h1 {
        @include font-content(
          var(--msv-font-weight-light),
          var(--msv-feature-title-desktop-size),
          $msv-line-height-xxl
        );
        color: var(--msv-feature-title-color);
        margin: 0;
        padding: 0;
        position: relative;
        top: 30%;
        margin-bottom: 8px;
      }

      .msc-feature-search-result__item__detail p {
        @include font-content(
          var(--msv-font-weight-bold),
          var(--msv-feature-heading-font-size),
          $msv-line-height-l
        );
        color: var(--msv-feature-heading-color);
        margin: 0;
        margin-bottom: 8px;
        padding: 0;
        position: relative;
        top: 30%;
      }
    }
  }

  @media (max-width: $msv-breakpoint-l) {
    .ms-feature-search-result {
      .ms-feature-search-result__item {
        .msc-feature-search-result__item__detail {
          p,
          h1 {
            top: 20%;
          }
        }
      }
    }
  }

  @media (max-width: $msv-breakpoint-m) {
    .ms-feature-search-result {
      .ms-feature-search-result__item {
        display: block;

        .msc-feature-search-result__item__image,
        .msc-feature-search-result__item__detail {
          width: 100%;
        }

        .msc-feature-search-result__item__image {
          margin-bottom: 10px;
        }

        .msc-feature-search-result__item__detail {
          padding-left: unset;

          h1 {
            @include font-content(
              var(--msv-font-weight-light),
              var(--msv-feature-title-mobile-size),
              $msv-line-height-xl
            );
          }
        }
      }
    }
  }
}

@media (min-width: $msv-breakpoint-xl) {
  .ms-search-result-container {
    .ms-product-search-result__item {
      .msc-product {
        .msc-empty_image {
          @include image-placeholder(275px);
          max-width: 100%;
          background-size: cover;
        }
      }
    }
  }
}

.category-banner {
  background-color: var(--britax-primary);
  padding: 5px 0 10px;
  .ms-content-block {
    max-height: 280px;
    min-height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
    &__image {
      img {
        width: 100%;
      }
    }
    &__details {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // width: 600px;
      // height: 120px;
      // text-align: center;
      // margin-left: -300px;
      // margin-top: -60px;
      color: var(--britax-white);
      .ms-content-block {
        &__title {
          // margin-bottom: 10px;
          position: relative;
          font-size: var(--britax-font-size-xxml);
          font-weight: var(--britax-font-weight-700);
          // padding-bottom: 5px;
          // &:after {
          //   content: "";
          //   background: var(--britax-white);
          //   width: 80px;
          //   height: 2px;
          //   display: inline-block;
          //   position: absolute;
          //   bottom: 0;
          //   left: 50%;
          //   margin-left: -40px;
          // }
        }
      }
    }
  }
  .ms-breadcrumb {
    margin-top: 0px;
  }
}
.result-container-slot {
  background-color: var(--britax-silver-grey);
  text-align: center;
  height: 46px;
  .ms-text-block {
    margin-top: 0;
    p {
      margin: 14px 0;
      font-size: 0;
    }
  }
  @media screen and (max-width: $britax-container-max-width-m) {
    display: none;
  }
}

.dropbtn {
  background-color: #4caf50;
  color: var(--britax-white);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $msv-snow;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: var(--britax-black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: $msv-white-smoke;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.grid-list-view {
  width: 100%;
  top: 0;
  right: 0;
  margin-top: -50px;
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  > span {
    display: inline-block;
    margin: 6px 0;
    color: var(--britax-grey);
    font-weight: var(--britax-font-weight-normal);
  }
  .grid-view-btn,
  .list-view-btn {
    background-color: transparent;
    border: 0;
    margin-top: 4px;
    text-decoration: none;
    &:before {
      @include britax-icon();
      content: $britax-icon-grid-view;
      color: var(--britax-white);
      font-size: var(--britax-font-size-xsml);
    }
    &.active {
      &:before {
        color: var(--britax-primary);
      }
    }
  }
  .list-view-btn {
    &:before {
      content: $britax-icon-list-view;
    }
    &:after {
      width: 1px;
      height: 17px;
      margin-left: 13px;
      background: var(--britax-grey);
      content: "";
      display: inline-block;
    }
  }
  .select-category {
    float: left;
    select {
      width: auto;
      max-width: 210px;
      height: 30px;
      margin-top: 2px;
      padding-right: 20px;
      background-color: transparent;
      border: 0;
      color: var(--britax-primary);
      -webkit-appearance: none;
      position: relative;
      z-index: 2;
      option {
        background-color: var(--britax-grey-2);
        color: var(--britax-primary);
        padding: 5px;
        &:first-child {
          display: none;
        }
      }
      &:focus-visible {
        outline: 0;
        color: var(--britax-primary);
      }
    }
    &:after {
      @include msv-icon();
      content: $msv-ChevronDown;
      margin-left: -12px;
    }
  }
  .pull-right {
    float: right;
    > span {
      float: left;
      margin: 4px 8px 0 0;
      font-weight: var(--britax-font-weight-bold);
    }
  }
  .size-description-content {
    width: calc(100% - 350px);
    float: left;
    text-align: center;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    .size__icon {
      margin-right: 10px;
      .infant-icon,
      .baby-icon,
      .child-icon {
        &:before {
          @include britax-icon();
          content: $britax-icon-infant;
          color: var(--britax-label);
          font-size: var(--britax-body-font-size-m);
          margin-right: 5px;
          display: inline-block;
          color: var(--britax-white);
        }
      }
      .baby-icon {
        &:before {
          content: $britax-icon-child;
          font-size: var(--britax-font-size-xsml);
          color: var(--britax-white);
        }
      }
      .child-icon {
        &:before {
          content: $britax-icon-child;
          font-size: var(--britax-font-size-xxml);
          color: var(--britax-white);
        }
      }
      .infant-icon.selected,
      .baby-icon.selected,
      .child-icon.selected {
        &:before {
          color: var(--britax-primary);
        }
      }
    }
    .size-content {
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
