$quickView-margin-top: 36px;
$quickView-label-margin-bottom: 5px;
$quickView-box-top-margin: 20px;
$quickView-box-bottom-margin: 20px;
$quickView-box-left-margin: 60px;
$quickView-store-description-margin-bottom: 8px;
$quickView-add-to-cart-extra-actions-margin: 8px;
$quickView-add-to-cart-extra-actions-width: 48px;
$quickView-product-status-padding-top: 18px;
$quickView-quantity-input: 5px;
$quickView-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width: 60%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-modal-content-background-color: #f8f7f6;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$quickView-custom-amount-input-width: 80px;
$quickView-custom-amount-input-padding: 5px;
$quickView-custom-btn-color: #000000de;
$quickView-custom-hover-btn-color: #000000;

//style presets
:root {
  --msv-quickView-btn-bg: #{$msv-white};
  --msv-quickView-btn-font-color: var(--msv-font-primary-color);
  --msv-quickView-btn-border-color: var(--msv-border-color);
  --msv-quickView-btn-font-size: var(--msv-body-font-size-l);

  //title
  --msv-quickView-title-font-size: var(--msv-body-font-size-xxl);
  --msv-quickView-title-font-color: var(--msv-font-primary-color);

  // rating star
  --msv-quickView-rating-star: var(--msv-font-primary-color);
  --msv-quickView-btn-extra-actions-color: var(--msv-accent-brand-color);

  //text
  --msv-quickView-text-font-size: var(--msv-body-font-size-m);
  --msv-quickView-text-font-color: var(--msv-font-primary-color);

  // primary button
  --msv-quickView-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-quickView-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-quickView-primary-btn-border: var(--msv-accent-brand-color);

  // secondary button
  --msv-quickView-secondary-btn-bg: var(
    --msv-secondary-button-background-color
  );
  --msv-quickView-secondary-btn-font-color: var(--msv-font-primary-color);
  --msv-quickView-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-quickView {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  margin-top: -40px;
  margin-left: -80px;
  overflow: hidden;
  display: none;

  &__content {
    width: 60%; //50%
    .per-unit-box {
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      .ms-quickView__add-to-cart-container {
        margin-top: 15px;
      }
      .msc-cart__btn-addcarttotemplate {
        background: transparent;
        border: 0;
        color: var(--britax-grey);
      }
    }
    .per-unit-box-quickview {
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      .ms-quickView__add-to-cart-container {
        margin-top: 15px;
      }
      .msc-cart__btn-addcarttotemplate {
        background: transparent;
        border: 0;
        color: var(--britax-grey);
      }
    }
    .pdp-quantity {
      overflow: hidden;
      .ms-quickView__quantity {
        width: 100%;
        overflow: hidden;
        border: 0;
        margin: 0 0 20px 0;
        img {
          max-width: 40px;
          height: auto;
          margin-right: 15px;
        }
        h5 {
          font-size: var(--britax-body-font-size-xs);
          font-weight: var(--britax-font-weight-bold);
          white-space: normal;
          margin: 0 0 5px;
          &.name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-height: 36px;
          }
        }
        .quantity-box {
          display: inline-block;
          border-radius: 4px;
          overflow: hidden;
          margin: 0 !important;
          background-color: var(--msv-bg-color);
          padding: 0 10px;
          height: 40px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          button {
            background-color: var(--britax-primary);
            border: 0;
            padding: 0;
            width: 18px;
            height: 18px;
            color: var(--msv-bg-color);
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            border-radius: 50%;
            span {
              position: relative;
              top: -1px;
            }
            &.disabled,
            &:disabled {
              background-color: var(--britax-silver-grey);
              &:hover {
                background-color: var(--britax-silver-grey);
              }
            }
            &:hover {
              background-color: var(--britax-grey);
            }
          }
          input {
            background-color: transparent;
            border: 0 !important;
            font-size: var(--britax-body-font-size-xxs);
            width: 35px;
            text-align: center;
            color: var(--britax-grey);
            -moz-appearance: textfield;
            &:focus,
            &:focus-within {
              outline-color: var(--britax-silver-grey);
            }
          }
        }
      }
      .viewmore {
        background-color: var(--britax-grey-2);
        overflow: hidden;
        padding: 15px 0;
        button {
          background: transparent;
          border: 0;
          padding: 0;
          color: var(--britax-primary);
          font-weight: var(--britax-font-weight-bold);
          &:hover,
          &:focus {
            text-decoration: underline;
            color: var(--britax-grey);
          }
        }
        &__price {
          color: var(--britax-primary);
          font-size: var(--britax-font-size-xm);
          font-weight: var(--britax-font-weight-bold);
          float: right;
          width: 22%;
        }
      }
      .quantity-box {
        display: inline-block;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 !important;
        background-color: var(--msv-bg-color);
        padding: 0 10px;
        height: 40px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        button {
          background-color: var(--britax-primary);
          border: 0;
          padding: 0;
          width: 18px;
          height: 18px;
          color: var(--msv-bg-color);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 50%;
          span {
            position: relative;
            top: -1px;
          }
          &:hover {
            background-color: var(--britax-grey);
          }
          &.disabled,
          &:disabled {
            background-color: var(--britax-silver-grey);
          }
        }
        input {
          background-color: transparent;
          border: 0 !important;
          font-size: var(--britax-body-font-size-xxs);
          width: 35px;
          text-align: center;
          color: var(--britax-primary);
          -moz-appearance: textfield;
          &:focus,
          &:focus-within {
            outline-color: var(--britax-grey);
          }
        }
      }
      ~ .price {
        font-size: var(--britax-font-size-sml);
        margin: 30px 0 0;
        font-weight: var(--britax-font-weight-bold);
      }
    }
    .product-variant-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      .blk {
        width: 21.33%;
        h2 {
          color: var(--britax-primary);
          text-transform: uppercase;
          font-size: 0.85rem;
        }
        &:first-of-type {
          width: 39%; //40%
        }
        &:last-of-type {
          margin-left: 20px;
        }
      }
    }
    .product-variant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .variant-blk {
        width: 21.33%;
        position: relative;
        &:first-of-type {
          width: 36%;
        }
        .status {
          font-size: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: inline-block;
          border: solid 2px var(--britax-white);
          position: absolute;
          top: 0;
          left: 0;
          &.AVAIL {
            background: var(--britax-success-color);
          }
          &.OOS {
            background: var(--britax-error-color);
          }
          &.LOW {
            background: var(--britax-pending-color);
          }
        }
        .msc-empty_image {
          max-width: 40px;
          height: 40px;
          background-size: 100%;
          margin-right: 15px;
          float: left;
        }
        h5 {
          font-size: var(--britax-body-font-size-xs);
          font-weight: var(--britax-font-weight-bold);
          white-space: normal;
          margin: 0 0 5px;
          &.name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-height: 36px;
          }
        }
      }
      .img-box {
        img {
          max-width: 40px;
          height: auto;
          margin-right: 15px;
          float: left;
        }
      }
    }
    .load-text {
      display: inline-block;
      font-weight: var(--britax-font-weight-bold);
      color: var(--britax-grey);
      font-size: var(--britax-body-font-size-xs);
      cursor: pointer;
    }
    .description-text {
      margin-bottom: 30px;
      line-height: var(--britax-line-height-m);
    }
    .isretired {
      background: var(--britax-pending-color);
      color: var(--britax-white);
      padding: 5px 15px 7px;
      border-radius: 4px;
      margin-bottom: 15px;
      display: inline-block;
      &:before {
        display: inline-block;
        content: "!";
        margin-right: 7px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background: var(--britax-white);
        color: var(--britax-pending-color);
        font-weight: var(--britax-font-weight-bold-full);
      }
    }
  }

  &__header {
    margin-bottom: 0;
  }

  &__button {
    font-size: var(--britax-font-size-sm);
    @include primary-button();
    line-height: 22px;
    padding: 10px;
    display: block;
    font-size: var(--britax-body-font-size-xs);
    &:hover {
      &::before {
        @include britax-icon();
        content: $britax-icon-quick-cart;
        font-size: var(--britax-font-size-xm);
        float: left;
        margin: 2px -5px 0 15px;
        color: var(--britax-white);
      }
    }
    &::before {
      @include britax-icon();
      content: $britax-icon-quick-cart;
      font-size: var(--britax-font-size-xm);
      float: left;
      margin: 2px -5px 0 15px;
    }
  }

  &__dialog {
    width: 1300px; //1200px
    height: 620px;
    @media screen and (min-width: $britax-container-min-width-xl) {
      max-width: 1200px;
    }
    @media screen and (min-width: $britax-container-min-width-l) {
      max-width: 1000px;
    }
    @media screen and (min-width: $britax-container-min-width-m) {
      max-width: 900px;
    }
    @media screen and (min-width: $britax-container-min-width-s) {
      max-width: 100%; //90%
    }

    .msc-modal__content {
      background: $msv-dialog-modal-content-background-color;
    }
  }

  &__add-to-cart-container {
    display: inline-block;
    padding: 10px 19px 12px 1px;

    .msc-add-to-cart {
      margin-left: 0;
    }
  }

  &__body {
    max-height: fit-content;
    display: flex;
    flex-flow: row;
    padding: 0 30px 30px;

    .msc-rating {
      &__count,
      &__star {
        color: var(--msv-quickView-rating-star);
      }

      &__half-star {
        &::after {
          color: var(--msv-quickView-rating-star);
        }
      }
    }

    .msc-price__actual {
      font-size: var(--britax-font-size-xsml);
      color: var(--britax-grey);
      font-weight: var(--britax-font-weight-700);
      line-height: var(--britax-line-height-sml);
    }

    .msc-price__strikethrough {
      @include font-content-xl(var(--msv-font-weight-light));
    }

    .ms-quickView__key_in_price {
      margin-top: 20px;

      .ms-quickView__key_in_price-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-quickView-title-font-color);
        display: block;
        margin-bottom: $quickView-label-margin-bottom;
      }

      .ms-quickView__key_in_price_custom-amount__input {
        @include form-control();
        -moz-appearance: textfield;
        padding: $quickView-custom-amount-input-padding;
        text-align: left;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
      }
    }

    .ms-text-block {
      margin-top: 0;
      margin-bottom: 1rem;

      @include font-content(
        var(--msv-font-weight-normal),
        var(--msv-quickView-text-font-size),
        $msv-line-height-m
      );
    }

    .product-add-to-cart {
      margin-top: 20px;

      .buttons {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      .msc-add-to-cart {
        @include primary-button(
          var(--msv-quickView-primary-btn-bg),
          var(--msv-quickView-primary-btn-font-color),
          var(--msv-quickView-primary-btn-border)
        );
        font-size: var(--msv-quickView-btn-font-size);
        line-height: $msv-line-height-l;
        flex-grow: 1;
      }

      .ms-wishlist-items__product-status {
        width: 100%;
        padding-top: $quickView-product-status-padding-top;
      }

      .msc-add-to-cart-extra-actions {
        @include font-glyph-standard();
        @include secondary-button(
          var(--msv-quickView-secondary-btn-bg),
          var(--msv-quickView-secondary-btn-font-color),
          var(--msv-quickView-secondary-btn-border)
        );
        color: var(--msv-quickView-btn-extra-actions-color);
        padding-left: 0;
        padding-right: 0;
        position: relative;
        min-width: $quickView-add-to-cart-extra-actions-width;
        width: $quickView-add-to-cart-extra-actions-width;
        flex: 0 0 $quickView-add-to-cart-extra-actions-width;
        margin-left: $quickView-add-to-cart-extra-actions-margin;

        &:not(:disabled):hover,
        &:not(:disabled):not(.disabled):active:focus {
          color: var(--msv-quickView-btn-extra-actions-color);
          border-color: transparent;
        }
      }
    }

    .msc-dropdown__select {
      @include form-control();
      width: 279px;
      height: 50px;
    }

    .quantity {
      .quantity-input {
        @include form-control();
        width: 159px;
        display: inline-block;
        margin-left: 8px;
        margin-right: 8px;
        -moz-appearance: textfield;
        padding: $quickView-quantity-input;
        vertical-align: bottom;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        &:disabled {
          /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
          background-color: #ffffff !important;
          /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
          border: 1px solid #d1d1d1 !important;
        }
      }

      .quantity__controls {
        padding: $quickView-incr-decr;
        padding-left: 0;
        padding-right: 0;
        background: var(--msv-quickView-btn-bg);
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid var(--msv-quickView-btn-border-color);
        min-width: auto;
        width: 48px;
        height: 48px;
        cursor: pointer;

        &:disabled {
          color: var(--britax-silver-grey);
          pointer-events: none;
        }
      }

      .increment {
        @include add-icon($msv-Add, after);
        color: var(--msv-quickView-btn-font-color);

        &.disabled {
          background-color: var(--britax-silver-grey);
          border-color: var(--britax-silver-grey);
        }

        &:hover,
        &:focus {
          background-color: var(--britax-grey);
          border: 1px solid var(--britax-grey);
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
          color: var(--msv-quickView-btn-font-color);
        }
      }

      .decrement {
        @include add-icon($msv-Remove, after);
        color: var(--msv-quickView-btn-font-color);

        &.disabled {
          background-color: var(--britax-silver-grey);
          border-color: var(--britax-silver-grey);
        }

        &:hover,
        &:focus {
          background-color: var(--britax-grey);
          border: 1px solid var(--britax-grey);
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
          color: var(--msv-quickView-btn-font-color);
        }
      }
    }
  }

  &__inventory-info {
    margin-top: $quickView-box-top-margin;
  }

  &__product-title {
    font-size: var(--britax-font-size-sml);
    color: var(--britax-primary);
    margin-bottom: 8px;
    line-height: var(--britax-body-font-size-xl);
    font-weight: var(--britax-font-weight-700);
    margin-top: 5px;
    margin-bottom: 8px;
  }

  &__inventory-label {
    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-body-font-size-l),
      $msv-line-height-m
    );
  }

  &__product-description {
    @include font-content(
      var(--msv-font-weight-light),
      var(--msv-quickView-text-font-size),
      $msv-line-height-m
    );
    color: var(--msv-quickView-text-font-color);
    margin-bottom: $quickView-box-bottom-margin;
    // margin-left: $quickView-box-left-margin;
    text-align: left;
  }

  &__configure {
    @include font-content-l(var(--msv-font-weight-normal));
  }

  &__dropdown {
    margin-top: 20px;

    &-quantity-label {
      @include font-content-l(var(--msv-font-weight-light));
      color: var(--msv-quickView-title-font-color);
    }
  }

  &__media-gallery {
    display: block;
    min-width: 259px;
    flex-grow: 1;
    flex-wrap: nowrap;
    width: 40%;
    margin-right: 40px;

    &__carousel,
    .msc-carousel {
      // height: 440px;
      // left: 60px;
      // right: -40px;
      // top: 15px;
      // width: 259px;
      // background-color: #FFFFFF;

      &__indicators {
        bottom: -80px;
        display: none;
      }
    }

    .ms-media-gallery__thumbnails-container {
      width: fit-content;
      height: 100px;

      .msc-ss-carousel {
        width: fit-content;
        height: fit-content;
        bottom: 426px;
      }

      .msc-ss-carousel-slide {
        display: block;
      }

      .ms-media-gallery__thumbnail-item:not(:last-child) {
        margin-bottom: 10px;
        margin-right: 20px;
      }

      .ms-media-gallery__carousel {
        margin-bottom: 0;
      }
    }
    .msc-carousel__inner {
      height: auto;
      .msc-empty_image {
        height: 500px;
        width: 100%;
        background-size: cover;
      }
    }
  }

  &__add-to-wishlist-container {
    display: inline-block;

    .msc-add-to-wishlist {
      width: 48px;
      height: 48px;
      margin: -10px;
    }
  }

  &__product-quantity-label {
    @include font-content-l(var(--msv-font-weight-light));
    color: var(--msv-quickView-title-font-color);
    display: block;
    margin-bottom: $quickView-label-margin-bottom;
  }

  @media (max-width: $msv-breakpoint-l) {
    &__media-gallery {
      display: block;
      min-width: $media-gallery-width;
      flex-grow: 1;
      flex-wrap: nowrap;
      padding-right: $gutter-width;
    }

    .ms-media-gallery {
      padding-bottom: 54px;

      .msc-carousel {
        height: 440px;
        left: 60px;
        right: -40px;
        top: 15px;

        &__indicators {
          bottom: -36px;
        }

        &__control {
          &__prev,
          &__next {
            bottom: -40px;
            height: auto;
            top: auto;
            width: auto;
          }

          &__prev__icon {
            @include add-icon($msv-ChevronLeft);
            margin-left: 0;
          }

          &__next__icon {
            @include add-icon($msv-ChevronRight);
            margin-right: 0;
          }
        }
      }
    }
  }

  //mobile vp

  @media (max-width: $msv-breakpoint-m) {
    flex-flow: column;
  }

  &__footer {
    display: none;
    .ms-quickView__product-add-to-cart {
      margin-left: calc(50% + 40px);
    }
  }
  &__seeDetailsbutton {
    @include grey-button();
    height: auto;
    font-size: var(--britax-body-font-size-s);
    text-transform: uppercase;
    padding: 9px 20px;
    margin-left: 10px;
  }

  &__atpCalculteButton {
    @include primary-button();
    height: auto;
    font-size: var(--britax-body-font-size-s);
    text-transform: uppercase;
    padding: 9px 20px;
    height: 100%;
    margin-left: 10px;
  }
}

.card-discount-explaination-simple-products {
  margin-top: 10px;
}

.card-discount-explaination {
  margin-top: 10px;
}
//custom CSS Start
.ms-buybox__product-tagline-plp {
  font-size: var(--britax-body-font-size-xs);
  text-align: left;
  font-weight: var(britax-font-weight-bold-full);
  color: var(--britax-grey);
  margin-top: $buybox-box-top-margin;
  margin-bottom: $buybox-box-bottom-margin;
}
//custom CSS end
