$britax-image-path: '../../../';
$default-empty-image-width: 150px;

@mixin image-placeholder-url($size) {
    background: url('#{$britax-image-path}placeholder.png') no-repeat center;
}

@mixin image-placeholder($size) {
    @include image-placeholder-url($size);
    height: $size;
    width: $size;
}

.msc-empty_image {
    @include image-placeholder($default-empty-image-width);
}
