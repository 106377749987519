$msv-order-history-padding-bottom: 28px;
$msv-order-history-heading-margin-bottom: 8px;
$msv-order-history-header-padding-bottom: 20px;
$msv-order-history-order-count-margin-left: 5px;
$msv-order-history-sales-order-padding-top: 40px;
$msv-order-history-sales-order-padding-bottom: 40px;
$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-receipt-id-padding-right: 5px;
$msv-details-order-information-channel-name-margin-bottom: 4px;
$msv-order-history-sales-lines-margin-top: 8px;
$msv-order-history-sales-line-picture-margin-right: 12px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 81px;
$msv-order-history-sales-line-picture-height: 81px;
$msv-order-history-empty-image-width: 81px;
$msv-order-history-btn-order-details-margin-top: 20px;
$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-order-history-group-delivery-heading-margin-right: 3px;
$msv-order-history-btn-keep-shopping-margin-top: 20px;
$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-btn-more-margin-top: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;

//style presets
:root {
	--msv-order-history-border: #{$msv-gray-300};

	// heading
	--msv-order-history-heading-font-color: var(--msv-font-primary-color);
	--msv-order-history-heading-font-size: var(--msv-body-font-size-xl);

	// title
	--msv-order-history-title-font-color: var(--msv-font-primary-color);
	--msv-order-history-title-font-size: var(--msv-body-font-size-l);

	// text
	--msv-order-history-text-font-color: var(--msv-font-primary-color);
	--msv-order-history-text-font-size: var(--msv-body-font-size-m);

	// secondary button
	--msv-order-history-secondary-btn-bg: var(
		--msv-secondary-button-background-color
	);
	--msv-order-history-secondary-btn-font-color: var(--msv-font-primary-color);
	--msv-order-history-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-order-history {
	padding-bottom: $msv-order-history-padding-bottom;

	&__heading {
		font-weight: var(--britax-font-weight-bold);
		font-size: var(--britax-font-size-sml-32);
		line-height: initial;
		text-transform: none;
		color: var(--britax-primary);
		display: inline-block;
		margin-top: $msv-order-history-heading-margin-bottom;
		text-transform: capitalize;
	}

	&__header {
		margin: 30px 0 15px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex-grow: 1;
		.heading-count {
			flex-grow: 1;
		}
		.search-order {
			margin-right: 15px;
			input {
				border: solid 1px var(--britax-silver-grey);
				height: 48px;
				width: 100%;
				min-width: 230px;
				padding: 10px 35px 10px 15px;
				font-size: var(--britax-font-size-s);
				color: var(--britax-primary);
				&:focus,
				&:focus-visible {
					border-color: var(--britax-primary);
				}
				::placeholder {
					color: var(--britax-grey);
				}

				:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: var(--britax-grey);
				}

				::-ms-input-placeholder {
					/* Microsoft Edge */
					color: var(--britax-grey);
				}
			}
			button {
				background: none;
				border: 0;
				margin-left: -35px;
				color: var(--britax-primary);
				@include add-icon($msv-Search);
			}
		}
	}
	&__row {
		background-color: var(--britax-grey-2);
		border-radius: 4px;
		margin: 10px 0;
		padding: 15px 15px 20px;
		table {
			th,
			td {
				width: 220px;
				padding-right: 15px;
				@media screen and (max-width: $britax-container-max-width-l) {
					width: 180px;
				}
				&:nth-last-child(2) {
					width: 300px;
					@media screen and (max-width: $britax-container-max-width-l) {
						width: 250px;
					}
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
		.product-images {
			padding: 10px 0 0 10px;
			white-space: nowrap;
			overflow-x: auto;
			.product-image {
				width: 75px;
				height: 75px;
				border-radius: 50%;
				margin-left: -10px;
				background-color: var(--britax-cold-grey);
				float: left;
				background-size: cover;
			}
		}
		.status {
			font-weight: var(--britax-font-weight-normal);
			color: var(--britax-white);
			display: inline-block;
			padding: 4px 15px 5px;
			border-radius: 25px;
			text-transform: capitalize;
			&.processing,
			&.loading {
				background-color: rgba(255, 127, 10, 0.11);
				color: var(--britax-pending-color);
			}
			&.pickedUp,
			&.shipped,
			&.readyForPickup,
			&.invoiced {
				background-color: var(--britax-success-bg-color);
				color: var(--britax-success-font-color);
			}
			&.canceled {
				background-color: var(--britax-danger-bg-color);
				color: var(--britax-danger-font-color);
			}
		}
		@media only screen and (max-width: 760px),
			(min-device-width: 768px) and (max-device-width: 1024px) {
			table {
				th,
				td {
					width: auto;
					&:nth-last-child(2) {
						width: auto;
					}
				}
				td {
					padding-top: 7px;
					padding-bottom: 7px;
				}
			}
			padding-top: 10px;
			/* Force table to not be like tables anymore */
			table,
			thead,
			tbody,
			th,
			td,
			tr {
				display: block;
			}

			/* Hide table headers (but not display: none;, for accessibility) */
			thead tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
			tr:first-child {
				font-size: 0px;
				line-height: 0;
				border: none;
			}
			td {
				/* Behave  like a "row" */
				border: none;
				border-bottom: 1px solid #eee;
				position: relative;
				padding-left: 150px;
			}
			.status {
				padding: 15px;
				font-size: 14px;
			}

			td:before {
				/* Now like a table header */
				position: absolute;
				/* Top/left values mimic padding */
				left: 6px;
				width: 45%;
				padding-right: 10px;
				white-space: nowrap;
			}

			/*
            Label the data
            */
			td:nth-of-type(1):before {
				content: "Purchase";
				font-weight: bold;
			}
			td:nth-of-type(2):before {
				content: "Order number";
				font-weight: bold;
			}
			td:nth-of-type(3):before {
				content: "Date";
				font-weight: bold;
			}
			td:nth-of-type(4):before {
				content: "Confirmation ID";
				font-weight: bold;
			}
			td:nth-of-type(5):before {
				content: "Address";
				font-weight: bold;
			}
			td:nth-of-type(6):before {
				content: "Action";
				font-weight: bold;
			}
		}
	}
	&__btn-order-details.msc-btn {
		background-color: transparent;
		border: 0;
		padding: 0;
		height: auto;
		margin: 0;
		text-align: left;
		color: var(--britax-primary);
		font-size: var(--britax-font-size-sm);
		&:hover,
		&:focus {
			background-color: transparent;
			text-decoration: underline;
			color: var(--britax-grey);
		}
		&:after {
			@include msv-icon();
			content: $msv-ChevronRight;
			font-size: var(--britax-body-font-size-xxs);
			margin-left: 8px;
		}
	}

	&__ms-order-history-filter {
		@media screen and (min-width: $msv-breakpoint-m) {
			float: right;
			button {
				border: solid 1px var(--britax-silver-grey);
				font-size: var(--britax-font-size-sm);
				color: var(--britax-primary);
				padding: 10px 15px;
				&:focus {
					border: solid 1px var(--britax-silver-grey);
				}
			}
		}

		&__expand-filter-button {
			background-color: unset;

			&::after {
				@include msv-icon();
				content: $msv-ChevronDown;
				font-size: var(--msv-header-font-size);
				text-align: center;
				vertical-align: text-bottom;
				line-height: 1.2;
				margin: 0 8px;
				color: var(--britax-primary);
			}

			&:hover,
			&:hover::after {
				color: var(--britax-grey);
			}
		}

		&__expand-filter-button[aria-expanded="true"] {
			&::after {
				content: $msv-ChevronUp;
			}
		}

		.msc-popover {
			background-color: var(--britax-white);
			background-clip: padding-box;
			box-shadow: 0px 0px 3px var(--britax-cold-grey);
			display: block;
			left: 0;
			max-width: $account-links-max-width;
			position: absolute;
			top: 0;
			word-wrap: break-word;
			z-index: 1060;

			a,
			button {
				align-items: center;
				display: flex;
				font-size: 16px;
				line-height: 20px;
				padding: 0 20px;
				height: 48px;
				cursor: pointer;
				border: 0;
				width: 100%;
				color: var(--britax-primary);
				background: var(--britax-white);
				border-bottom: solid 1px var(--britax-grey-2);
				text-align: left;
				&:hover {
					text-decoration: none;
					color: var(--britax-grey);
				}
			}

			.msc-btn {
				color: var(--msv-header-font-color);
				font-weight: var(--msv-font-weight-normal);
				height: $popover-menu-item-height;
			}

			.msc-arrow {
				display: block;
				height: 0.5rem;
				margin: 0 0.3rem;
				position: absolute;
				top: calc((0.5rem + 1px) * -1);
				width: 1rem;

				&::before,
				&::after {
					border-color: transparent;
					border-style: solid;
					border-width: 0 0.5rem 0.5rem 0.5rem;
					content: "";
					display: block;
					position: absolute;
				}

				&::before {
					border-bottom-color: var(--britax-cold-grey);
					top: 0;
				}

				&::after {
					border-bottom-color: var(--msv-border-color);
					top: 1px;
				}
			}
		}
	}

	&__order-count {
		display: inline-block;
		margin-left: $msv-order-history-order-count-margin-left;
	}

	&__sales-order {
		@include font-content-m();
		padding-top: $msv-order-history-sales-order-padding-top;
		padding-bottom: $msv-order-history-sales-order-padding-bottom;
		position: relative;
		border-bottom: 1px solid var(--msv-order-history-border);
	}

	&__order-information-channel-name {
		color: var(--msv-order-history-title-font-color);
		display: block;
		font-size: var(--msv-order-history-title-font-size);
		line-height: $msv-line-height-l;
		margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
	}

	&__order-information-sales-id,
	&__order-information-receipt-id,
	&__order-information-channel-reference-id {
		display: block;
	}

	&__sales-lines {
		margin-top: $msv-order-history-sales-lines-margin-top;
		display: flex;
		flex-wrap: wrap;
	}

	&__sales-line-picture {
		margin-right: $msv-order-history-sales-line-picture-margin-right;
		margin-top: $msv-order-history-sales-line-picture-margin-top;
		width: $msv-order-history-sales-line-picture-width;
		height: $msv-order-history-sales-line-picture-height;
	}

	&__btn-order-details {
		@include secondary-button(
			$bg-color: var(--msv-order-history-secondary-btn-bg),
			$color: var(--msv-order-history-secondary-btn-font-color),
			$border-color: var(--msv-order-history-secondary-btn-border)
		);
		margin-top: $msv-order-history-btn-order-details-margin-top;
	}

	&__order-information-created-date {
		padding-right: $msv-order-history-order-information-created-date-padding-right;
		border-right: 1px solid var(--msv-order-history-border);
	}

	&__order-information-count {
		display: inline-block;
		padding-right: $msv-order-history-order-information-count-padding-right;
		padding-left: $msv-order-history-order-information-count-padding-left;
		border-right: 1px solid var(--msv-order-history-border);
	}

	&__order-information-amount {
		padding-left: $msv-order-history-order-information-amount-padding-left;
	}

	&__groups {
		display: flex;
	}

	&__empty-message,
	&__alert {
		display: block;
		margin-top: $msv-order-history-alert-margin-top;
		margin-bottom: $msv-order-history-alert-margin-bottom;
	}

	&__order-information-sales-id,
	&__order-information-receipt-id,
	&__order-information-channel-reference-id,
	&__order-information-created-date,
	&__order-information-count,
	&__order-information-amount {
		font-size: var(--msv-order-history-text-font-size);
		color: var(--msv-order-history-text-font-color);
	}

	@media screen and (max-width: $msv-breakpoint-m) {
		&__btn-order-details {
			position: unset;
			width: 100%;
		}

		&__btn-keep-shopping {
			width: 100%;
		}
	}

	.msc-empty_image {
		@include image-placeholder($msv-order-history-empty-image-width);
	}

	&__btn-keep-shopping {
		margin-top: $msv-order-history-btn-keep-shopping-margin-top;

		@include primary-button();
	}

	&__btn-more {
		&.is-busy {
			@include add-spinner(before);
		}

		margin-top: $msv-order-history-btn-more-margin-top;

		@include primary-button();
		&:focus {
			background-color: var(--britax-primary);
			color: var(--britax-white);
			border-color: var(--britax-primary);
		}
	}

	&__btn-view-detail-buybox {
		&.is-busy {
			@include add-spinner(before);
		}

		@include primary-button();
		&:focus {
			background-color: var(--britax-primary);
			color: var(--britax-white);
			border-color: var(--britax-primary);
		}
	}
}
