// Style preset
:root {
    // rows
    --msv-product-specification-row-odd-color: #{$msv-gray-100};
    --msv-product-specification-row-even-color: #{$msv-white};
    --msv-product-specification-border: #{$msv-gray-300};

    // heading
    --msv-product-specification-heading-font-color: var(--msv-font-primary-color);
    --msv-product-specification-heading-font-size: var(--msv-body-font-size-xl);

    // body
    --msv-product-specification-title-font-color: #{$msv-gray-900};
    --msv-product-specification-text-font-color: var(--msv-font-primary-color);
    --msv-product-specification-body-font-size: var(--msv-body-font-size-l);
}

.ms-product-specification {
    &__heading {
        @include font-content(var(--msv-font-weight-normal),
        var(--msv-product-specification-heading-font-size), $msv-line-height-xl);
        color: var(--msv-product-specification-heading-font-color);
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &__table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }

    &__table-row {
       
        background-color: var(--msv-product-specification-row-even-color);
        border-top: 1px solid var(--msv-product-specification-border);

    &:last-child {
        border-bottom: 1px solid var(--msv-product-specification-border);
        
    }

    th {
        @include font-content(var(--msv-font-weight-light), var(--msv-product-specification-body-font-size), $msv-line-height-l);
        color: var(--msv-product-specification-title-font-color);
        padding: .75rem;
        text-align: left;
        
    }

    td {
        @include font-content(var(--msv-font-weight-light), var(--msv-product-specification-body-font-size), $msv-line-height-l);
        color: var(--msv-product-specification-title-font-color);
        padding: .75rem;
        }
    }
}
