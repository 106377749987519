$msv-checkout-guest-profile-input-margin-top: 4px;
$msv-checkout-guest-profile-input-height: 32px;
$msv-checkout-guest-profile-input-label-width: 100%;
$msv-checkout-guest-profile-input-text-max-width: 610px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;

.ms-checkout-guest-profile {
    &__add-contact-info {
        width: 100%;
    }

    &__input {
        display: flex;
        flex-wrap: wrap;
    }

    &__selected-email {
        font-size: var(--britax-font-size-sm);
        font-weight: var(--britax-font-weight-normal);
        line-height: var(--britax-line-height-xs);
        color: var(--britax-grey);
    }

    &__input-text {
        display: flex;
        border: 1px solid rgba(98,108,118,0.18);
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 24px;
        width: 100%;
        height: 50px;
        font-size: var(--britax-font-size-sm);
        font-weight: var(--britax-font-weight-normal);
        line-height: var(--britax-line-height-xs);
        color: var(--britax-grey);
        padding: 5px 15px;
        &:focus{
            outline: none;
        }
        max-width: $msv-checkout-guest-profile-input-text-max-width;
    }

    &__input-label {
        font-size: var(--britax-font-size-sm);
        font-weight: var(--britax-font-weight-normal);
        line-height: var(--britax-line-height-xs);
        color: var(--britax-grey);
    }

    &__input-error {
        @include form-input-error-message();
        display: block;
        width: 100%;
    }
}
