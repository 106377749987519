$product-collection-item-width: calc(20% - 20px);
$product-collection-carousel-padding-top: 15px;
$product-collection-margin-bottom-mobile: 48px;
$product-collection-margin-bottom-desktop: 60px;
$product-collection-heading-padding-right: 100px;
$product-collection-heading-margin-top: 20px;
$product-collection-heading-margin-bottom: 8px;
$product-collection-item-padding: 5px 30px 24px 1px;
$product-collection-item-margin: 0 10px;
$product-collection-price-strikethrough-line-height: $msv-line-height-m;
$product-collection-tooltip-left: 0;
$product-collection-tooltip-top: 0;
$product-collection-tooltip-inner-margin-top: -29px;
$product-collection-tooltip-inner-max-width: 200px;
$product-collection-tooltip-inner-padding: 4px 8px;
$product-collection-transparent: transparent;

//style presets
:root {
	--msv-product-collection-font-family: #{$msv-primary-font-family};

	// heading
	--msv-product-collection-heading-font-size: var(--msv-heading-font-size-s);
	--msv-product-collection-heading-font-color: var(--msv-font-primary-color);
	--msv-product-collection-text-size: var(--msv-body-font-size-m);
	--msv-product-collection-text-color: var(--msv-font-primary-color);

	// rating star
	--msv-product-collection-rating-star: var(--msv-font-primary-color);

	//flipper
	--msv-product-collection-flipper-bg: #{$product-collection-transparent};
	--msv-product-collection-flipper-font-color: var(--msv-font-primary-color);
	--msv-product-collection-flipper-border: #{$product-collection-transparent};

	// Tooltip
	--msv-product-collection-tooltip-bg: var(--msv-font-primary-color);
	--msv-product-collection-tooltip-font-color: var(--msv-font-secondary-color);
}

.ms-product-collection {
	margin-bottom: $product-collection-margin-bottom-mobile;

	@media (min-width: $msv-breakpoint-m) {
		margin-bottom: $product-collection-margin-bottom-desktop;
	}

	&__heading {
		@include font-content(
			var(--britax-font-weight-bold-full),
			var(--britax-body-font-size-xs),
			$britax-line-height-xs
		);
		color: var(--britax-primary);
		text-transform: capitalize;
		padding-right: $product-collection-heading-padding-right;
		margin-top: 30px;
	}

	&__item {
		display: inline-block;
		max-width: none;
		margin: $product-collection-item-margin;
		overflow: hidden;
		text-align: left;
		vertical-align: top;
		width: $product-collection-item-width;
		position: relative;
		background-color: var(--britax-bg-grey);
		@media screen and (max-width: $britax-container-max-width-l) {
			width: calc(33.33% - 20px);
		}
		@media screen and (max-width: $britax-container-max-width-m) {
			width: calc(50% - 20px);
		}
		@media screen and (max-width: $britax-breakpoint-mbl-sm) {
			width: 100%;
			margin: 0;
		}

		&:hover,
		&:focus {
			.ms-quickView {
				display: block;
				@media screen and (max-width: $britax-container-max-width-ipad) {
					display: none;
				}
			}
		}
		.msc-add-to-cart {
			position: absolute;
			top: 15px;
			right: 30px;
			font-size: 0;
			background: transparent;
			border: 0;
			color: var(--britax-grey);
			min-width: auto;
			padding: 5px;
			&:after {
				font-family: "britax";
				content: $britax-icon-cart;
				font-size: var(--britax-font-size-xxml);
			}
		}
		.isretired {
			background: var(--britax-pending-color);
			color: var(--britax-white);
			border-radius: 4px;
			display: inline-block;
			padding: 5px 15px;
			position: absolute;
			top: 23px;
			right: -36px;
			width: 170px;
			text-align: center;
			transform: rotate(35deg);
			&:before {
				display: inline-block;
				content: "!";
				margin-right: 7px;
				width: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				border-radius: 50%;
				background: var(--britax-white);
				color: var(--britax-pending-color);
				font-weight: var(--britax-font-weight-bold-full);
			}
		}
	}

	.msc-ss-carousel {
		padding-top: $product-collection-carousel-padding-top;

		.msc-flipper {
			color: var(--msv-product-collection-flipper-font-color);
			background-color: var(--msv-product-collection-flipper-bg);
			border: 1px solid var(--msv-product-collection-flipper-border);
			font-size: var(--britax-font-size-xxml);
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -24px;
			color: var(--britax-primary);
			@media screen and (max-width: $britax-container-max-width-l) {
				left: 0;
				background-color: var(--britax-primary);
			}
			&.msc-ss-carousel__flipper--next {
				right: 0;
				left: initial;
				color: var(--britax-primary);
				@media screen and (max-width: $britax-container-max-width-l) {
					right: 0;
					background-color: var(--britax-primary);
				}
			}
			&:hover,
			&:focus {
				color: var(--britax-grey);
				border: 0;
				outline: 0;
			}
		}
	}

	.msc-product {
		@include vfi();
		overflow: hidden;
		display: block;
		color: var(--britax-primary);

		&__image {
			padding: 15px;
			img {
				margin: auto;
			}
		}

		&__details {
			padding: 0 10px;
			text-align: center;
			.msc-add-to-order-template-icon {
				background: transparent;
				border: 0;
				margin: 10px 0;
				&:after {
					display: none;
				}
				&:before {
					content: "Add to Order Template";
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}

		&__btn {
			@include primary-button();
			text-decoration: none;
			max-width: 150px;
			margin: 15px auto 15px;
			height: auto;
			padding: 8px 15px;
			font-size: var(--britax-body-font-size-xs);
			text-decoration: none;
			&:hover {
				background-color: var(--britax-primary);
			}
		}

		&__add-order-template {
			color: var(--britax-grey);
			font-size: var(--britax-font-size-sm);
		}

		.msc-product__title {
			font-size: var(--britax-font-size-sm);
			font-weight: var(--britax-font-weight-700);
			color: var(--britax-primary);
			text-decoration: none;
			margin-top: 0;

			&:hover {
				text-decoration: underline;
				color: var(--britax-grey);
			}
		}
		&:hover {
			text-decoration: none;
		}
	}

	.msc-price {
		color: var(--britax-grey);
		display: inline-block;
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
		text-transform: none;

		&__strikethrough {
			font-size: var(--britax-font-size-xsml);
			line-height: $product-collection-price-strikethrough-line-height;
		}

		&__actual {
			font-weight: var(--britax-font-weight-bold);
		}
	}

	.msc-rating {
		&__star,
		&__count {
			color: var(--msv-product-collection-rating-star);
			border-bottom: none;
		}

		&__half-star {
			&::after {
				color: var(--msv-product-collection-rating-star);
			}
		}
	}

	.msc-tooltip {
		display: block;
		left: $product-collection-tooltip-left;
		position: absolute;
		top: $product-collection-tooltip-top;

		.msc-tooltip-inner {
			background-color: var(--msv-product-collection-tooltip-bg);
			color: var(--msv-product-collection-tooltip-font-color);
			font-size: var(--msv-body-font-size-s);
			margin-top: $product-collection-tooltip-inner-margin-top;
			max-width: $product-collection-tooltip-inner-max-width;
			padding: $product-collection-tooltip-inner-padding;
			text-align: center;
		}

		&.msc-bs-tooltip-bottom {
			padding: $tooltip-arrow-height 0;

			.msc-tooltip-inner {
				margin-top: 0;
			}
		}
	}
}
