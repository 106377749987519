$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-text-align: center;
$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: 100%;
$msv-checkout-button-border-radius: 2px;
$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 100px;
$cart-empty-image-width-m: 80px;
$summary-min-width: 320px;
$summary-max-width: 480px;
$cart-heading-bottom: 20px;
$cart-heading-top: 32px;
$cart-cartline-wrapper-grp-padding: 32px;
$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;
$cart-cartline-line-padding-right: 20px;
$cart-content-margin-left: 200px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0;
$cart-cartline-item-content-margin-left-m: 100px;
$cart-promo-code-input-width: 170px;
$cart-promo-code-input-width-m: 150px;
$cart-order-summary-padding-m: 20px;
$cart-promo-code-input-height: 50px;
$msv-promotion-icon-right-margin: 8px;
$cart-promotion-padding: 20px;
$cart-promotion-margin-bottom: 5px;
$cart-promotion-popup-close-button-padding: 5px;
$cart-lines-group-heading-padding-top: 12px;
$cart-lines-group-heading-padding-left: 76px;
$cart-line-group-extra-action-margin-top: 75px;
$cart-line-group-extra-action-margin-left: 200px;

//style presets
:root {
  // heading
  --msv-cart-heading-font-size: var(--msv-heading-font-size-s);
  --msv-cart-heading-font-color: var(--msv-font-primary-color);
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout {
  @include button-default();
  @include secondary-button();
  @include primary-button(
    var(--msv-cart-primary-btn-bg),
    var(--msv-cart-primary-btn-font-color),
    var(--msv-cart-primary-btn-border)
  );
  align-items: $msv-checkout-button-align-items;
  display: flex;
  justify-content: $msv-checkout-button-justify-content;
}

.msc-promo-code__apply-btn {
  font-size: var(--britax-body-font-size-xxs);
  color: var(--britax-primary);
  background: none;
  font-weight: var(--britax-font-weight-normal);
  padding: 0px 10px;
  height: auto;
  &::after {
    @include msv-icon();
    content: $msv-ChevronRight;
    display: inline-flex;
    margin-left: 10px;
    text-align: right;
  }
  &:disabled {
    color: var(--britax-grey);
  }
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
  @include primary-button();
  margin: $msv-checkout-button-margin;
  width: $msv-checkout-button-width;
}

.msc-cart__btn-guestcheckout {
  @include button-default();
  @include secondary-button(
    var(--msv-cart-secondary-btn-bg),
    var(--msv-cart-secondary-btn-font-color),
    var(--msv-cart-secondary-btn-border)
  );
}

.msc-cart__btn-backtoshopping {
  @include button-link(var(--msv-cart-btn-link-color));
}
.drawer__button {
  display: none;

  //   background: linear-gradient(
  //     249.47deg,
  //     rgba(106, 160, 183, 0.1) 13.73%,
  //     rgba(255, 127, 10, 0.1) 110.11%
  //   );
  border-radius: 4px;
  width: 400px;
  margin-top: 35px;
  height: 62px;
  float: right;
  //   &:hover {
  //     background: linear-gradient(
  //       249.47deg,
  //       rgba(106, 160, 183, 0.1) 13.73%,
  //       rgba(255, 127, 10, 0.1) 110.11%
  //     );
  //   }
  .ms-quick-order__heading {
    font-size: var(--britax-body-font-size-xs);
    font-weight: var(--britax-font-weight-normal);
    color: var(--britax-grey);
  }
  .ms-quick-order__description {
    display: none;
  }
}

.ms-cart {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  .msc-cart__heading {
    // @include font-content(var(--msv-font-weight-normal),
    // var(--msv-cart-heading-font-size), $msv-line-height-xl);
    color: var(--britax-grey);
    font-size: var(--britax-font-size-xm);
    line-height: var(--britax-line-height-m);
    font-weight: var(--britax-font-weight-normal);
    // color: var(--msv-cart-heading-font-color);
    flex: 0 0 100%;
    margin-bottom: $cart-heading-bottom;
    margin-top: $cart-heading-top;
  }

  .msc-cart__promotion {
    width: 100%;
    background-color: $msv-white;
    padding: $cart-promotion-padding;
    margin-bottom: $cart-promotion-margin-bottom;

    &_link {
      margin-left: 8px;
      text-decoration: underline;
      cursor: pointer;
    }

    .msi-promotion-icon {
      margin-right: $msv-promotion-icon-right-margin;

      @include add-icon($msv-DollarSign, before);
      display: none;
    }

    &-popup {
      position: absolute;
      width: auto;
      height: auto;
      z-index: 2;
      background-color: $msv-white;
      left: 0;

      &_content {
        color: var(--msv-font-primary-color);
        background-clip: padding-box;
        background-color: var(--msv-bg-color);
        border: 1px solid $msv-gray-400;
        outline: 0;
        pointer-events: auto;
        position: relative;
        width: 400px;
        min-width: 335px;
        padding: 20px 10px;

        .msc-cart__promotion-popup_title {
          @include font-content-xl();
          margin: 0;
          text-transform: none;
        }

        .msc-cart__promotion-popup_close-button {
          @include add-icon($msv-x-shape);
          @include vfi();
          background-color: transparent;
          border: 0;
          color: var(--msv-font-primary-color);
          font-size: 20px;
          padding: $cart-promotion-popup-close-button-padding;
          position: absolute;
          right: 15px;
          text-shadow: 0 1px 0 var(--msv-bg-color);
          top: 22px;
        }

        ul {
          list-style: none;
          padding: 0;

          .msc-cart__promotion-popup_name {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    .msc-cart__promotion {
      &-popup {
        &_content {
          width: 335px;
        }
      }
    }
  }

  .msc-cartline-wraper {
    border-top: 1px solid var(--msv-cart-border);
    flex: 1;
    margin-bottom: $cart-cartline-wrapper-margin-bottom;
    margin-right: $cart-cartline-wrapper-margin-right;
    @media screen and (max-width: $britax-container-max-width-m) {
      margin-right: 0;
    }
    &__labels {
      padding: 15px 0 10px;
      display: flex;
      label {
        width: 21.66%;
        &:last-of-type {
          width: 10%;
        }
      }
    }
    .msc-cartline-wraper__labels__name {
      padding: 0 0 0 15px;
      width: 43%;
    }
  }

  .msc-cart-line {
    position: relative;
    display: flex;
    .mobile-label {
      display: none;
    }
    &__product-image {
      width: 42.6%;
      flex-direction: row;
      margin: 0 5px 0 0;
      display: flex;
      @media (max-width: $britax-container-max-width-s) {
        width: 100%;
      }
      img {
        width: 100px;
      }
      .msc-empty_image {
        @include image-placeholder($cart-empty-image-width);
        background-size: cover;
      }
      .product-info {
        margin-left: 10px;
        width: calc(100% - 110px);
        .msc-cart-line__product-variant-color {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          padding-bottom: 2px;
        }
        a.msc-cart-line__product-title {
          width: 100%;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          white-space: normal;
          max-height: 55px;
          min-height: 30px;
        }
      }
    }
    .small-blks {
      width: 21.66%;

      &:last-of-type {
        width: 10%;
      }
      .quantity {
        border: 1px solid var(--msv-cart-border);
        border-radius: 3px;
        padding: 5px 10px;
        display: inline-flex;
        align-items: center;
        height: 40px;
        z-index: 10;
        position: relative;
        @media screen and (max-width: $britax-container-max-width-s) {
          height: 32px;
        }

        .decrement {
          color: var(--britax-white);
          font-size: var(--britax-font-size-xsml);
          background-color: var(--britax-primary);
          border: 1px solid var(--britax-primary);
          height: 16px;
          width: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          position: relative;
          padding: 9px;
          @media screen and (max-width: $britax-container-max-width-s) {
            padding: 0;
            line-height: 14px;
          }
          &:hover,
          &:focus {
            background-color: var(--britax-grey);
            border: 1px solid var(--britax-grey);
          }
          &::after {
            content: "-";
            position: absolute;
            top: 0px; //Saqlain Customization: Icon position fix
          }
          &.disabled,
          &:disabled {
            background-color: var(--britax-silver-grey);
            border-color: var(--britax-silver-grey);
          }
        }
        .increment {
          color: var(--britax-white);
          font-size: var(--britax-font-size-xsml);
          background-color: var(--britax-primary);
          border: 1px solid var(--britax-primary);
          height: 16px;
          width: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          position: relative;
          padding: 9px;
          @media screen and (max-width: $britax-container-max-width-s) {
            padding: 0;
            line-height: 14px;
          }
          &:hover,
          &:focus {
            background-color: var(--britax-grey);
            border: 1px solid var(--britax-grey);
          }
          &::after {
            content: "+";
            position: absolute;
            top: 0px; //Saqlain Customization: Icon position fix
          }
          &.disabled,
          &:disabled {
            background-color: var(--britax-silver-grey);
            border-color: var(--britax-silver-grey);
          }
        }
        &-input {
          border: none;
          width: 50px;
          height: auto;
          padding: 0;
        }
      }
      span {
        width: auto;
        margin: 0;
      }
      .msc-price__actual {
        font-size: var(--britax-font-size-sm);
      }
      .msc-alert__header {
        color: var(--britax-danger-font-color);
        font-weight: var(--britax-font-weight-bold);
        margin-bottom: 5px;
      }
    }

    // &__content,
    // &__bopis-container,
    // &__extra-actions {
    //     margin-left: $cart-content-margin-left;
    // }
    // &__content,
    // &__extra-actions{
    //     margin-left: 120px;
    // }
  }
  .msc-cart-line__extra-actions {
    margin: -20px 0 0 110px;
    position: relative;
    z-index: 9;
  }
  .sku-number {
    margin-left: 110px;
    margin-bottom: 10px;
    font-size: var(--britax-body-font-size-xs);
    @media screen and (max-width: $britax-container-max-width-s) {
      margin: 10px 0 0 60px;
    }
  }

  .msc-cart-lines-item {
    position: relative;
    overflow: hidden;
    &__price {
      margin-top: 8px;
      margin-left: 10px;
      width: 90px;
      display: inline-block;
      font-size: var(--britax-font-size-sm);
    }
    &__priceSubtotal {
      margin-left: 10px;
      margin-top: 8px;
      width: 90px;
      display: inline-block;
      font-size: var(--britax-font-size-sm);
      text-align: right;
    }
    .status {
      font-size: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-block;
      border: solid 2px var(--britax-white);
      position: absolute;
      top: 10px;
      left: 0;
      &.AVAIL {
        background: var(--britax-success-color);
      }
      &.OOS {
        background: var(--britax-error-color);
      }
      &.LOW {
        background: var(--britax-pending-color);
      }
    }
    .premium {
      display: none;
      background: var(--britax-grey);
      color: var(--britax-white);
      padding: 2px 15px 5px;
      position: absolute;
      top: 11px;
      left: -33px;
      width: 130px;
      text-align: center;
      transform: rotate(-35deg);
      font-size: var(--britax-body-font-size-xxs);
      font-weight: var(--britax-font-weight-bold);
      z-index: 1;
      @include add-icon($msv-FavoriteStarFill);
      &:before {
        margin-right: 5px;
      }
    }
    .isretired {
      background: var(--britax-pending-color);
      color: var(--britax-white);
      border-radius: 4px;
      display: inline-block;
      padding: 5px 15px;
      position: absolute;
      top: 23px;
      right: -36px;
      width: 170px;
      text-align: center;
      transform: rotate(35deg);
      &:before {
        display: inline-block;
        content: "!";
        margin-right: 7px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background: var(--britax-white);
        color: var(--britax-pending-color);
        font-weight: var(--britax-font-weight-bold-full);
      }
    }
  }

  .msc-cart-lines-item
    > .msc-cart-line__add-to-wishlist
    ~ .msc-cart-line__remove-item {
    margin-left: auto;
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .msc-cart-lines-item > .msc-cart-line__remove-item,
  .msc-cart-lines-item > .msc-cart-line__add-to-wishlist {
    margin-left: $cart-content-margin-left;
  }

  .msc-promo-code {
    &__input-box {
      height: $cart-promo-code-input-height;
    }
  }

  .msc-cart-lines-group {
    flex: 1;
    margin-right: $cart-cartline-wrapper-margin-right;
    padding-top: $cart-cartline-wrapper-grp-padding;
  }

  .msc-cart-lines-group-wraper {
    background-color: $msv-white;
    flex: 1;
    margin-bottom: $cart-cartline-wrapper-margin-bottom;

    .msc-cart-line-group__extra-actions {
      margin-left: $cart-line-group-extra-action-margin-left;
      margin-top: $cart-line-group-extra-action-margin-top;
    }
  }
  .cart-main-heading {
    margin: 30px 0;
    flex: 0 0 100%;
  }
}

@media screen and (max-width: $msv-breakpoint-m) {
  .ms-cart {
    display: block;

    .msc-cartline-wraper .msc-cart-lines-group-wraper {
      margin-right: 0;
      width: 100%;
    }

    .msc-cart-lines-group {
      margin-right: 0;
    }

    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
    .msc-cart-lines-item > .msc-cart-line__remove-item {
      margin-left: $cart-cartline-item-content-margin-left-m;
    }

    .msc-cart-lines-item {
      .msc-cart-line {
        &__product-image {
          .msc-empty_image {
            @include image-placeholder($cart-empty-image-width-m);
            background-size: cover;
          }
        }

        &__content {
          display: block;
          margin-left: $cart-cartline-item-content-margin-left-m;
        }

        // &__bopis-container,
        // &__extra-actions {
        //     margin-left: $cart-cartline-item-content-margin-left-m;
        // }

        &__product {
          padding-bottom: $cart-cartline-line-product-padding-bottom;

          &-quantity {
            padding-bottom: $cart-cartline-line-product-padding-bottom;
          }

          &-savings {
            margin-left: 0;
            text-align: left;
          }

          &-price {
            margin-left: $cart-cartline-line-product-price-margin-left;
            padding-top: $cart-cartline-line-product-price-padding-top;
            text-align: left;
          }
        }

        &-group {
          &__extra-actions {
            margin-left: $cart-cartline-item-content-margin-left-m;
            margin-top: $cart-line-group-extra-action-margin-top;
          }
        }
      }
      &__price {
        font-weight: var(--britax-font-weight-normal);
        line-height: var(--britax-line-height-xs);
        color: var(--britax-grey);
        font-size: var(--britax-body-font-size-xs);
        margin-left: $cart-cartline-item-content-margin-left-m;
      }
    }

    .msc-promo-code {
      &__input-box {
        width: $cart-promo-code-input-width;
        @media screen and (max-width: $britax-container-max-width-m) {
          width: $cart-promo-code-input-width-m;
        }
      }

      &__apply-btn {
        min-width: auto;
      }
    }

    .msc-order-summary-wrapper {
      padding: $cart-order-summary-padding-m;
      width: 100%;
      @media screen and (max-width: $britax-container-max-width-m) {
        padding: 0;
      }
    }

    .msc-order-summary__items {
      @include font-content-s();
    }
  }
}
@media (max-width: $britax-container-max-width-l) {
  .ms-cart .msc-cartline-wraper {
    margin-right: 20px;
  }
}
@media (max-width: $britax-container-max-width-s) {
  .ms-cart {
    .msc-cartline-wraper__labels {
      display: none;
    }
    .msc-cartline-wraper {
      margin-right: 0;
      .msc-cart-lines-item {
        .msc-cart-line {
          flex-wrap: wrap;
          .msc-cart-line__product-image {
            img {
              width: 50px;
            }
            .product-info {
              width: calc(100% - 60px);
            }
          }
          .small-blks {
            &:nth-child(2) {
              margin-left: 60px;
            }
          }
          .small-blks {
            width: calc(33.33% - 20px);
            margin-bottom: 5px;
            .mobile-label {
              display: block;
              font-weight: 400;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .msc-cart-line__extra-actions {
      margin: 0 0 0 60px;
    }
  }
}
@media (max-width: $britax-breakpoint-mbl) {
  .ms-cart {
    .msc-cartline-wraper {
      .msc-cart-lines-item {
        .msc-cart-line {
          .small-blks {
            width: 100%;
            margin-left: 60px;
            &.product-quantity {
              margin-top: 15px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
h1.msc-cart__heading {
  display: none;
}
