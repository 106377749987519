.ms-text-block .btn-primary {
	@include primary-button();
}
.custom-styles {
	h1 {
		margin-bottom: 20px;
		color: var(--britax-primary);
	}
	h2 {
		margin-bottom: 10px;
		font-size: 1.3rem;
		color: var(--britax-primary);
	}
	&.privacy {
		h4 {
			margin-bottom: 15px;
			font-size: 1rem;
			color: var(--britax-primary);
		}
		h5 {
			font-size: 0.9rem;
			margin-bottom: 10px;
			color: var(--britax-primary);
		}
	}
}

.table-style {
	width: 100%;
	border: 1px solid #ddd;
	td,
	th {
		border: 1px solid #ddd;
		padding: 8px;
	}
	tr {
		th {
			padding-top: 12px;
			padding-bottom: 12px;
			text-align: left;
			background-color: var(--britax-grey);
			color: var(--britax-primary);
		}
	}
}
.btn-center {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	display: inline-block;
}
