.Toastify {
  &__toast-container {
      z-index: 999999;
      -webkit-transform: translate3d(0, 0, 9999px);
      position: fixed;
      width: 320px;
      box-sizing: border-box;

      @media (max-width: 480px) {
          width: 100vw;
          padding: 0;
          left: 0;
          margin: 0;

          &--top-left,
          &--top-center,
          &--top-right {
              top: 0;
              transform: translateX(0);
          }

          &--bottom-left,
          &--bottom-center,
          &--bottom-right {
              bottom: 0;
              transform: translateX(0);
          }

          &--rtl {
              right: 0;
              left: initial;
          }
      }
  }

  &__toast-container {
      &--top-left {
          top: 1em;
          left: 1em;
      }

      &--top-center {
          top: 1em;
          left: 50%;
          transform: translateX(-50%);
      }

      &--top-right {
          top: 3em;
          right: 1em;
      }

      &--bottom-left {
          bottom: 1em;
          left: 1em;
      }

      &--bottom-center {
          bottom: 1em;
          left: 50%;
          transform: translateX(-50%);
      }

      &--bottom-right {
          bottom: 1em;
          right: 1em;
      }
  }

  &__toast {
      position: relative;
      // min-height: 64px;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      max-height: 800px;
      overflow: hidden;
      font-family: sans-serif;
      cursor: pointer;
      direction: ltr;
      //  background-color: #34aadc;
      
      color: var(--britax-success-font-color);
      background-color: var(--britax-success-bg-color);

      @media (max-width: 800) {
          &__toast {
              margin-bottom: 0;
              border-radius: 0;
          }
      }
      &--error {
        background-color: var(--britax-danger-bg-color);
        color: var(--britax-danger-font-color);
        padding: 10px;
      }
  }

  &__toast--rtl {
      direction: rtl;
  }

  &__toast-body {
      margin: 0;
      padding: 0;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      h5 {
        padding: 12px 10px;
        font-size: var(--britax-font-size-sm);
      }
      p {
        padding: 0 10px;
        margin-bottom: 10px;
        font-size: var(--britax-body-font-size-xs);
      }
  }

  &--animate {
      animation-fill-mode: both;
      animation-duration: 0.7s;
  }

  &__close-button {
      // color: #fff;
      background: transparent;
      outline: none;
      border: none;
      padding: 0;
      cursor: pointer;
      opacity: 0.7;
      transition: 0.3s ease;
      -ms-flex-item-align: start;
      align-self: flex-start;
      margin: 9px;

      &--default {
          color: #cedff1;
          opacity: 1;
      }

      & > svg {
          fill: currentColor;
          height: 16px;
          width: 14px;
          color: var(--britax-success-font-color);
      }

      &:hover,
      &:focus {
          opacity: 1;
      }
      &--error {
        margin: 0 9px 5px;
        svg {
          color: var(--britax-danger-font-color);
        }
      }
  }

  &__bounce-enter {
      &--top-left,
      &--bottom-left {
          animation-name: Toastify__bounceInLeft;
      }

      &--top-right,
      &--bottom-right {
          animation-name: Toastify__bounceInRight;
      }

      &--top-center {
          animation-name: Toastify__bounceInDown;
      }

      &--bottom-center {
          animation-name: Toastify__bounceInUp;
      }
  }

  &__bounce-exit {
      &--top-left,
      &--bottom-left {
          animation-name: Toastify__bounceOutLeft;
      }

      &--top-right,
      &--bottom-right {
          animation-name: Toastify__bounceOutRight;
      }

      &--top-center {
          animation-name: Toastify__bounceOutUp;
      }

      &--bottom-center {
          animation-name: Toastify__bounceOutDown;
      }
  }

  &__zoom-enter {
      animation-name: Toastify__zoomIn;
  }

  &__zoom-exit {
      animation-name: Toastify__zoomOut;
  }

  &__flip-enter {
      animation-name: Toastify__flipIn;
  }

  &__flip-exit {
      animation-name: Toastify__flipOut;
  }

  &__slide-enter {
      &--top-left,
      &--bottom-left {
          animation-name: Toastify__slideInLeft;
      }

      &--top-right,
      &--bottom-right {
          animation-name: Toastify__slideInRight;
      }

      &--top-center {
          animation-name: Toastify__slideInDown;
      }

      &--bottom-center {
          animation-name: Toastify__slideInUp;
      }
  }

  &__slide-exit {
      &--top-left,
      &--bottom-left {
          animation-name: Toastify__slideOutLeft;
      }

      &--top-right,
      &--bottom-right {
          animation-name: Toastify__slideOutRight;
      }

      &--top-center {
          animation-name: Toastify__slideOutUp;
      }

      &--bottom-center {
          animation-name: Toastify__slideOutDown;
      }
  }
  &__progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 9999;
      opacity: 0.7;
      background-color: rgba(255, 255, 255, 0.7);
      transform-origin: left;
      &--animated {
          animation: Toastify__trackProgress linear 1 forwards;
      }
      &--controlled {
          transition: transform 0.2s;
      }
      &--rtl {
          right: 0;
          left: initial;
          transform-origin: right;
      }
      &--default {
          background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
      }
      &--dark {
          background-color: var(--britax-success-font-color);
      }
  }
}


@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }

    75% {
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        transform: translate3d(5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}

@keyframes Toastify__flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}

@keyframes Toastify__flipOut {
    from {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0);
    }
}

@keyframes Toastify__slideOutLeft {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0);
    }
}

@keyframes Toastify__slideOutDown {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0);
    }
}

@keyframes Toastify__slideOutUp {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0);
    }
}