// Contact Page - Custom CSS Start
.contact-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	.row {
		justify-content: center;
		.container-heading {
			padding-bottom: 50px;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-body-font-size-ml);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
			}
		}
	}
}
.contact-container-highlights {
	.row {
		.col-12 {
			.child-2 {
				display: flex;
				flex-direction: row-reverse;
			}
			.child-4 {
				display: flex;
				flex-direction: row-reverse;
			}
		}
	}
}
// Contact Page - Custom CSS End

// Delivery Page - Custom CSS Start
.delivery-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	margin-bottom: 5%;
	.row {
		justify-content: center;
		.container-heading {
			padding-bottom: 50px;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-body-font-size-ml);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
			}
		}
	}
}
// Delivery Page - Custom CSS End

// Warranty Page - Custom CSS Start
.warranty-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	margin-bottom: 5%;
	.row {
		justify-content: center;
		.container-heading {
			padding-bottom: 50px;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-body-font-size-ml);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
				h3 {
					font-size: var(--britax-font-size-xxml);
				}
				h4 {
					font-size: var(--britax-font-size-sm);
				}
			}
		}
	}
}
// Warranty Page - Custom CSS End

// Terms and Conditions Page - Custom CSS Start

.terms-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	margin-bottom: 5%;
	.row {
		.container-heading {
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
				h3 {
					font-size: var(--britax-font-size-xxml);
					font-weight: var(--britax-font-weight-700);
					strong {
						font-weight: var(--britax-font-weight-700);
					}
				}
				h4 {
					font-size: var(--britax-font-size-sm);
				}
			}
		}
	}
}

// Terms and Conditions Page - Custom CSS End

// FAQ Page - Custom CSS Start

.faq-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	margin-bottom: 5%;
	.row {
		justify-content: center;
		.container-heading {
			display: flex;
			justify-content: center;
			flex: 1;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-body-font-size-ml);
		}
		.col-12 {
			margin-bottom: 5%;
			.ms-accordion {
				&-header-section {
					&__heading {
						color: var(--britax-primary);
						font-size: var(--britax-font-size-xxml);
						font-weight: var(--britax-font-weight-700);
						text-align: center;
					}
					.ms-accordion-header-section-toggle-button-container {
						display: none;
					}
				}
				&-item-section {
					color: var(--britax-primary);
					.drawer__button {
						border-top: 1px solid var(--britax-grey);
						font-weight: var(--britax-font-weight-700);
						color: var(--britax-primary);
						height: auto;
						padding: 20px 0;
						&:hover {
							background: none;
						}
						&::after {
							margin-left: 10px;
						}
					}
					.collapse {
						min-height: 0 !important;
					}
					.collapsing {
						display: none;
					}
				}
				.faq-container-image {
					img {
						width: 800px;
						height: auto;
						max-width: 100%;
					}
				}
				h3 {
					font-size: var(--britax-font-size-sm);
					font-weight: var(--britax-font-weight-700);
					line-height: var(--britax-line-height-l);
				}
				h4 {
					font-size: var(--britax-font-size-sm);
					line-height: var(--britax-line-height-l);
				}
				p {
					line-height: var(--britax-line-height-l);
				}
				ul {
					padding: 0;
					li {
						list-style: none;
					}
				}
				table {
					border: 1px solid var(--britax-grey);
					border-collapse: collapse;
					width: 100%;
					color: var(--britax-primary);
					th {
						border: 1px solid var(--britax-silver-grey);
						background-color: var(--britax-silver-grey);
						text-align: center;
						padding: 15px;
					}
					tr {
						border: 1px solid var(--britax-silver-grey);
						text-align: left;
					}
					td {
						border: 1px solid var(--britax-silver-grey);
						text-align: left;
						padding: 15px;
					}
				}
			}
		}
	}
}

// FAQ Page - Custom CSS End

// Press Contact Page - Custom CSS Start

.pressContact-container-main {
	min-height: 330px;
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	margin-bottom: 5%;
	.row {
		justify-content: center;
		.container-heading {
			padding-bottom: 50px;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-body-font-size-ml);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
				h3 {
					font-size: var(--britax-font-size-xxml);
				}
				h4 {
					font-size: var(--britax-font-size-sm);
				}
			}
		}
	}
}

// Press Contact Page - Custom CSS End

// Privay Policy Page - Custom CSS Start

.privacypolicy-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	h5,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	.row {
		.container-heading {
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
			flex: 1;
			text-align: center;
			margin: 5% 0;
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
				h3 {
					font-size: var(--britax-font-size-sm);
					font-weight: var(--britax-font-weight-700);
					line-height: var(--britax-line-height-l);
				}
				h4 {
					font-size: var(--britax-font-size-sm);
					line-height: var(--britax-line-height-l);
				}
				p {
					line-height: var(--britax-line-height-l);
				}
			}
			table {
				border: 1px solid #ccc;
				border-collapse: collapse;
				width: 100%;
				color: var(--britax-primary);
				th {
					border: 1px solid #ccc;
					text-align: center;
					background-color: var(--britax-silver-grey);
					padding: 15px;
				}
				tr {
					border: 1px solid #ccc;
					text-align: left;
				}
				td {
					border: 1px solid #ccc;
					text-align: left;
					padding: 15px;
				}
			}
		}
	}
}

// Privay Policy Page - Custom CSS End

// About Page - Custom CSS Start

.about-container-head {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	.row {
		justify-content: center;
		.container-heading {
			margin-bottom: 5%;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
				margin-top: 5%;
			}
		}
		.ms-content-block__image {
			display: flex;
			justify-content: center;
			img {
				height: auto;
			}
		}
	}
}
.about-container-body {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	p {
		font-size: var(--britax-body-font-size-s);
	}
	.row {
		.col-12 {
			.ms-content-block__title {
				font-weight: var(--britax-font-weight-700);
				font-size: var(--britax-font-size-sml-32);
			}
			.child-1,
			.child-2 {
				margin: 0;
			}
			.child-2 {
				flex-direction: row-reverse;
			}
		}
	}
}
.about-container-footer {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	margin-bottom: 5%;
	p {
		font-size: var(--britax-body-font-size-s);
	}
	.row {
		justify-content: center;
		.container-heading {
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
				margin-top: 5%;
				margin-bottom: 5%;
			}
			.ms-content-block__image {
				display: flex;
				justify-content: center;
				img {
					height: auto;
				}
			}
		}
	}
}

// About Page - Custom CSS End

// Safety Notices Page - Custom CSS Start

.safetyNotices-container-head {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	.row {
		.container-heading {
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
		}
	}
}
.safetyNotices-container-body {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	.row {
		.container-heading {
			flex: 1;
			text-align: center;
			margin: 5% 0;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
		}
		.col-12 {
			.ms-content-block {
				justify-content: center;
				&__details {
					.ms-content-block__title {
						font-size: var(--britax-font-size-xsml);
						line-height: var(--britax-line-height-sml);
						font-weight: var(--britax-font-weight-700);
						max-width: none;
					}
				}
				&__text {
					max-width: none;
					p {
						font-size: var(--britax-body-font-size-s);
						a {
							display: flex;
							font-size: var(--britax-body-font-size-xxs);
							font-weight: var(--britax-font-weight-700);
							color: var(--britax-primary);
							&:hover {
								text-decoration: none;
								color: var(--britax-grey);
							}
							&::after {
								font-family: "Font Awesome 5 Free";
								-moz-osx-font-smoothing: grayscale;
								-webkit-font-smoothing: antialiased;
								font-style: normal;
								font-variant: normal;
								font-weight: 900;
								text-rendering: auto;
								line-height: 1;
								content: "";
								margin-left: 12px;
								line-height: 30px;
							}
						}
					}
				}
				&__image {
					width: 15%;
				}
			}
		}
	}
}
// Safety Notices Page - Custom CSS End

// Safety Notices Articles Page - Custom CSS Start
.article-container-head {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	.row {
		margin-top: 5%;
		.container-heading {
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-font-size-sml-32);
			margin-bottom: 40px;
		}
		.col-12 {
			.ms-content-block {
				margin: 0;
				&__details {
					.ms-content-block__title {
						font-size: var(--britax-font-size-xsml);
						line-height: var(--britax-line-height-sml);
						font-weight: var(--britax-font-weight-700);
						max-width: none;
					}
					flex: 1;
				}
				&__text {
					max-width: none;
					a {
						color: var(--britax-primary);
						&:hover {
							color: var(--britax-grey);
						}
					}
					p {
						font-size: var(--britax-body-font-size-s);
					}
					h1 {
						font-weight: var(--britax-font-weight-700);
						font-size: var(--britax-font-size-sml-32);
					}
					h3 {
						font-size: var(--britax-body-font-size-l);
						margin: 20px 0;
						font-weight: var(--britax-font-weight-700);
						line-height: var(--britax-line-height-sml);
					}
				}
				&__image {
					width: 15%;
				}
			}
		}
	}
}

.article-container-body {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		margin: 20px 0;
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		display: flex;
		justify-content: flex-start;
		line-height: var(--britax-line-height-l);
	}
	i {
		display: flex;
		justify-content: center;
	}
	.row {
		.col-auto {
			flex: 1;
		}
	}
	.ms-content-block {
		&__image {
			display: flex;
			justify-content: center;
			img {
				height: auto;
				width: 500px;
				max-width: 100%;
				margin-bottom: 15px;
			}
		}
	}
}

.article-container-two-column {
	.row {
		.col-auto {
			display: flex;
			flex: 1;
			h4 {
				font-size: var(--britax-font-size-sm);
				font-weight: var(--britax-font-weight-700);
			}
		}
	}
	.ms-content-block__details {
		text-align: center;
		.ms-content-block__title {
			position: absolute;
			top: 0;
		}
	}
}
.article-container-multiImages {
	.row {
		.col-12 {
			flex: 33%;
		}
	}
	.ms-content-block {
		&__image {
			display: flex;
			img {
				height: auto;
				width: 500px;
				max-width: 100%;
			}
		}
		&__title {
			font-size: var(--britax-font-size-sm);
			text-align: center;
		}
		&__text {
			margin-top: 15px;
		}
	}
}
// Safety Notices Articles Page - Custom CSS End

// Imprint Page - Custom CSS Start

.imprint-container-main {
	strong,
	p,
	h3,
	h4,
	li,
	a,
	b,
	i {
		color: var(--britax-primary);
		:hover,
		:focus {
			color: var(--britax-grey);
		}
	}
	h2 {
		font-size: var(--britax-font-size-xxml);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h3 {
		font-size: var(--britax-body-font-size-l);
		font-weight: var(--britax-font-weight-700);
		line-height: var(--britax-line-height-sml);
	}
	h4 {
		font-size: var(--britax-font-size-sm);
		font-weight: var(--britax-font-weight-700);
	}
	p {
		line-height: var(--britax-line-height-l);
	}
	margin-top: 5%;
	.row {
		justify-content: center;
		.container-heading {
			padding-bottom: 50px;
			font-weight: var(--britax-font-weight-700);
			font-size: var(--britax-body-font-size-ml);
		}
		.col-12 {
			.ms-text-block {
				text-align: left;
				line-height: 25px;
			}
		}
	}
}

// Imprint Page - Custom CSS End
